import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import * as _ from "lodash";
import { CallTimeLine } from "../CallTimeLine";
import "../../../src/App.css";
import * as XLSX from "xlsx";
import axios from "axios";
axios.defaults.headers.common["user"] = localStorage.getItem("user");

interface Column {
  id:
    | "ucid"
    | "call_date"
    | "summary"
    | "complaint_types"
    | "issue_tags"
    | "campaign";
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}
const columns: readonly Column[] = [
  { id: "call_date", label: "Call Date", minWidth: 150 },
  { id: "ucid", label: "UCID", minWidth: 80 },
  { id: "campaign", label: "Campaign", minWidth: 80 },
  { id: "complaint_types", label: "Complaint Types", minWidth: 200 },
  { id: "summary", label: "Summary", minWidth: 300 },
  { id: "issue_tags", label: "Issue Tags", minWidth: 450 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

let metaArr: any[] = [];
let downloadArr: any[] = [];
let ucidArr: any[] = [];
const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((d) => {
    const obj = JSON.parse(JSON.stringify(d));
    metaArr.push(obj);
  });
  return metaArr;
};

let HitsArr = {};

export default function VocSearchDataTable(fetchVOCSummary: any) {
  let data = fetchVOCSummary && fetchVOCSummary;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const downloadVOCSummaryUrl =
    process.env.REACT_APP_BASE_URL + "/downloadVocSummary";
    const convertToTitleCase = (str: string) => {
      return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
    };
    const convertKeysToTitleCase = (obj: DataItem) => {
      const newObj: DataItem = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[convertToTitleCase(key)] = obj[key];
        }
      }
      return newObj;
    };
    const exportToExcel = (data: any) => {
      // Check if data is defined and is an array
      if (!data || !Array.isArray(data) || data.length === 0) {
        console.error("Invalid or empty data array");
        return;
      }
      // Convert keys to camel case for each object in the array
      const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
      // Check if camelCaseData is defined and is an array
      if (
        !camelCaseData ||
        !Array.isArray(camelCaseData) ||
        camelCaseData.length === 0
      ) {
        console.error("Invalid or empty camelCaseData array");
        return;
      }
      const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // Save the workbook as an XLSX file
      XLSX.writeFile(
        workbook,
        String(localStorage.getItem("user")) + "_Summary.xlsx"
      );
    };
  const vocDownloadData = async () => {
    try {
      const response = await axios.get(downloadVOCSummaryUrl, {
        params: {
          startDate: String(data.fetchData.startDate),
          endDate: String(data.fetchData.endDate),
          keyPhrase: String(data.fetchData.keyPhrase),
          campaign: String(data.fetchData.campaign),
          from: 0,
        },
        timeout: 180000,
      });
      const result = response && response.data && response.data.Hits;
      downloadArr = [];
      Object.values(result).forEach((result:any) => {
        const orderedRow: any = {};
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = result.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(result[id]))
            : undefined;
          // Additional processing for specific columns if needed
          if (id === "issue_tags" && orderedRow[id] !== undefined) {
            orderedRow[id] = JSON.parse(
              JSON.stringify((orderedRow[id].join(";")))
            );
          }
          // Add more conditions for other columns if needed
        });
        downloadArr.push(orderedRow);
      });
      console.log(downloadArr);
      setDownloadData(downloadArr);
      exportToExcel(downloadArr);
    } catch (error) {
      console.log(error);
      window.alert("Error Calling Api to Download");
    }
  };

  const handleDownload = () => {
    vocDownloadData();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    console.log("page is rows ", page);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage + 1;
    var size = rowsPerPage;
    data.fetchData.fetchVocSummariesDataCallBack(
      String(data.fetchData.startDate),
      String(data.fetchData.endDate),
      String(data.fetchData.keyPhrase),
      String(data.fetchData.campaign),
      size,
      from
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    data.fetchData.fetchVocSummariesDataCallBack(
      String(data.fetchData.startDate),
      String(data.fetchData.endDate),
      String(data.fetchData.keyPhrase),
      String(data.fetchData.campaign),
      parseInt(event.target.value, 10),
      "0"
    );
  };

  const getPopoverContent = () => {
    return <CallTimeLine getTranscript={responseData}></CallTimeLine>;
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };

  if (data.fetchVocSummarydata && data.fetchVocSummarydata.length != 0) {
    if (data.fetchVocSummarydata.Hits != undefined) {
      HitsArr = data.fetchVocSummarydata.Hits;
      // console.log(HitsArr);
    }
  }

  metaArr.map((row) => {
    ucidArr.push(row.ucid);
  });

  function splitStringWithNewlines(stringArray: []) {
    if (_.isArrayLikeObject(stringArray) || _.isArray(stringArray)) {
      const labels =
        stringArray &&
        stringArray.map((line: any, index: any) =>
          line && !_.isEmpty(line) && _.isString(line) ? (
            <label key={index}>{line}</label>
          ) : (
            ""
          )
        );
      return labels;
    }
  }

  React.useEffect(() => {
    setTableData(getData());
  }, [fetchVOCSummary]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                {columns.map((column, idx) => {
                  return (
                    <StyledTableCell
                      key={column.id + idx}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#1976d2",
                        fontWeight: "bold",
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.map((ro: any, rowIdx: any) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // key={row.SNO}
                    >
                      {columns.map((cl) => {
                        if (cl.id === "call_date") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>{ro.call_date}</label>
                            </StyledTableCell>
                          );
                        }
                        if (cl.id === "campaign") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>{ro.campaign}</label>
                            </StyledTableCell>
                          );
                        }
                        if (cl.id === "ucid") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>{ro.ucid} </label>
                            </StyledTableCell>
                          );
                        }
                        if (cl.id === "summary") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>{ro.summary}</label>
                            </StyledTableCell>
                          );
                        }
                        if (cl.id === "complaint_types") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>{ro.complaint_types}</label>
                            </StyledTableCell>
                          );
                        }
                        if (cl.id === "issue_tags") {
                          return (
                            <StyledTableCell
                              key={cl.id + rowIdx}
                              align={cl.align}
                            >
                              <label>
                                {splitStringWithNewlines(ro.issue_tags)}
                              </label>
                            </StyledTableCell>
                          );
                        }
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={
            data.fetchVocSummarydata &&
            data.fetchVocSummarydata.DataCount !== 0
              ? Math.ceil(data.fetchVocSummarydata.DataCount)
              : 0
          }
          // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={() => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={handleDownload}
      >
        Download
      </Button>
    </Card>
  );
}
