import styled from "styled-components";
import SlotDiv from "./SlotDiv";
import React from 'react'

const TimeBarDiv = styled.div`
display: grid;
grid-template-columns: repeat(${props => props.endTime}, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 1px;
height: 21px;
margin: 0px 0 2px 0px;
background-color: rgba(225,225,225,0.34);
overflow:hidden;
`;

function TimeBar(props) {
    return (
        <TimeBarDiv endTime={props.endTime}>
            {[...Array(Math.ceil(props.endTime)).keys()].map((data, key) => {
                return (
                    <>
                        {key !== 0 && key % 5 === 0 ? <SlotDiv className="timeline-text" key={key}>{key}</SlotDiv> : <div key={key} className="timeline-text">&nbsp;</div>}
                    </>
                );
            })}
        </TimeBarDiv>
    );
}
export default TimeBar;
