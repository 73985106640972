import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button, Menu, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import "../App.css";
import * as XLSX from "xlsx";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";
import axios from "axios";
axios.defaults.headers.common["user"] = localStorage.getItem("user");

interface Column {
  id:
    | "agent_names" 
    | "score"
    | "score_fatal"
    | "calls"
    |"call_duration"
    |"callopening_5sec"
    |"opening_greeting"
    |"opening_selfintro"
    |"brand_name_check"
    |"paraphrasing"
    |"showing_intresting_thanking_outbound"
    |"requirements_understanding"
    |"looking_for_underconstruction_or_readytomove"
    |"offering_assistance_opening"
    |"name_confirmation_outbound"
    |"continuing_previous_interaction"
    |"budget_check"
    |"configurations_carpetarea_check"
    |"customer_location_check"
    |"investment_self_check"
    |"objection_handling_check"
    |"effective_timelyresponses_check"
    |"urgencey_creation"
    |"correct_property_location_check"
    |"correct_usp_check"
    |"correct_configuration_check"
    |"correct_pricing_check"
    |"correct_amenities_check"
    |"campaign_scheme"
    |"offer_payment_plan"
    |"correct_possession_timeline"
    |"confidence_check"
    |"active_listeining"
    |"empathy_assurance_check"
    |"interruption_avoidence"
    |"tone_modulation"
    |"energetic_sound"
    |"fillers"
    |"rate_of_speech"
    |"rudeness_avoidence_check"
    |"abusiveness_avoidence_check"
    |"hold_placing"
    |"hold_duration"
    |"language_switch"
    |"personalization"
    |"jargons_avoidence"
    |"pick_and_drop_check"
    |"virtual_presentation_check"
    |"followup_visit_date_time_check"
    |"summarization"
    |"thanking_closing"
    |"greeting_closing"
      
   ;
  label: string;
  minWidth?: number;
  align?: "right";
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [
    { id: "agent_names", label: "Agent Name", minWidth: 250 },
    { id: "score", label: "Score without fatal", minWidth: 100 },
    { id: "score_fatal", label: "score with fatal", minWidth: 100 },
    { id: "calls", label: "Calls", minWidth: 80 },
    { id: "call_duration", label: "Call Duration", minWidth: 100 },
    { id: "callopening_5sec", label: "Call Opening (5 Sec)", minWidth: 100 },
    { id: "opening_greeting", label: "Opening Greeting", minWidth: 100 },
    { id: "opening_selfintro", label: "Self Introduction", minWidth: 100 },
    { id: "brand_name_check", label: "Brand Name Check", minWidth: 100 },
    { id: "paraphrasing", label: "Paraphrasing", minWidth: 100 },
    { id: "showing_intresting_thanking_outbound", label: "Showing Interest & Thanking (Outbound)", minWidth: 150 },
    { id: "requirements_understanding", label: "Understanding Requirements", minWidth: 150 },
    { id: "looking_for_underconstruction_or_readytomove", label: "Looking for Under Construction or Ready to Move", minWidth: 200 },
    { id: "offering_assistance_opening", label: "Offering Assistance (Opening)", minWidth: 150 },
    { id: "name_confirmation_outbound", label: "Name Confirmation (Outbound)", minWidth: 150 },
    { id: "continuing_previous_interaction", label: "Continuing Previous Interaction", minWidth: 180 },
    { id: "budget_check", label: "Budget Check", minWidth: 100 },
    { id: "configurations_carpetarea_check", label: "Configuration & Carpet Area Check", minWidth: 180 },
    { id: "customer_location_check", label: "Customer Location Check", minWidth: 150 },
    { id: "investment_self_check", label: "Investment Self Check", minWidth: 150 },
    { id: "objection_handling_check", label: "Objection Handling", minWidth: 150 },
    { id: "effective_timelyresponses_check", label: "Effective & Timely Responses", minWidth: 180 },
    { id: "urgencey_creation", label: "Urgency Creation", minWidth: 150 },
    { id: "correct_property_location_check", label: "Correct Property Location Check", minWidth: 200 },
    { id: "correct_usp_check", label: "Correct USP Check", minWidth: 150 },
    { id: "correct_configuration_check", label: "Correct Configuration Check", minWidth: 180 },
    { id: "correct_pricing_check", label: "Correct Pricing Check", minWidth: 150 },
    { id: "correct_amenities_check", label: "Correct Amenities Check", minWidth: 180 },
    { id: "campaign_scheme", label: "Campaign Scheme", minWidth: 150 },
    { id: "offer_payment_plan", label: "Offer Payment Plan", minWidth: 150 },
    { id: "correct_possession_timeline", label: "Correct Possession Timeline", minWidth: 200 },
    { id: "confidence_check", label: "Confidence Check", minWidth: 150 },
    { id: "active_listeining", label: "Active Listening", minWidth: 150 },
    { id: "empathy_assurance_check", label: "Empathy & Assurance", minWidth: 150 },
    { id: "interruption_avoidence", label: "Interruption Avoidance", minWidth: 180 },
    { id: "tone_modulation", label: "Tone Modulation", minWidth: 150 },
    { id: "energetic_sound", label: "Energetic Sound", minWidth: 150 },
    { id: "fillers", label: "Fillers", minWidth: 100 },
    { id: "rate_of_speech", label: "Rate of Speech", minWidth: 150 },
    { id: "rudeness_avoidence_check", label: "Rudeness Avoidance", minWidth: 150 },
    { id: "abusiveness_avoidence_check", label: "Abusiveness Avoidance", minWidth: 180 },
    { id: "hold_placing", label: "Hold Placing", minWidth: 150 },
    { id: "hold_duration", label: "Hold Duration", minWidth: 150 },
    { id: "language_switch", label: "Language Switch", minWidth: 150 },
    { id: "personalization", label: "Personalization", minWidth: 150 },
    { id: "jargons_avoidence", label: "Jargon Avoidance", minWidth: 150 },
    { id: "pick_and_drop_check", label: "Pick and Drop Check", minWidth: 180 },
    { id: "virtual_presentation_check", label: "Virtual Presentation Check", minWidth: 200 },
    { id: "followup_visit_date_time_check", label: "Follow-up Visit Date & Time Check", minWidth: 200 },
    { id: "summarization", label: "Summarization", minWidth: 150 },
    { id: "thanking_closing", label: "Thanking (Closing)", minWidth: 150 },
    { id: "greeting_closing", label: "Greeting (Closing)", minWidth: 150 },
    
  
 ];
let metaArr: any[] = [];
interface DataItem {
  [key: string]: string;
}
const getData = () => {
  metaArr = [];
  if (!_.isEmpty(HitsArr)) {
    Object.values(HitsArr).forEach((data) => {
      const obj = JSON.parse(JSON.stringify(data));
      metaArr.push(obj);
    });
  }
  return metaArr;
};

type Props = {
  fetchAudit: any;
};

let HitsArr = {};
let downloadArr: any[] = [];
export default function SunteckRealtyAuditTable({ fetchAudit }: Props) {
  let agentAggregationObj: any = {};
  let avgDurationObj: any = {};
  let agentCountArr: any[] = [];
  let avgDurationArr: any[] = [];
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [downloadData, setDownloadData] = useState<any>();
  const [downloadAgentData, setDownloadAgentData] = useState<any>();
  const [agentCatergoryWiseData, setAgentCatergoryWiseData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);
  const [order, setOrder] = useState("");
  // const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const openSearchAuditDataUrl =
    process.env.REACT_APP_BASE_URL + "/sunteck/getOpenAuditData";
  const sunteckRealtyAgentDataDownload =
    process.env.REACT_APP_BASE_URL + "/sunteck/getDownloadAgentScoreByParams";
  const sunteckRealtyAgentCategoryWiseDataDownload =
    process.env.REACT_APP_BASE_URL + "/sunteck/getDownloadAgentParams";

     const [downloadMenuAnchor, setDownloadMenuAnchor] = React.useState(null);
      const downloadMenuOpen = Boolean(downloadMenuAnchor)

  // console.log("order is auditttttt", order);
  const findName = (name: any) => {
    // console.log("Name is ", name);
    if (fetchAudit[0].aggregations != undefined) {
      for (const element of fetchAudit[0].aggregations) {
        // console.log("Element is audit", element);
        if (element["name"] === name) {
          return element;
        }
      }
    }
  };
  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };

    const handleDownloadClick = (event: any) => {
        setDownloadMenuAnchor(event.currentTarget );
      };
      const closeDownloadMenu = () => {
        setDownloadMenuAnchor(null);
      };
    
  function exportToCsv(downloadArr: any[]) {
        const csvData = downloadArr.map((row) =>
         columns.map((column) => row[column.id]).join(",")
       );
       const csvContent =
         "data:text/csv;charset=utf-8," +
         columns.map((column) => column.label).join(",") +
         "\n" +
         csvData.join("\n");
       const encodedUri = encodeURI(csvContent);
       const link = document.createElement("a");
       link.setAttribute("href", encodedUri);
       link.setAttribute(
         "download",
         String(localStorage.getItem("user")) + "_Calls.csv"
       );
       document.body.appendChild(link); // Required for FF
       link.click();
     }

  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "Audit.xlsx"
    );
  };
  interface LanguageMapping {
    [key: string]: string;
  }
  const getLanguageCode = (languageName: string): string => {
    const languageMapping: LanguageMapping = {
      English: "en-IN",
      Tamil: "ta-IN",
      Malayalam: "ml-IN",
      Marathi: "mr-IN",
      Hindi: "hi-IN",
      Telugu: "te-IN",
      Kannada: "kn-IN",
      Gujarati: "gu-IN",
      // Add more language codes and names as needed
    };
    return languageMapping[languageName] || "0";
  };

  
  const downloadDataCall = async (type:string) => {
    try {
      await axios
        .get(openSearchAuditDataUrl, {
          params: {
            startDateAudit: fetchAudit[2].startDateAudit,
            endDateAudit: fetchAudit[2].endDateAudit,
            campaignAuditVal: fetchAudit[2].campaignAuditVal,
            callTypeAuditVal: fetchAudit[2].callTypeAuditVal,
            agentAuditVal: fetchAudit[2].agentAuditVal,
            dispositionAuditVal: fetchAudit[2].dispositionAuditVal,
            languageAuditVal: !_.isEqual(fetchAudit[2].languageAuditVal, "0")
              ? getLanguageCode(fetchAudit[2].languageAuditVal)
              : fetchAudit[2].languageAuditVal,
            skillAuditVal: fetchAudit[2].skillAuditVal,
            scoreInputVal: fetchAudit[2].scoreInputVal,
            scoreAuditVal: fetchAudit[2].scoreAuditVal,
            fatalInputVal: fetchAudit[2].fatalInputVal,
            fatalAuditVal: fetchAudit[2].fatalAuditVal,
            size: fetchAudit[0]["Total Agents"],
            from: 0,
          },
        })
        .then((response: any) => {
          downloadArr = [];
          if (!_.isEmpty(response.data["Table Agg"])) {
            Object.values(response.data["Table Agg"]).forEach(
              (rowData: any) => {
                const orderedRow: any = {};
                // Iterate through columns to ensure the order
                columns.forEach((column) => {
                  const { id } = column;
                  orderedRow[id] = rowData.hasOwnProperty(id)
                    ? JSON.parse(JSON.stringify(rowData[id]))
                    : undefined;
                });
                downloadArr.push(orderedRow);
              }
            );
          }
          console.log(downloadArr);
          setDownloadData(downloadArr);
          if(type === "excel") {
            exportToExcel(downloadArr);
            } else if(type === "csv") {
             exportToCsv(downloadArr);
            }
        });
    } catch (error) {
      console.log(error);
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
      window.alert("Error Calling Api to Download");
    } finally {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
    }
  };

 
  const handleDownload = (type: string) => { 
    closeDownloadMenu();
    downloadDataCall(type);
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {    
      return (
        <AgentDataDetails
          getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
        ></AgentDataDetails>
      );    
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log("page is rows ", page);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage;
    var size = rowsPerPage;
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].callTypeAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].languageAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal, 
      fetchAudit[2].fatalInputVal,
      fetchAudit[2].fatalAuditVal,
      size,
      from
    );
    
    // console.log("page is audit ", from);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = parseInt(event.target.value, 10);
    fetchAudit[1](
      fetchAudit[2].startDateAudit,
      fetchAudit[2].endDateAudit,
      fetchAudit[2].campaignAuditVal,
      fetchAudit[2].callTypeAuditVal,
      fetchAudit[2].agentAuditVal,
      fetchAudit[2].dispositionAuditVal,
      fetchAudit[2].languageAuditVal,
      fetchAudit[2].skillAuditVal,
      fetchAudit[2].scoreInputVal,
      fetchAudit[2].scoreAuditVal,
      fetchAudit[2].fatalInputVal,
      fetchAudit[2].fatalAuditVal,
      size,
      "0"
    );
  };

  if (fetchAudit != undefined && fetchAudit.length != 0) {
    if (
      fetchAudit[0]["Table Agg"] != undefined &&
      !_.isEmpty(fetchAudit[0]["Table Agg"])
    ) {
      // console.log("isOpen valueis ----", fetchAudit[0]["Table Agg"]);
      HitsArr = fetchAudit[0]["Table Agg"];
      // console.log("HITS ARRAY IS", HitsArr);
    } else {
      HitsArr = {};
    }
  }
  const agentAggregation = findName("Agent Count");
  // console.log("Agent fin name", agentAggregation);
  if (agentAggregation != undefined && !_.isEmpty(agentAggregation)) {
    agentAggregation["labels"].forEach((element: any, index: any) => {
      agentAggregationObj[element] = agentAggregation["data"][index];
    });
    Object.entries(agentAggregationObj).forEach((data) => {
      agentCountArr.push({ label: data[0], value: data[1] });
    });
  }

  const avgDurationAggregation = findName("avg_duration");
  if (
    avgDurationAggregation != undefined &&
    !_.isEmpty(avgDurationAggregation)
  ) {
    avgDurationAggregation["labels"].forEach((element: any, index: any) => {
      avgDurationObj[element] = avgDurationAggregation["data"][index];
    });
    Object.entries(avgDurationObj).forEach((data) => {
      avgDurationArr.push({ label: data[0], value: data[1] });
    });
  }
  const agentDataDetails = (event: any) => {
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
    getPopoverContent();
  };
  let res: never[] = [];
  const getAgentData = async (value: any, e: any) => {
    if (!_.isEmpty(tableData)) {
      tableData.forEach((val: any) => {
        if (val.agent_names == value.value) {
          res = val;
          setResponseData(val);
        }
      });
    }
    if (!_.isEmpty(agentAggregation)) {
      agentCountArr.forEach((val: any) => {
        if (val.label == value.value) {
          setAgentCountFinal(val.value);
        }
      });
    }
    if (!_.isEmpty(avgDurationArr)) {
      avgDurationArr.forEach((val: any) => {
        if (val.label == value.value) {
          let trimVal = val.value.toFixed(1);
          setAvgDurationFinal(trimVal);
        }
      });
    }
    agentDataDetails(e);
    return res;
  };

  useEffect(() => {
    setTableData(getData());
  }, [fetchAudit]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
              {localStorage.getItem("user") === "sunteck_realty" ? (
                  <>
                    {columns.map((column, accessor) => {
                      return (
                        <StyledTableCell
                          key={accessor}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {column.label}
                          {order === "asc" ? (
                            <img
                              alt="upArrow"
                              style={{ height: "12px", paddingLeft: "5px" }}
                              src={images.sortAsc}
                            />
                          ) : order == "" ? null : (
                            <img
                              alt="downArrow"
                              style={{ height: "12px" }}
                              src={images.sortDsc}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {localStorage.getItem("user") === "sunteck_realty" ? (
                <>
                  {tableData.map((row: any, rowIdx: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.SNO}_${rowIdx}`} // Combine SNO and rowIdx for a unique key
                      >
                        {columns.map((column, keyId) => {
                          const value = row[column.id];
                          if (column.id === "agent_names") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                <Button
                                  variant="text"
                                  onClick={(e) => getAgentData({ value }, e)}
                                >
                                  {value}
                                </Button>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 125]}
          component="div"
          count={
            fetchAudit[0]["Total Agents"] > 0
              ? fetchAudit[0]["Total Agents"]
              : 0
          } // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button
        style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
        onClick={handleDownloadClick}
      >
        Download
      </Button>
      <Menu
                            id="basic-menu"
                            anchorEl={downloadMenuAnchor}
                            open={downloadMenuOpen}
                            onClose={closeDownloadMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem dense key={'excel'} onClick={() => handleDownload('excel')}>Excel</MenuItem>
                            <MenuItem dense key={'csv'} onClick={() => handleDownload('csv')}>CSV</MenuItem>
              </Menu>

    </Card>
  );
}
