import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import { AgentDataDetails } from "./AgentDataDetails";
import * as images from "../utils/images/images.component";

import "../App.css";
interface Column {
  id:
    | "StartTime"
    | "EndTime"
    | "Type"
    | "UUI"
    | "monitorUCID"
    | "Did"
    | "CallerID"
    | "AgentName"
    | "PhoneName"
    | "Disposition"
    | "Skill"
    | "Location"
    | "CampaignName"
    | "Status"
    | "DialStatus"
    | "CustomerStatus"
    | "AgentStatus"
    | "CampaignStatus"
    | "AgentPhoneNumber"
    | "DialedNumber"
    | "Duration"
    | "CallDuration"
    | "HoldDuration"
    | "WrapUpDuration"
    | "ConfDuration"
    | "TimeToAnswer"
    | "TransferredTo"
    | "TransferType"
    | "FallBackRule"
    | "HangupBy"
    | "Comments"
    | "transcript"
    | "AudioFile" ;
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}
const columns: readonly Column[] = [
  { id: "StartTime", label: "Start Time", minWidth: 80 },
  { id: "EndTime", label: "End Time", minWidth: 80 },
  { id: "Type", label: "Type", minWidth: 80 },
  { id: "UUI", label: "UUI", minWidth: 80 },
  { id: "monitorUCID", label: "Monitor UCID", minWidth: 120 },
  { id: "Did", label: "Did", minWidth: 120 },
  { id: "CallerID", label: "Caller ID", minWidth: 120 },
  { id: "AgentName", label: "Agent Name", minWidth: 120 },
  { id: "PhoneName", label: "Phone Name", minWidth: 120 },
  { id: "Disposition", label: "Disposition", minWidth: 120 },
  { id: "Skill", label: "Skill", minWidth: 120 },
  { id: "Location", label: "Location", minWidth: 120 },
  { id: "CampaignName", label: "Campaign Name", minWidth: 120 },
  { id: "Status", label: "Status", minWidth: 120 },
  { id: "DialStatus", label: "Dial Status", minWidth: 120 },
  { id: "CustomerStatus", label: "Customer Status", minWidth: 120 },
  { id: "AgentStatus", label: "Agent Status", minWidth: 120 },
  { id: "CampaignStatus", label: "Campaign Status", minWidth: 120 },
  { id: "AgentPhoneNumber", label: "Agent Phone Number", minWidth: 120 },
  { id: "DialedNumber", label: "Dialed Number", minWidth: 120 },
  { id: "Duration", label: "Duration", minWidth: 120 },
  { id: "CallDuration", label: "Call Duration", minWidth: 120 },
  { id: "HoldDuration", label: "Hold Duration", minWidth: 120 },
  { id: "WrapUpDuration", label: "Wrap Up Duration", minWidth: 120 },
  { id: "ConfDuration", label: "Conf Duration", minWidth: 120 },
  { id: "TimeToAnswer", label: "Time To Answer", minWidth: 120 },
  { id: "TransferredTo", label: "Transferred To", minWidth: 120 },
  { id: "TransferType", label: "Transfer Type", minWidth: 120 },
  { id: "FallBackRule", label: "FallBack Rule", minWidth: 120 },
  { id: "HangupBy", label: "Hangup By", minWidth: 120 },
  { id: "Comments", label: "Comments", minWidth: 120 },
  { id: "transcript", label: "Conversation", minWidth: 450 },
  { id: "AudioFile", label: "Audio", minWidth: 120 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type Props = {
  fetchCalls: any;
};

let metaArr: any[] = [];
let downloadArr: any[] = [];
let ucidArr: any[] = [];
const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((d) => {
    const obj = JSON.parse(JSON.stringify(d));
    metaArr.push(obj);
  });
  // console.log(metaArr);
  return metaArr;
};

let HitsArr = {};

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function CDRTable({ fetchCalls }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [sortColumn, setSortColumn] = useState("");
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  // const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  // const [anchorAudio, setAnchorAudio] = React.useState<null | HTMLElement>(
  //   null
  // );
  // const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
  const mindtreeCDRDownloadUrl =
    process.env.REACT_APP_BASE_URL + "/mindTree/getCDRDataDownload";

  //   const findName = (name: any) => {
  //     // console.log("Name is ", name);
  //     if (fetchCalls[0].aggregations != undefined) {
  //       for (const element of fetchCalls[0].aggregations) {
  //         // console.log("Element is audit", element);
  //         if (element["name"] === name) {
  //           return element;
  //         }
  //       }
  //     }
  //   };

  // const handlePlay = (audioUrl: string) => {
  //   if (
  //     currentlyPlaying &&
  //     currentlyPlaying !== audioUrl &&
  //     audioRefs.current[currentlyPlaying]
  //   ) {
  //     const prevAudio = audioRefs.current[currentlyPlaying];
  //     if (prevAudio) {
  //       prevAudio.pause();
  //     }
  //     const audioElement = audioRefs.current[audioUrl];
  //     if (audioElement) {
  //       audioElement.play();
  //     }
  //   }
  //   setCurrentlyPlaying(audioUrl);
  // };

  // const handlePause = (audioUrl: string) => {
  //   const audioElement = audioRefs.current[audioUrl];
  //   if (audioElement) {
  //     audioElement.pause();
  //   }
  //   setCurrentlyPlaying(null);
  // };
  const convertToTitleCase = (str: string) => {
    const words = str.split(/(?=[A-Z])/);
    const capitalizedWords = words.map(word => {
      // Capitalize the first character if it's in lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const convertedString = capitalizedWords.join(" ");
    return convertedString;
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "_CDR.xlsx"
    );
  };

  const downloadDataCall = async () => {
    try {
      setLoading(true);
      const response = await axios.get(mindtreeCDRDownloadUrl, {
        params: {
          startDate: fetchCalls[2].startDate,
          endDate: fetchCalls[2].endDate,
          campaignVal: fetchCalls[2].campaignVal,
          callerVal: !_.isEmpty(fetchCalls[2].callerVal)
            ? fetchCalls[2].callerVal
            : 0,
          agentVal: fetchCalls[2].agentVal,
          dispositionVal: fetchCalls[2].dispositionVal,
          locationVal: fetchCalls[2].locationVal,
          skillVal: fetchCalls[2].skillVal,
          statusVal: fetchCalls[2].statusVal,
          phoneVal: fetchCalls[2].phoneVal,
          size: fetchCalls[0].dataCount,
          from: 0,
        },
        timeout: 180000,
      });
      const data = response && response.data && response.data.Hits;
      downloadArr = [];
      Object.values(data).forEach((rowData: any) => {
        const orderedRow: any = {};
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = rowData.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(rowData[id]))
            : undefined;
          // Additional processing for specific columns if needed
          if (id === "transcript" && orderedRow[id] !== undefined) {
            orderedRow[id] = JSON.parse(
              JSON.stringify(orderedRow[id].join(";"))
            );
          }
          // Add more conditions for other columns if needed
        });
        downloadArr.push(orderedRow);
      });
      console.log(downloadArr);
      setDownloadData(downloadArr);
      exportToExcel(downloadArr);
    } catch (error) {
      setLoading(false); // Set isLoading to false when done or if an error occurs
      console.log(error);
      window.alert("Error Calling Api to Download");
    } finally {
      setLoading(false); // Set isLoading to false when done or if an error occurs
    }
  };

  function splitStringWithNewlines(stringArray: []) {
    if (_.isArrayLikeObject(stringArray) || _.isArray(stringArray)) {
      const labels =
        stringArray &&
        stringArray.map((line: any, index: any) =>
          line && !_.isEmpty(line)? (
            <label key={index}>{line}</label>
          ) : (
            ""
          )
        );
        console.log("Splitted labels ::",labels)
      return labels;
    }    
  }

  const handleDownload = (e: any) => {
    downloadDataCall();
  };

  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    return (
      <AgentDataDetails
        getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
      ></AgentDataDetails>
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage;
    var size = rowsPerPage;
    fetchCalls[1](
      fetchCalls[2].startDate,
      fetchCalls[2].endDate,
      fetchCalls[2].campaignVal,
      fetchCalls[2].callerVal,
      fetchCalls[2].agentVal,
      fetchCalls[2].dispositionVal,
      fetchCalls[2].locationVal,
      fetchCalls[2].skillVal,
      fetchCalls[2].statusVal,
      fetchCalls[2].phoneVal,
      size,
      from
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = parseInt(event.target.value, 10);

    fetchCalls[1](
      fetchCalls[2].startDate,
      fetchCalls[2].endDate,
      fetchCalls[2].campaignVal,
      fetchCalls[2].callerVal,
      fetchCalls[2].agentVal,
      fetchCalls[2].dispositionVal,
      fetchCalls[2].locationVal,
      fetchCalls[2].skillVal,
      fetchCalls[2].statusVal,
      fetchCalls[2].phoneVal,
      size,
      "0"
    );
  };

  if (fetchCalls != undefined && fetchCalls.length != 0) {
    if (fetchCalls[0]["Hits"] != undefined) {
      HitsArr = fetchCalls[0]["Hits"];
    }
  }

  const handleSortingChange = (sortField: any) => {
    setTableData([]);
    const sortOrder = order === "asc" ? "desc" : "asc";
    setSortColumn(sortField);
    setOrder(sortOrder);
    if (sortField != null || !sortField.equals("0") || sortField != undefined) {
      const sorted = [...metaArr].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  useEffect(() => {
    setTableData(getData());
  }, [fetchCalls]);

  return (
    <Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "yellow" }}>
              <TableRow>
                <>
                  {columns.map((column, accessor) => {
                    return (
                      <StyledTableCell
                        key={accessor}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1976d2",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSortingChange(column.id)}
                      >
                        {column.label}
                        {order === "asc" ? (
                          <img
                            alt="upArrow"
                            style={{ height: "12px", paddingLeft: "5px" }}
                            src={images.sortAsc}
                          />
                        ) : order == "" ? null : (
                          <img
                            alt="downArrow"
                            style={{ height: "12px" }}
                            src={images.sortDsc}
                          />
                        )}
                      </StyledTableCell>
                    );
                  })}
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {tableData &&
                  tableData.map((row: any, rowIdx: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.SNO}
                      >
                        {columns.map((column) => {
                          if (column.id === "AudioFile") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {audioFile(row.AudioFile)}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === "transcript") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                <div style={{display: "grid"}}>
                                  {splitStringWithNewlines(row.transcript)}
                                </div>
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx}
                                align={column.align}
                              >
                                {row[column.id]}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={
            fetchCalls[0]["dataCount"] > 0 ? fetchCalls[0]["dataCount"] : 0
          } // This is what your request should be returning in addition to the current page of rows.
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}
        >
          X
        </span>
        {getPopoverContent()}
      </Popover>
      {loading ? (
        <div className="loader_digital" id="loader_digital"></div>
      ) : (
        <Button
          style={{ float: "right", marginTop: "-10px", marginRight: "22px" }}
          onClick={handleDownload}
        >
          Download
        </Button>
      )}
    </Card>
  );
}

function audioFile(url: string) {
  const aud = <audio controls controlsList="nodownload" src={url}></audio>;
  return aud;
}
