import React from "react";
import VoiceBar from "./VoiceBar";
import TimeBar from "./TimeBar";
import TimelineDiv from "./TimelineDiv";
import SpeechAnalytics from "./SpeechAnalytics";
import SpeechInsights from "./SpeechInsights";
import CustomerAgentIcons from "./CustomerAgentIcons";
import styled from "styled-components";
import "../App.css";

const ChatMessage = styled.div`
margin: 12px;
padding: 10px;
border-radius: 9px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
background-color:#fdfdfd;
width:fit-content;
height:fit-content;
justify-self:${props => props.edge === 1 ? "start" : "end"};
`
const ChatIntentMessage = styled.div`
margin: 12px;
box-sizing: border-box;
padding: 10px;
width:fit-content;
height:fit-content;
left: 350px;
top: 304px;
background: #FFFFFF;
border: 1px solid #008DFF;
border-radius: 4px;
margin-top:-20px;
justify-self:${props => props.edge === 1 ? "start" : "end"};
`

const thresholdData_0 = {
    "speaking_rate":
    {
        "higher_threshold": "200",
        "lower_thershold": "80",
    },
    "loudness":
    {
        "higher_threshold": "65",
        "lower_thershold": "55",
    },

    "talk_duration":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "listen_duration":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "participation_ratio":
    {
        "higher_threshold": "0.8",
        "lower_thershold": "0.3",
    },
    "no_of_interupts":
    {
        "higher_threshold": "12",
        "lower_thershold": "0",
    },

    "interupt_duration":
    {
        "higher_threshold": "15",
        "lower_thershold": "0",
    },
    "information_retrival_time":
    {
        "higher_threshold": "30",
        "lower_thershold": "0",
    },
    "snr":
    {
        "higher_threshold": "20",
        "lower_thershold": "0",
    },
    "aht":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "sentiment_threshold":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",

    }
}
const thresholdData_1 = {
    "speaking_rate":
    {
        "higher_threshold": "200",
        "lower_thershold": "80",
    },
    "loudness":
    {
        "higher_threshold": "65",
        "lower_thershold": "55",
    },

    "talk_duration":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "listen_duration":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "participation_ratio":
    {
        "higher_threshold": "0.8",
        "lower_thershold": "0.3",
    },
    "no_of_interupts":
    {
        "higher_threshold": "12",
        "lower_thershold": "0",
    },

    "interupt_duration":
    {
        "higher_threshold": "15",
        "lower_thershold": "0",
    },
    "information_retrival_time":
    {
        "higher_threshold": "30",
        "lower_thershold": "0",
    },
    "snr":
    {
        "higher_threshold": "20",
        "lower_thershold": "0",
    },
    "aht":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",
    },
    "sentiment_threshold":
    {
        "higher_threshold": "0",
        "lower_thershold": "0",

    }
}

export const CallTimeLine = (getChatTranscript) => {
    const transcriptionArray = getChatTranscript.getTranscript.transcription
    const endTime = transcriptionArray[transcriptionArray.length - 1].end
    const speechInsights = []
    const callTimeMin = Math.floor(endTime / 60)
    const callTimeSec = Math.floor(endTime % 60)
    speechInsights.push('Total time of conversation:' + callTimeMin + ' minutes ' + callTimeSec + ' seconds')
    speechInsights.push('Overall customer sentiment in the call: ' + getChatTranscript.getTranscript.conversation_report[0].sentiment[0].label)
    speechInsights.push('Overall agent sentiment in the call: ' + getChatTranscript.getTranscript.conversation_report[1].sentiment[0].label)
    
    if (getChatTranscript.getTranscript.conversation_report[0].speaking_rate > getChatTranscript.getTranscript.conversation_report[1].speaking_rate)
        speechInsights.push("Agent spoke slower than the customer")
    else
        speechInsights.push("Agent spoke faster than the customer")
    if (getChatTranscript.getTranscript.conversation_report[1].speaking_rate > 110)
        speechInsights.push("Agent spoke too fast")

    //loop throough transcription array and get transcription with channel =0
    let customerTranscription = [];
    let agentTranscription = [];

    transcriptionArray.forEach(function (data) {
        if (data.channel === 0) {
            customerTranscription.push(data)
        } else if (data.channel === 1) {
            agentTranscription.push(data)
        }
    })
    
    return (
        <>        
            <div className="col-xs-12">
                <SpeechAnalytics className="transcript-timeline" style={{ overFlow: "overlay" }}>
                    <div><p style={{ inlineSize: "max-content" }} className="insight">Call Overview</p></div>
                    <div className="col-xs-3">
                        <CustomerAgentIcons>
                        </CustomerAgentIcons>
                    </div>
                    <div className="col-xs-9">
                        <TimelineDiv calcWidth={Math.ceil(Math.ceil(endTime) / 60) * 70}>
                            <VoiceBar endTime={Math.ceil(endTime)} position="end" transcription={customerTranscription} type="customer" />
                            <TimeBar endTime={Math.ceil(endTime)} />
                            <VoiceBar endTime={Math.ceil(endTime)} position="start" transcription={agentTranscription} type="agent" />
                        </TimelineDiv>
                    </div>
                </SpeechAnalytics>
            </div>
            <div className="horline"></div>

            {/*Grid 2nd column to 5th column*/}
            <div className="col-xs-12">
                <div className="wrap">
                    <SpeechInsights style={{ width: "329px" }}>
                        <div className="col-xs-4" style={{ padding: "12px 5px 4px 24px" }}>
                            <div className="speech-font-view" style={{ marginTop: "1px" }}>
                                <div className="insight">Call Metrics</div>
                                {/* <div className="panel panel-third"> */}
                                <table style={{margin:"3px"}}>                                    
                                    <tr>
                                        <td></td>
                                        <th>Agent</th>
                                        <th>Customer</th>
                                    </tr>
                                    <tr>
                                        <td>Rate of Speech</td>
                                       
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].speaking_rate}
                                            </span>
                                            {getChatTranscript.getTranscript.conversation_report[1].speaking_rate > thresholdData_1.speaking_rate.higher_threshold? 
                                            <span className="triangle-up"> </span>
                                            :
                                            getChatTranscript.getTranscript.conversation_report[1].speaking_rate < thresholdData_1.speaking_rate.lower_thershold?
                                            <span className="triangle-down"></span>
                                            :null
                                            }
                                        </td>
                                      
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].speaking_rate}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[0].speaking_rate > thresholdData_0.speaking_rate.higher_threshold? 
                                            <span className="triangle-up"> </span>
                                            :
                                            getChatTranscript.getTranscript.conversation_report[0].speaking_rate < thresholdData_0.speaking_rate.lower_thershold?
                                            <span className="triangle-down"></span>
                                            :null
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Volume</td>

                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].loudness}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[1].loudness > thresholdData_1.loudness.higher_threshold? 
                                            <span className="triangle-up"> </span>
                                            :
                                            getChatTranscript.getTranscript.conversation_report[1].loudness < thresholdData_1.loudness.lower_thershold?
                                            <span className="triangle-down"></span>
                                            :null
                                            }
                                        </td>

                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].loudness}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[0].loudness > thresholdData_0.loudness.higher_threshold? 
                                            <span className="triangle-up"> </span>
                                            :
                                            getChatTranscript.getTranscript.conversation_report[0].loudness < thresholdData_0.loudness.lower_thershold?
                                            <span className="triangle-down"></span>
                                            :null
                                            }</td>
                                       
                                    </tr>
                                    <tr>
                                        <td>Talk Duration</td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.talk2listen_analysis.talk_duration}
                                            </span>
                                        <div id="tick-mark"></div>
                                        </td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.talk2listen_analysis.talk_duration}
                                            </span>
                                        <div id="tick-mark"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Listen Duration</td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.talk2listen_analysis.listen_duration}
                                            </span>
                                        <div id="tick-mark"></div>
                                        </td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.talk2listen_analysis.listen_duration}
                                            </span>
                                        <div id="tick-mark"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Interrupts</td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.interuption_analysis.no_of_interupts}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.interuption_analysis.no_of_interupts>thresholdData_1.no_of_interupts.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        
                                        </td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.interuption_analysis.no_of_interupts}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.interuption_analysis.no_of_interupts>thresholdData_0.no_of_interupts.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Interrupt Duration</td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.interuption_analysis.interupt_duration}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.interuption_analysis.interupt_duration>thresholdData_1.interupt_duration.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        </td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.interuption_analysis.interupt_duration}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.interuption_analysis.interupt_duration>thresholdData_0.interupt_duration.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Dead Air</td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.information_retrival_analysis.information_retrival_time}
                                            </span>
                                        {getChatTranscript.getTranscript.conversation_report[1].conversation_analysis.information_retrival_analysis.information_retrival_time>thresholdData_1.information_retrival_time.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        
                                        </td>
                                        <td>
                                        <span className="ex1">
                                            {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.information_retrival_analysis.information_retrival_time}
                                         </span>
                                        {getChatTranscript.getTranscript.conversation_report[0].conversation_analysis.information_retrival_analysis.information_retrival_time>thresholdData_0.information_retrival_time.higher_threshold?
                                        <span className="triangle-up"> </span>:null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sentiment</td>
                                        <td>
                                        <span className="ex1">
                                        {getChatTranscript.getTranscript.conversation_report[1].sentiment[0].label==='negative'?
                                        <div className="colk_red">
                                            {getChatTranscript.getTranscript.conversation_report[1].sentiment[0].label}
                                            </div>:
                                            <div className="colk">
                                            {getChatTranscript.getTranscript.conversation_report[1].sentiment[0].label}
                                            </div>}
                                            </span>
                                        </td>
                                        <td>
                                        <span className="ex1">
                                        {getChatTranscript.getTranscript.conversation_report[0].sentiment[0].label==='negative'?
                                        <div className="colk_red">
                                            {getChatTranscript.getTranscript.conversation_report[0].sentiment[0].label}
                                            </div>:
                                            <div  className="colk">
                                            {getChatTranscript.getTranscript.conversation_report[0].sentiment[0].label}
                                            </div>}
                                            </span>
                                        </td>
                                    </tr>                                
                                </table>
                            </div>
                            <div className="speech-font-view" style={{ marginTop: "19px", padding: "0px 0px 10px" }}>
                                <div className="insight">Insights</div>
                            </div>
                            {speechInsights.map(speechIns => {
                                return <p className="speech-font-view" style={{ fontFamily: "roboto", padding: "0px 10px" }}>{speechIns}</p>
                            })}


                        </div>

                        <div className="col-xs-8">
                            <div className="chatBoxDiv" style={{ position: "absolute", left: "336px", top: "245px", borderLeft: "solid 0.9px #979797", padding: "2px 51px 72px 21px",width: "200%"  }}>
                                <div className="chatSegment" 
                                >
                                    <div className="insight">Call Transcript</div>
                                    {
                                        transcriptionArray.map((data, key) => {
                                            return (
                                                <div className={data.channel === '0' ? 'agent-transcript' : 'customer-transcript'}>
                                                    <div className="col-xs-2">
                                                        key={key}
                                                        {data.transcript !== '' ?
                                                            <ChatMessage edge={data.channel} className={data.channel === '0' ? 'agent-msg-transcript' : 'customer-msg-transcript'}>
                                                                <div className="col-xs-6">
                                                                    {data.call_intent.intents[0] !== '' && data.call_intent.intents[0] !== null ?
                                                                        <ChatIntentMessage edge={data.channel} className={data.channel === '0' ? 'agent-intent-transcript' : 'customer-intent-transcript'}>
                                                                            {data.call_intent.intents[0]}
                                                                        </ChatIntentMessage> : null}
                                                                </div>
                                                                {data.transcript}
                                                            </ChatMessage> : null}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </SpeechInsights>
                </div>
            </div>
        </>
    );

}

