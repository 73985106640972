import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button, Menu, MenuItem } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import _ from "lodash";
import "../App.css";
import * as XLSX from "xlsx";
import "../components/loader.css";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";
import "../App.css";
import { log } from "console";

interface Column {
  id:
  |"audio_url"
  |"score"
  |"summary"
  |"score_fatal"
  |"call_time"
  |"CallerID"
  |"call_type" 
  |"EndTime"
  |"StartTime"
  | "call_duration"
  |"disposition"
  |"ucid"
  |"agent"
  |"call_date"
  |"campaign"
  |"callopening_5sec"
  |"opening_greeting"
  |"opening_selfintro"
  |"brand_name_check"
  |"paraphrasing"
  |"showing_intresting_thanking_outbound"
  |"requirements_understanding"
  |"looking_for_underconstruction_or_readytomove"
  |"offering_assistance_opening"
  |"name_confirmation_outbound"
  |"continuing_previous_interaction"
  |"budget_check"
  |"configurations_carpetarea_check"
  |"customer_location_check"
  |"investment_self_check"
  |"objection_handling_check"
  |"effective_timelyresponses_check"
  |"urgencey_creation"
  |"correct_property_location_check"
  |"correct_usp_check"
  |"correct_configuration_check"
  |"correct_pricing_check"
  |"correct_amenities_check"
  |"campaign_scheme"
  |"offer_payment_plan"
  |"correct_possession_timeline"
  |"confidence_check"
  |"active_listeining"
  |"empathy_assurance_check"
  |"interruption_avoidence"
  |"tone_modulation"
  |"energetic_sound"
  |"fillers"
  |"rate_of_speech"
  |"rudeness_avoidence_check"
  |"abusiveness_avoidence_check"
  |"hold_placing"
  |"hold_duration"
  |"language_switch"
  |"personalization"
  |"jargons_avoidence"
  |"pick_and_drop_check"
  |"virtual_presentation_check"
  |"followup_visit_date_time_check"
  |"summarization"
  |"thanking_closing"
  |"greeting_closing"
  |"areas_of_improvement"
  |"language"
  |"training_needs_identification"
    ;
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [

  { id: "audio_url", label: "Audio", minWidth: 150 },
  { id: "score", label: "Score without fatal", minWidth: 100 },
  { id: "score_fatal", label: "score with fatal", minWidth: 100 },
  { id: "call_date", label: "Call Date", minWidth: 100 },
  { id: "StartTime", label: "Start Time", minWidth: 100 },
  { id: "EndTime", label: "End Time", minWidth: 100 },
  { id: "call_type", label: "Call Type", minWidth: 120 },
  { id: "CallerID", label: "Caller ID", minWidth: 100 },
  { id: "call_time", label: "Call Time", minWidth: 100 },
  { id: "call_duration", label: "Call Duration", minWidth: 100 },
  { id: "language", label: "Language", minWidth: 100 },
  { id: "agent", label: "Agent Name", minWidth: 150 },
  { id: "ucid", label: "UCID", minWidth: 100 },
  { id: "campaign", label: "Campaign", minWidth: 120 },
  { id: "disposition", label: "Disposition", minWidth: 120 },

  { id: "callopening_5sec", label: "Call Opening (5 Sec)", minWidth: 100 },
  { id: "opening_greeting", label: "Opening Greeting", minWidth: 100 },
  { id: "opening_selfintro", label: "Self Introduction", minWidth: 100 },
  { id: "brand_name_check", label: "Brand Name Check", minWidth: 100 },
  { id: "paraphrasing", label: "Paraphrasing", minWidth: 100 },
  { id: "showing_intresting_thanking_outbound", label: "Showing Interest & Thanking (Outbound)", minWidth: 150 },
  { id: "requirements_understanding", label: "Understanding Requirements", minWidth: 150 },
  { id: "looking_for_underconstruction_or_readytomove", label: "Looking for Under Construction or Ready to Move", minWidth: 200 },
  { id: "offering_assistance_opening", label: "Offering Assistance (Opening)", minWidth: 150 },
  { id: "name_confirmation_outbound", label: "Name Confirmation (Outbound)", minWidth: 150 },
  { id: "continuing_previous_interaction", label: "Continuing Previous Interaction", minWidth: 180 },
  { id: "budget_check", label: "Budget Check", minWidth: 100 },
  { id: "configurations_carpetarea_check", label: "Configuration & Carpet Area Check", minWidth: 180 },
  { id: "customer_location_check", label: "Customer Location Check", minWidth: 150 },
  { id: "investment_self_check", label: "Investment Self Check", minWidth: 150 },
  { id: "objection_handling_check", label: "Objection Handling", minWidth: 150 },
  { id: "effective_timelyresponses_check", label: "Effective & Timely Responses", minWidth: 180 },
  { id: "urgencey_creation", label: "Urgency Creation", minWidth: 150 },
  { id: "correct_property_location_check", label: "Correct Property Location Check", minWidth: 200 },
  { id: "correct_usp_check", label: "Correct USP Check", minWidth: 150 },
  { id: "correct_configuration_check", label: "Correct Configuration Check", minWidth: 180 },
  { id: "correct_pricing_check", label: "Correct Pricing Check", minWidth: 150 },
  { id: "correct_amenities_check", label: "Correct Amenities Check", minWidth: 180 },
  { id: "campaign_scheme", label: "Campaign Scheme", minWidth: 150 },
  { id: "offer_payment_plan", label: "Offer Payment Plan", minWidth: 150 },
  { id: "correct_possession_timeline", label: "Correct Possession Timeline", minWidth: 200 },
  { id: "confidence_check", label: "Confidence Check", minWidth: 150 },
  { id: "active_listeining", label: "Active Listening", minWidth: 150 },
  { id: "empathy_assurance_check", label: "Empathy & Assurance", minWidth: 150 },
  { id: "interruption_avoidence", label: "Interruption Avoidance", minWidth: 180 },
  { id: "tone_modulation", label: "Tone Modulation", minWidth: 150 },
  { id: "energetic_sound", label: "Energetic Sound", minWidth: 150 },
  { id: "fillers", label: "Fillers", minWidth: 100 },
  { id: "rate_of_speech", label: "Rate of Speech", minWidth: 150 },
  { id: "rudeness_avoidence_check", label: "Rudeness Avoidance", minWidth: 150 },
  { id: "abusiveness_avoidence_check", label: "Abusiveness Avoidance", minWidth: 180 },
  { id: "hold_placing", label: "Hold Placing", minWidth: 150 },
  { id: "hold_duration", label: "Hold Duration", minWidth: 150 },
  { id: "language_switch", label: "Language Switch", minWidth: 150 },
  { id: "personalization", label: "Personalization", minWidth: 150 },
  { id: "jargons_avoidence", label: "Jargon Avoidance", minWidth: 150 },
  { id: "pick_and_drop_check", label: "Pick and Drop Check", minWidth: 180 },
  { id: "virtual_presentation_check", label: "Virtual Presentation Check", minWidth: 200 },
  { id: "followup_visit_date_time_check", label: "Follow-up Visit Date & Time Check", minWidth: 200 },
  { id: "summarization", label: "Summarization", minWidth: 150 },
  { id: "thanking_closing", label: "Thanking (Closing)", minWidth: 150 },
  { id: "greeting_closing", label: "Greeting (Closing)", minWidth: 150 },

  { id: "summary", label: "Summary", minWidth: 250 },
  { id: "training_needs_identification", label: "Training needs Identification", minWidth: 250 },
  { id: "areas_of_improvement", label: "Areas of Improvement", minWidth: 250 },

];

let metaArr: any[] = [];

const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((data) => {
    const obj = JSON.parse(JSON.stringify(data));
    metaArr.push(obj);
  });
  return metaArr;
};


type Props = {
  fetchCalls: any;
};

let HitsArr = {};
let downloadArr: any[] = [];

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function OpensearchCallsTable({ fetchCalls }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);                                                                                                                                                                                                                                                                                                                                                                 
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  // const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  // const [anchorAudio, setAnchorAudio] = React.useState<null | HTMLElement>(
  //   null
  // );
  const [downloadMenuAnchor, setDownloadMenuAnchor] = React.useState(null);
  const downloadMenuOpen = Boolean(downloadMenuAnchor)
  const tableRef = useRef(null);
  // const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
  const sunteckRealtyCallsTabDownloadUrl =
    process.env.REACT_APP_BASE_URL + "/sunteck/getCallsDataDownload";

  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };

  function exportToCsv(downloadArr: any[]) {
    const csvData = downloadArr.map((row) =>
     columns.map((column) => row[column.id]).join(",")
   );
   const csvContent =
     "data:text/csv;charset=utf-8," +
     columns.map((column) => column.label).join(",") +
     "\n" +
     csvData.join("\n");
   const encodedUri = encodeURI(csvContent);
   const link = document.createElement("a");
   link.setAttribute("href", encodedUri);
   link.setAttribute(
     "download",
     String(localStorage.getItem("user")) + "_Calls.csv"
   );
   document.body.appendChild(link); // Required for FF
   link.click();
 }
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "_Calls.xlsx"
    );
  };
  interface LanguageMapping {
    [key: string]: string;
  }

  const languageMapping: LanguageMapping = {
    "en-IN": "English",
    "ta-IN": "Tamil",
    "ml-IN": "Malayalam",
    "mr-IN": "Marathi",
    "hi-IN": "Hindi",
    "te-IN": "Telugu",
    "kn-IN": "Kannada",
    "gu-IN": "Gujarati",
    // Add more language codes and names as needed
  };

  const getLanguageName = (languageCode: string): string => {
    let lang =  languageMapping[languageCode] || "Language not found";
    return lang;
  };
  function getLanguageNames(languageCodes:any):string[] {

    if (!Array.isArray(languageCodes)) {
      getLanguageName(languageCodes)
    }
    return languageCodes.map((code: string) => {
      const lang = languageMapping[code] || "Language not found";
      return lang;
    }
    );
  }

  const getLanguageCode = (languageName: string): string => {
    const languageMapping: LanguageMapping = {
      "English": "en-IN",
      "Tamil": "ta-IN",
      "Malayalam": "ml-IN",
      "Marathi": "mr-IN",
      "Hindi": "hi-IN",
      "Telugu": "te-IN",
      "Kannada": "kn-IN",
      "Gujarati": "gu-IN",
      // Add more language codes and names as needed
    };
    return languageMapping[languageName] || "0";
  };

   const [pageNav, setPageNav] = useState<boolean>(false);

   const downloadDataCall = async (type: string) => {
    try {
      setLoading(true);
      const response = await fetchCalls[2]();
      const data = response && response.data && response.data.Hits;
      downloadArr = [];
  
      Object.values(data).forEach((rowData: any) => {
        const orderedRow: any = {};
  
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = rowData.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(rowData[id]))
            : undefined;
  
          // Process language field
          if (id === "language" && orderedRow[id] !== undefined) {
            orderedRow[id] = getLanguageName(orderedRow[id]); // No need for JSON parsing or join
          }
        });
  
        downloadArr.push(orderedRow);
      });
  
      console.log(downloadArr);
      setDownloadData(downloadArr);
  
      if (type === "excel") {
        exportToExcel(downloadArr);
      } else if (type === "csv") {
        exportToCsv(downloadArr);
      }
    } catch (error) {
      downloadArr = [];
      setLoading(false);
      console.log(error);
      window.alert("Error Calling API to Download");
    } finally {
      downloadArr = [];
      setLoading(false);
    }
  };
  

  const handleDownload = (type: string) => { 
    closeDownloadMenu();
    downloadDataCall(type);
  };

  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    if (
      !_.isEmpty(responseData) &&
      !_.isEmpty(agentCountFinal) &&
      !_.isEmpty(avgDurationFinal)
    ) {
      return (
        <AgentDataDetails
          getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
        ></AgentDataDetails>
      );
    } else {
      return <AgentDataDetails></AgentDataDetails>;
    }
  };


      const handleChangePage = (event: unknown, newPage: number) => {
        setTableData([]);
        setPage(newPage);
        setPageNav(true);
        var from = newPage === 0 ? 0 : newPage * rowsPerPage;
        var size = rowsPerPage;
        fetchCalls[1](
          size,
          from
        );
      };
      const handleDownloadClick = (event: any) => {
        closeDownloadMenu();
        setElRefPoint(event.currentTarget);
        setDownloadMenuAnchor(event.currentTarget );
      };
      const closeDownloadMenu = () => {
        setDownloadMenuAnchor(null);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTableData([]);
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
      
        fetchCalls[1](
       parseInt(event.target.value, 10),
          "0"
        );
      };
    
      if (fetchCalls != undefined && fetchCalls.length != 0) {
        if (fetchCalls[0]["Hits"] != undefined) {
          HitsArr = fetchCalls[0]["Hits"];
        }
      }
    
    
      useEffect(() => {
        if(!pageNav){
          setPage(0);
        }
        setTableData(getData());
        setPageNav(false);
      }, [fetchCalls[0]]);

  return (
    <Card>
    {/* Box for RowsPerPage and Download button */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        ml: 100,
      }}
    >
      {/* RowsPerPage Options */}
      <TablePagination
    rowsPerPageOptions={[50, 100, 250]}
    component="div"
    count={
      fetchCalls[0]["dataCount"] > 0 ? fetchCalls[0]["dataCount"] : 0
    } // This is what your request should be returning in addition to the current page of rows.
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
      />
  
      {/* Download Button */}
      <Button variant="contained" color="primary" onClick={handleDownloadClick}>
        Download
      </Button>
      <Menu
                            id="basic-menu"
                            anchorEl={downloadMenuAnchor}
                            open={downloadMenuOpen}
                            onClose={closeDownloadMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem dense key={'excel'} onClick={() => handleDownload('excel')}>Excel</MenuItem>
                            <MenuItem dense key={'csv'} onClick={() => handleDownload('csv')}>CSV</MenuItem>
              </Menu>
    </Box>
  
    <Paper>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: "yellow" }}>
            <TableRow>
              {localStorage.getItem("user") === "sunteck_realty" ? (
                <>
                  {columns.map((column, accessor) => {
                    return (
                      <StyledTableCell
                        key={accessor}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1976d2",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {column.label}
                        {order === "asc" ? (
                          <img
                            alt="upArrow"
                            style={{ height: "12px", paddingLeft: "5px" }}
                            src={images.sortAsc}
                          />
                        ) : order == "" ? null : (
                          <img
                            alt="downArrow"
                            style={{ height: "12px" }}
                            src={images.sortDsc}
                          />
                        )}
                      </StyledTableCell>
                    );
                  })}
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {localStorage.getItem("user") === "sunteck_realty" ? (
              <>
                {tableData &&
                  tableData.map((row: any, rowIdx: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.SNO}_${rowIdx}`}
                      >
                        {columns.map((column, keyId) => {
                          if (column.id === "audio_url") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {audioFile(row.audio_url)}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === "language") {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {getLanguageName(row[column.id])}
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {row[column.id]}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  
    <Popover
      open={isPopoverOpen}
      onClose={handleOnPopoverClose}
      anchorReference="anchorPosition"
      anchorPosition={eleRefPoint}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <span
        className="cs-modal-close"
        onClick={(e) => {
          handleOnPopoverClose();
        }}
      >
        X
      </span>
      {getPopoverContent()}
    </Popover>
  </Card>
  
  );
}

function audioFile(url: string) {
  const aud = <audio controls controlsList="nodownload" src={url}></audio>;
  return aud;
}


