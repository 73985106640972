import printIcon from "../../assets/images/print-icon.png"
import calenderIcon from "../../assets/images/iconCalendar.png"
import goToFirstPage from "../../assets/svgs/goFirst.svg" 
import goToPrevious from "../../assets/svgs/goPrevious.svg"
import goToLastPage from "../../assets/svgs/goLast.svg"
import goToNext from "../../assets/svgs/goNext.svg"
import downloadicon from "../../assets/svgs/downloadIcon.svg"
import tableIcon from "../../assets/images/tableImage.png"
import loading from "../../assets/images/loadingAnimation.gif"
import upArrow from "../../assets/images/arrow-up.png"
import downArrow from "../../assets/images/arrow-down.png"
import warning from "../../assets/images/iconWarning.png"
import sortAsc from "../../assets/images/sort-asc.svg"
import sortDsc from "../../assets/images/sort-dsc.svg"

export {warning,downArrow,upArrow,loading,tableIcon,printIcon,calenderIcon,goToFirstPage,goToPrevious,goToLastPage,goToNext,downloadicon,sortAsc,sortDsc}



