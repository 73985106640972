import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import Axios from "axios";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import { MultiSelect } from "react-multi-select-component";
import DropDown from "../assets/svgs/arrow_drop_down.svg";
import DropUp from "../assets/svgs/arrow_drop_up.svg";
import ClearIcon from "../assets/svgs/clear.svg";
import * as XLSX from "xlsx";
import DownloadIcon from '@mui/icons-material/Download';


import * as _ from "lodash";
import { Pie } from "react-chartjs-2";
import BisleriAuditTable from "./BisleriAuditTable";
import BisleriCallsTable from "./BisleriCallsTable";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const languageCodeMap = {
  "en-IN": "English",
  "ta-IN": "Tamil",
  "ml-IN": "Malayalam",
  "mr-IN": "Marathi",
  "hi-IN": "Hindi",
  "te-IN": "Telugu",
  "kn-IN": "Kannada",
  "gu-IN": "Gujarati",
  // Add more language codes and names as needed
};
const langVariant = [
  { label: "English", value: "en-IN" },
  { label: "Tamil", value: "ta-IN" },
  { label: "Malayalam", value: "ml-IN" },
  { label: "Marathi", value: "mr-IN" },
  { label: "Hindi", value: "hi-IN" },
  { label: "Telugu", value: "te-IN" },
  { label: "Kannada", value: "kn-IN" },
  { label: "Gujarati", value: "gu-IN" },
];
const scores = ["None", ">", "<"];
const BisleriAuditFiltersURL =
  process.env.REACT_APP_BASE_URL + "/bisleri/openSearchAuditFilters";
const BisleriAuditURL =
  process.env.REACT_APP_BASE_URL + "/bisleri/getOpenAuditData";
const BisleriAuditCallsURL =
  process.env.REACT_APP_BASE_URL + "/bisleri/getCallsData";

  const BisleriCallsTabDownloadUrl =
  process.env.REACT_APP_BASE_URL + "/bisleri/getCallsDataDownload";

const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");
var objFilter = {};
var campaignFinalFilter = [];
var languageFinalFilter = [];
var skillFinalFilter = [];
var callTypeFinalFilter = [];
var agentFinalFilter = {};
var dispositionFinalFilter = {};

export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const BisleriDashBoard = () => {
  // alert("check");
  const [dashboardAuditData, setDashboardAuditData] = useState({});
  const [jsonOpenAuditData, setJsonOpenAuditData] = useState({});
  const [jsonOpenCallsData, setJsonOpenCallsData] = useState({});
  const [load, setIsload] = useState(true);
  const startDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const emdDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const [startDateAudit, setStartDateAudit] = useState(startDateAuditVal);
  const [endDateAudit, setEndDateAudit] = useState(emdDateAuditVal);
  const [campaignAuditVal, setCampaignAuditVal] = React.useState([]);
  const [dispositionAuditVal, setDispositionAuditVal] = React.useState([]);
  const [languageAuditVal, setLanguageAduitVal] = React.useState([]);
  const [skillAuditVal, setSkillAuditVal] = React.useState([]);
  const [callTypeAuditVal, setCallTypeAuditVal] = React.useState([]);
  const [scoreAuditVal, setScoreAuditVal] = React.useState([]);
  const [callerIDAuditVal, setCallerIDAuditVal] = React.useState([]);
  const [ucidAuditVal, setUcidAuditVal] = React.useState([]);
  const [agentAuditOptions, setAgentAuditOptions] = React.useState([]);
  const [campaignAuditOptions, setCampaignAuditOptions] = React.useState([]);
  const [languageAuditOptions, setLanguageAuditOptions] = React.useState([]);
  const [skillOptions, setSkillOptions] = React.useState([]);
  const [callTypeOptions, setCallTypeOptions] = React.useState([]);
  const [dispositionAuditOptions, setDispositionAuditOptions] = React.useState(
    []
  );
  const [value, setValue] = useState("1");
  const [audGraphVal, setAudGraphVal] = React.useState("campaign");
  const [audPieVal, setAudPieVal] = React.useState("language");
  const [scoreInputVal, setScoreInputVal] = React.useState([]);
  const [checkCampaign, setCheckCampaign] = React.useState(true);
  const [checkLanguage, setCheckLanguage] = React.useState(true);
  const [scoreSelected, setScoreSelected] = React.useState(false);
  const [agentAuditVal, setAgentAuditVal] = React.useState([]);
  const handleChangeAudGraph = (event) => {
    if (event.target.value !== "campaign") setCheckCampaign(false);
    else setCheckCampaign(true);
    setAudGraphVal(event.target.value);
  };
  const handleChangeAudPie = (event) => {
    if (event.target.value !== "language") setCheckLanguage(false);
    else setCheckLanguage(true);
    setAudPieVal(event.target.value);
  };
  const handleChangeDispositionAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setDispositionAuditVal(fil);
  };
  const handleChangeLanguageAudit = (event) => {
    const fil = handleFilter(event);
    setLanguageAduitVal(fil);
  };
  const handleChangeCampaignAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setCampaignAuditVal(fil);
  };
  const handleChangeSkillAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setSkillAuditVal(fil);
  };
  const handleChangeCallTypeAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setCallTypeAuditVal(fil);
  };
  const handleChangeScoreAudit = (event) => {
    const {
      target: { value },
    } = event;
    setScoreAuditVal(event.target.value);
    if (
      event.target.value === "<" ||
      event.target.value === ">" ||
      event.target.value === "="
    ) {
      setScoreSelected(true);
    } else {
      setScoreSelected(false);
    }
  };
  const handleChangeScoreInput = (event) => {
    setScoreInputVal(event.target.value);
  };

  const handlechangeUcidVal = (event) => {
    const regex = /^[0-9]*$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setUcidAuditVal(event.target.value);
    }
  };

  const handlechangeCallerIDVal = (event) => {
    const regex = /^[0-9]*$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setCallerIDAuditVal(event.target.value);
    }
  };

  const handleChangeAgentAudit = (selectedOptions) => {
    const fil = handleFilters(selectedOptions);
    setAgentAuditVal(fil);
  };

  const handleFilters = (selectedOptions) => {
    let map = {};
    for (let item of selectedOptions) {
      map[item.value] = item;
    }
    const duplicateRemoved = Object.values(map);
    return duplicateRemoved;
  };
 

  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;

    let map = {};

    for (let list of value) {
      map[Object.values(list).join("")] = list;
    }

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) > 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    return duplicateRemoved;
  };
  if (window.localStorage.getItem("user") === "bisleri") {
    useEffect(() => {
      fetchAuditFiltersData();
    }, []);
  }
  if (window.localStorage.getItem("user") === "bisleri") {
    useEffect(() => {
      fetchOpenSearchAuditData(startDateAudit, endDateAudit);
    }, []);
  }

  if (window.localStorage.getItem("user") === "bisleri") {
    useEffect(() => {
      fetchOpenSearchCallsData(startDateAudit, endDateAudit);
    }, []);
  }
  if (window.localStorage.getItem("user") === "bisleri") {
    useEffect(() => {
      if (
        jsonOpenAuditData &&
        Object.keys(jsonOpenAuditData).length !== 0 
      ) {
        setDashboardAuditData(getOpenAuditGraphData());
      } else {
        setDashboardAuditData({});
      }
    }, [jsonOpenAuditData]);
  }
  const findOpenAuditDataName = (name,jsonData) => {
    for (const element of jsonData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  
  };

  const findFilters = (name) => {
    for (const element of objFilter) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };

  function getLanguageNames(languageCodes) {
    return languageCodes.map(
      (code) => languageCodeMap[code] || "Unknown Language"
    );
  }

  const getOpenAuditGraphData = () => {
    const languagePieAggregation = jsonOpenAuditData["Language count"];
    let languagePieLabel = [];
    let languagePieData = [];
    if (languagePieAggregation && languagePieAggregation.length !== 0) {
      languagePieLabel = getLanguageNames(languagePieAggregation[0].labels);
      languagePieData = languagePieAggregation[0].data;
    }
    const languagePieChartData = {
      labels: languagePieLabel,
      datasets: [
        {
          label: "# of calls in language",
          data: languagePieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const pieDispositionOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Disposition Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const pieChartsOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Language Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const dispositionPieAggregation = jsonOpenAuditData["dispostion count"];
    let dispositionPieLabel = [];
    let dispositionPieData = [];
    if (languagePieAggregation && dispositionPieAggregation.length !== 0) {
      dispositionPieLabel = dispositionPieAggregation[0].labels;
      dispositionPieData = dispositionPieAggregation[0].data;
    }
    const dispositionPieChartData = {
      labels: dispositionPieLabel,
      datasets: [
        {
          label: "# of call dispositions",
          data: dispositionPieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const timeScoreAggregation = jsonOpenAuditData["TimeScore Histogram"];
    let timeScoreAggrLabelPer = [];
    let timeScoreAggrDataPer = [];
    let timeScoreAggregationObj = {};
    if (!_.isEmpty(timeScoreAggregation) && timeScoreAggregation.length !== 0) {
      timeScoreAggregation[0].labels.forEach((element, index) => {
        timeScoreAggregationObj[element] = timeScoreAggregation[0].data[index];
      });
      Object.entries(timeScoreAggregationObj).forEach(([label, val]) => {
        timeScoreAggrLabelPer.push(label);
        timeScoreAggrDataPer.push(val.toFixed(1));
      });
    }

    const timeScoreData = {
      datasets: [
        {
          data: timeScoreAggrDataPer,
          backgroundColor: "#01FF84",
          // borderColor: "#000000",
          borderWidth: 1,
          label: "Trends of Score",
          fill: false,
          lineTension: 0.2,
          borderColor: "#01FF84",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#01FF84",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#01FF84",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        },
      ],
      labels: timeScoreAggrLabelPer,
    };

    const timeScoreOptions = {
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Trends Over Time",
        },
        scrollbar: {
          enabled: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      responsiveAnimationDuration: 0, // Disables animation for better performance
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const parameterAggregation = jsonOpenAuditData["Parameter-wise Adherence"];
    let parameterAggrLabelPer = [];
    let parameterAggrDataPer = [];
    let parameterAggregationObj = {};
    if (parameterAggregation  && parameterAggregation.length !== 0) {
      parameterAggregation[0].labels.forEach((element, index) => {
        parameterAggregationObj[element] = parameterAggregation[0].data[index];
      });
      Object.entries(parameterAggregationObj).forEach(([label, val]) => {
        parameterAggrLabelPer.push(label);
        parameterAggrDataPer.push(val.toFixed(1));
      });
    }
    const parameterScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Parameter-wise Adherence",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const parameterScoreData = {
      datasets: [
        {
          data: parameterAggrDataPer,
          backgroundColor: "#01FF84",
          label: "Parameter-wise Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(parameterAggrLabelPer),
    };

    const scoreAggregation = jsonOpenAuditData["Score Histogram"];
    let scoreAggregationLabelPer = [];
    let scoreAggregationDataPer = [];
    let scoreAggregationAggregationObj = {};
    if (!_.isEmpty(scoreAggregation)) {
      scoreAggregation[0].labels.forEach((element, index) => {
        scoreAggregationAggregationObj[element] =
          scoreAggregation[0].data[index];
      });
      Object.entries(scoreAggregationAggregationObj).forEach(([label, val]) => {
        scoreAggregationLabelPer.push(label);
        scoreAggregationDataPer.push(val.toFixed(1));
      });
    }

    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: scoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Score Data",
          stack: 1,
        },
      ],
      labels: scoreAggregationLabelPer.slice(0, 12),
    };

    const campaignScoreAggregation = findOpenAuditDataName(
      "Score By Campaign",
      jsonOpenAuditData
    );

    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationAggregationObj = {};
    if (!_.isEmpty(campaignScoreAggregation)) {
      campaignScoreAggregation["labels"].forEach((element, index) => {
        campaignScoreAggregationAggregationObj[element] =
          campaignScoreAggregation["data"][index];
      });
      Object.entries(campaignScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          campaignScoreAggregationLabelPer.push(label);
          campaignScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: campaignScoreAggregationLabelPer.slice(0, 12),
    };

    const skillScoreAggregation = findOpenAuditDataName(
      "Score By Skill",
      jsonOpenAuditData
    );

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    if (!_.isEmpty(skillScoreAggregation)) {
      skillScoreAggregation["labels"].forEach((element, index) => {
        skillScoreAggregationAggregationObj[element] =
          skillScoreAggregation["data"][index];
      });
      Object.entries(skillScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          skillScoreAggregationLabelPer.push(label);
          skillScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: skillScoreAggregationLabelPer.slice(0, 12),
    };

    const dispositionScoreAggregation = findOpenAuditDataName(
      "Score By Disposition",
      jsonOpenAuditData
    );

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    if (!_.isEmpty(dispositionScoreAggregation)) {
      dispositionScoreAggregation["labels"].forEach((element, index) => {
        dispositionScoreAggregationAggregationObj[element] =
          dispositionScoreAggregation["data"][index];
      });
      Object.entries(dispositionScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          dispositionScoreAggregationLabelPer.push(label);
          dispositionScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: dispositionScoreAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      scoreData: scoreData,
      scoreOptions: scoreOptions,
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
      languagePieChartData: languagePieChartData,
      dispositionPieChartOptions: pieDispositionOptions,
      dispositionPieChartData: dispositionPieChartData,
      pieChartsOptions: pieChartsOptions,
      parameterScoreOptions: parameterScoreOptions,
      parameterScoreData: parameterScoreData,
      timeScoreData: timeScoreData,
      timeScoreOptions: timeScoreOptions,
    };
  };

  const getIntentData = (intent) => {
    let intentArray = [];
    for (const element of intent) {
      intentArray.push({ label: element, value: element });
    }
    return intentArray;
  };

  const ArrowRenderer = ({ expanded }) => (
    <>{expanded ? <img src={DropUp}></img> : <img src={DropDown}></img>}</>
  );

  const CustomClearIcon = () => (
    <div>
      <img src={ClearIcon} className="clear-icon"></img>
    </div>
  );

  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const handleChange = (event, newValue) => {
    // console.log("Tabs value", newValue);
    setValue(newValue);
  };

  function transformArray(arr) {
    if (arr.length !== 0) {
      return arr.map((item) => {
        // Split the string by underscores
        const words = item.split("_");
        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        // Join the words with spaces and return the transformed string
        return capitalizedWords && capitalizedWords.join(" ");
      });
    } else {
      return [];
    }
  }

  const fetchOpenSearchAuditData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    callTypeAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(BisleriAuditURL, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          callTypeAuditVal: selectionList(callTypeAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 25,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    } else {
      const response = await Axios(BisleriAuditURL);
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonOpenAuditData({});
      }
      setIsload(false);
    }
  };

  function replaceLanguageNames(inputArray) {
    // Create an output array by mapping over the input array
    if (
      _.isArray(inputArray) &&
      !_.isEmpty(inputArray) &&
      !_.isNull(inputArray) &&
      !_.isUndefined(inputArray)
    ) {
      const outputArray = inputArray
        .map((inputItem) => {
          const matchingLang = langVariant.find(
            (langItem) => langItem.label === inputItem.value
          );
          if (matchingLang) {
            return { label: matchingLang.value, value: matchingLang.value };
          } else {
            return null; // Handle cases where no match is found
          }
        })
        .filter((item) => item !== null);
      return outputArray;
    } else {
      return [];
    }
  }
  
    
  const downloadReportData = async () => {
    try {
      const overallResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/dateWiseOverall`,
        { params: { startDateAudit, endDateAudit } }
      );

      const inboundResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/dateWiseInbound`,
        { params: { startDateAudit, endDateAudit } }
      );

      const manualResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/dateWiseManual`,
        { params: { startDateAudit, endDateAudit } }
      );

      const agentResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/agentWise`,
        { params: { startDateAudit, endDateAudit } }
      );

      const monthResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/monthWise`,
        { params: { startDateAudit, endDateAudit } }
      );

      const agentDailyResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bisleri/getReports/agentDateWise`,
        { params: { startDateAudit, endDateAudit } }
      );
  
      const overallData = formatReportData(overallResponse.data, "Overall");
      const inboundData = formatReportData(inboundResponse.data, "Inbound");
      const manualData = formatReportData(manualResponse.data, "Manual");

      const agentData = formatAgentReportData(agentResponse.data);
      const monthData = formatMonthReportData(monthResponse.data);
      const dailyAgentData = formatDailyReportData(agentDailyResponse.data);

  
      exportReportToExcel(overallData,inboundData,manualData,agentData,monthData,dailyAgentData);
    } catch (error) {
      console.error("Error in API calls:", error);
    }
  };
  
    
    const formatReportData = (data, sheetName) => {
      return data.data.map((item) => ({
        Date: item.date,
        [sheetName]: isFinite(item[sheetName.toLowerCase()]) ? item[sheetName.toLowerCase()] : "N/A", // Handle Infinity or NaN
        "Call Audit Count": isFinite(item.call_count) ? item.call_count : "N/A",
        "FATAL Count": isFinite(item.fatal_count) ? item.fatal_count : "N/A",
        "Less than 60%": isFinite(item.less_than_60_count) ? item.less_than_60_count : "N/A",
        "60% to 80%": isFinite(item.between_60_and_80_count) ? item.between_60_and_80_count : "N/A",
        "80% to 90%": isFinite(item.between_80_and_90_count) ? item.between_80_and_90_count : "N/A",
        "More than 90%": isFinite(item.greater_than_90_count) ? item.greater_than_90_count : "N/A",
      }));
    };

    const formatAgentReportData = (data) => {
      return data.data.map((item) => ({
        "Agent Name": item.agent,
        "Score": isFinite(item.average_score) ? item.average_score : "N/A", // Handle Infinity or NaN
        "Call Audit Count": isFinite(item.call_count) ? item.call_count : "N/A",
        "FATAL Count": isFinite(item.fatal_count) ? item.fatal_count : "N/A",
        "Less than 60%": isFinite(item.less_than_60_count) ? item.less_than_60_count : "N/A",
        "60% to 80%": isFinite(item.between_60_and_80_count) ? item.between_60_and_80_count : "N/A",
        "80% to 90%": isFinite(item.between_80_and_90_count) ? item.between_80_and_90_count : "N/A",
        "More than 90%": isFinite(item.greater_than_90_count) ? item.greater_than_90_count : "N/A",
      }));
    };
    
    const formatMonthReportData = (data) => {
      return data.data.map((item) => ({
        "Months": item.month,
        "Score": isFinite(item.average_score) ? item.average_score : "N/A", // Handle Infinity or NaN
        "Call Audit Count": isFinite(item.call_count) ? item.call_count : "N/A",
        "FATAL Count": isFinite(item.fatal_count) ? item.fatal_count : "N/A",
        "Less than 60%": isFinite(item.less_than_60_count) ? item.less_than_60_count : "N/A",
        "60% to 80%": isFinite(item.between_60_and_80_count) ? item.between_60_and_80_count : "N/A",
        "80% to 90%": isFinite(item.between_80_and_90_count) ? item.between_80_and_90_count : "N/A",
        "More than 90%": isFinite(item.greater_than_90_count) ? item.greater_than_90_count : "N/A",
      }));
    };
    
    const formatDailyReportData = (data) => {
      return data.data.map((agent) => {
        const row = {
          "Agent Name": agent.agent, // Add agent name
        };
    
        // Sort the daily breakdown by date to ensure order
        const sortedDailyBreakdown = agent.daily_breakdown.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
    
        let totalAvgScore = 0; // To calculate final average score
        let totalAvgScoreFatal = 0; // To calculate final average score with fatal
        let validDaysCount = 0; // To count valid days for averaging
    
        // Add scores for each date
        sortedDailyBreakdown.forEach((daily) => {
          const avgScore =
            daily.avg_score && daily.avg_score !== "Infinity"
              ? daily.avg_score
              : "N/A";
          const avgScoreFatal =
            daily.avg_score_fatal && daily.avg_score_fatal !== "Infinity"
              ? daily.avg_score_fatal
              : "N/A";
    
          // Assign the scores directly to the date field
          row[daily.date] = avgScore;
    
          // Accumulate valid scores for final averages
          if (avgScore !== "N/A" && avgScore !== "Infinity") {
            totalAvgScore += daily.avg_score;
            validDaysCount++;
          }
          if (avgScoreFatal !== "N/A" && avgScoreFatal !== "Infinity") {
            totalAvgScoreFatal += daily.avg_score_fatal;
          }
        });
    
        // Add final scores
        row["Final Score"] = validDaysCount > 0 ? totalAvgScore / validDaysCount : "N/A";
        row["Final Score with Fatal"] =
          validDaysCount > 0 ? totalAvgScoreFatal / validDaysCount : "N/A";
    
        return row;
      });
    };
    
    
    const exportReportToExcel = (overallData, inboundData, manualData, agentData, monthData, dailyAgentData) => {
      // Create a workbook
      const workbook = XLSX.utils.book_new();
    
      // Append sheets for Overall, Inbound, and Manual data
      const overallSheet = XLSX.utils.json_to_sheet(overallData);
      const inboundSheet = XLSX.utils.json_to_sheet(inboundData);
      const manualSheet = XLSX.utils.json_to_sheet(manualData);

      const agentSheet = XLSX.utils.json_to_sheet(agentData);
      const monthSheet = XLSX.utils.json_to_sheet(monthData);
      const dailyAgentSheet = XLSX.utils.json_to_sheet(dailyAgentData);

    
      XLSX.utils.book_append_sheet(workbook, overallSheet, "Overall");
      XLSX.utils.book_append_sheet(workbook, inboundSheet, "Inbound");
      XLSX.utils.book_append_sheet(workbook, manualSheet, "Manual");

      XLSX.utils.book_append_sheet(workbook, agentSheet, "Agent Wise");
      XLSX.utils.book_append_sheet(workbook, monthSheet, "Month Wise");
      XLSX.utils.book_append_sheet(workbook, dailyAgentSheet, "Agent Date Wise");
    
      // Save the workbook
      const fileName = `${localStorage.getItem("user") || "User"}_Reports.xlsx`;
      XLSX.writeFile(workbook, fileName);
      
    };
    
    
      const downloadCallData = async () => {
        let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
        const response = await axios.get(BisleriCallsTabDownloadUrl, {
          params: {
            startDateAudit: startDateAudit,
            endDateAudit: endDateAudit,
            campaignAuditVal: selectionList(campaignAuditVal),
            callTypeAuditVal: selectionList(callTypeAuditVal),
            agentAuditVal: selectionList(agentAuditVal),
            dispositionAuditVal: selectionList(dispositionAuditVal),
            languageAuditVal: selectionList(langVal),
            skillAuditVal: selectionList(skillAuditVal),
            callerIDAuditVal: !_.isEmpty(callerIDAuditVal) ? String(callerIDAuditVal) : "0",
            ucidAuditVal: !_.isEmpty(ucidAuditVal) ? String(ucidAuditVal) : "0",
            scoreInputVal: scoreInputVal,
            scoreAuditVal: scoreAuditVal,
            size: jsonOpenAuditData.dataCount,
            from: 0,
          },
          timeout: 180000,
        });
       return response;
          
    };
    
    const getLanguageCode = (languageName) => {
    const languageMapping = {
      "English": "en-IN",
      "Tamil": "ta-IN",
      "Malayalam": "ml-IN",
      "Marathi": "mr-IN",
      "Hindi": "hi-IN",
      "Telugu": "te-IN",
      "Kannada": "kn-IN",
      "Gujarati": "gu-IN",
      // Add more language codes and names as needed
    };
    return languageMapping[languageName] || "0";
    };
      
    const fetchOpenSearchCallsDataFromCallsTab = async ( size,
      from) =>{
      fetchOpenSearchCallsData(
         startDateAudit,
         endDateAudit,
         campaignAuditVal,
         callTypeAuditVal,
         agentAuditVal,
         dispositionAuditVal,
         languageAuditVal,
         ucidAuditVal,
         callerIDAuditVal,
         skillAuditVal,
         scoreInputVal,
         scoreAuditVal,
        size,
        from)
    
    };
  

  
  const fetchOpenSearchCallsData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    callTypeAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    ucidAuditVal,
    callerIDAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    //setJsonOpenCallsData([]);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(BisleriAuditCallsURL, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          callTypeAuditVal: selectionList(callTypeAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          callerIDAuditVal: !_.isEmpty(callerIDAuditVal) ? String(callerIDAuditVal) : "0",
          ucidAuditVal: !_.isEmpty(ucidAuditVal) ? String(ucidAuditVal) : "0",
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCallsData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(BisleriAuditCallsURL);
      setJsonOpenCallsData(response.data);
      setIsload(false);
    }
  };

  if (window.localStorage.getItem("user") === "Bisleri") {
    useEffect(() => {}, [jsonOpenCallsData]);
  }

 

  const fetchAuditFiltersData = async () => {
    const filtersAudit = await Axios(BisleriAuditFiltersURL);
    // console.log(filtersAudit.data.aggregation);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersAudit.data.aggregation)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("AuditCampaignCount", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignAuditOptions(campaignArr);

    const languageFilters = findFilters("AuditLanguageCount", objFilter);
    sessionStorage.setItem(
      "languageFilters",
      JSON.stringify(languageFilters["labels"])
    );
    if (typeof sessionStorage.languageFilters !== "undefined") {
      languageFinalFilter = JSON.parse(sessionStorage.languageFilters);
    }
    const languageNames = getLanguageNames(languageFinalFilter);
    const languageArr = getIntentData(languageNames);
    setLanguageAuditOptions(languageArr);

    const agentFilters = findFilters("AuditCallsPerAgent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentAuditOptions(agentArr);

    const dispositionFilters = findFilters("AuditDispositionCount", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionAuditOptions(dispositionArr);

    const skillFilters = findFilters("AuditSkillCount", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillOptions(skillArr);

    const callTypeFilters = findFilters("AuditCallTypes", objFilter);
    sessionStorage.setItem(
      "callTypeFilters",
      JSON.stringify(callTypeFilters["labels"])
    );
    if (typeof sessionStorage.callTypeFilters !== "undefined") {
      callTypeFinalFilter = JSON.parse(sessionStorage.callTypeFilters);
    }
    const callTypeArr = getIntentData(callTypeFinalFilter);
    setCallTypeOptions(callTypeArr);
    return {
      agentAuditOptions: agentAuditOptions,
      campaignAuditOptions: campaignAuditOptions,
      dispositionAuditOptions: dispositionAuditOptions,
      languageOptions: languageAuditOptions,
      callTypeOptions : callTypeOptions,
      skillOptions: skillOptions,
    };
  };

  const [error, setError] = useState('');
  
  const handleFromDateChange = (date) => {
    setStartDateAudit(date);
    // Check if end date is before start date and set error accordingly
    if (endDateAudit && date > endDateAudit) {
      setError("The start date cannot be after the end date.");
    } else {
      setError('');
    }
  };

  const handleToDateChange = (date) => {
    setEndDateAudit(date);
    // Check if start date is after end date and set error accordingly
    if (startDateAudit && date < startDateAudit) {
      setError("The end date cannot be before the start date.");
    } else {
      setError('');
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Conversational Intelligence</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
          className={load ? "hidden-cus" : ""}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {window.localStorage.getItem("user") === "bisleri" ? (
                  <Tab label="Audit" value="1" />
                ) : null}
                {window.localStorage.getItem("user") === "bisleri" ? (
                  <Tab label="Calls" value="2" />
                ) : null}
              </TabList>
            </Box>

            
            {window.localStorage.getItem("user") === "bisleri" ? (
              <TabPanel
                value="1"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <Grid container item spacing={2}>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={endDateAudit ? moment(endDateAudit).toDate() : moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={startDateAudit}
                      onChange={(newValue) => {
                        handleFromDateChange(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div></div>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      label="End Date"
                      value={endDateAudit}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      minDate={startDateAudit}
                      onChange={(newValue) => {
                        handleToDateChange(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={campaignAuditOptions}
                      value={campaignAuditVal}
                      onChange={handleChangeCampaignAudit}
                      labelledBy="Camapaign"
                      overrideStrings={{ selectSomeItems: "Campaigns" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={agentAuditOptions}
                      value={agentAuditVal}
                      onChange={handleChangeAgentAudit}
                      labelledBy="Agents"
                      overrideStrings={{ selectSomeItems: "Agents" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                 
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={dispositionAuditOptions}
                      value={dispositionAuditVal}
                      onChange={handleChangeDispositionAudit}
                      labelledBy="Disposition"
                      overrideStrings={{ selectSomeItems: "Dispositions" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                  
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={skillOptions}
                      value={skillAuditVal}
                      onChange={handleChangeSkillAudit}
                      labelledBy="Skill"
                      overrideStrings={{ selectSomeItems: "Skills" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-language">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-language"
                      id="demo-multiple-checkbox"
                      multiple
                      value={languageAuditVal}
                      onChange={handleChangeLanguageAudit}
                      input={<OutlinedInput label="LanguageAudit" />}
                      renderValue={(selected) =>
                        selected.map((x) => x.value).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {languageAuditOptions.map((m) => (
                        <MenuItem key={m.label} value={m}>
                          <Checkbox
                            checked={
                              languageAuditVal.findIndex(
                                (item) => item.label === m.label
                              ) >= 0
                            }
                          />
                          <ListItemText primary={m.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={callTypeOptions}
                      value={callTypeAuditVal}
                      onChange={handleChangeCallTypeAudit}
                      labelledBy="Call-Type"
                      overrideStrings={{ selectSomeItems: "Call-Types" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl> 
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-score">Score</InputLabel>
                    <Select
                      labelId="demo-score"
                      id="demo-checkbox"
                      value={scoreAuditVal}
                      // multiple
                      onChange={handleChangeScoreAudit}
                      input={<OutlinedInput label="Score" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {scores.map((m) => (
                        <MenuItem key={m} value={m}>
                          <Checkbox checked={scoreAuditVal.indexOf(m) > -1} />
                          <ListItemText primary={m} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  {scoreSelected ? (
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter Score"
                          value={scoreInputVal}
                          onChange={handleChangeScoreInput}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Grid></Grid>
                  )}
                  <div className="date-container">
                    &nbsp;
                    <br />
                  </div>
                  <FormControl>
                    <Button
                      variant="contained"
                      style={{ padding: "13px 8px", fontSize: "0.8rem", marginLeft: "10px"   }}
                      onClick={() => {
                        fetchOpenSearchAuditData(
                          startDateAudit,
                          endDateAudit,
                          campaignAuditVal,
                          callTypeAuditVal,
                          agentAuditVal,
                          dispositionAuditVal,
                          languageAuditVal,
                          skillAuditVal,
                          scoreInputVal,
                          scoreAuditVal
                        );
                      }}
                    >
                      Search
                    </Button>
                  </FormControl>
                  {load ? (
                    <div className="loader_digital" id="loader_digital"></div>
                  ) : null}
                </Grid>
                <br></br>
                <div className="col-md-12 row">
                  <div className="col-md-4">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: -2,
                        "& > :not(style)": {
                          m: 0.5,
                          width: 115,
                          height: 60,
                        },
                      }}
                    >
                      <Paper className="aupage">
                        <b>Total Calls</b>
                        <br></br>
                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Total Calls"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Calls above 90%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Threshold Above 90"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Calls below 60%</b>
                        <br></br>
                        <div className="dapage">
                          {jsonOpenAuditData["Threshold Below 60"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Total Agents</b>
                        <br></br>

                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Total Agents"]}
                        </div>
                      </Paper>
                      <Paper className="aupage">
                        <b>Avg Score</b>
                        <br></br>

                        <div className="dapage">
                          {" "}
                          {jsonOpenAuditData["Average Score"]}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <div className="col-md-8">
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 0.5,
                          width: 245,
                          height: 120,
                        },
                      }}
                    >
                      <Paper
                        className="aupage"
                        style={{
                          marginLeft: "-28px",
                          marginTop: "5px",
                        }}
                      >
                        <b>Best Parameters</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Best Parameters"] &&
                            transformArray(
                              jsonOpenAuditData["Best Parameters"]
                            ).map((el, i) => (
                              <div key={i}>{el.replace(/_/g, " ")}</div>
                            ))}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Best Agents</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Best Agents"] &&
                            jsonOpenAuditData["Best Agents"].map((el, i) => (
                              <div key={i}>{el}</div>
                            ))}
                        </ul>
                      </Paper>
                      <Paper className="aupage">
                        <b>Need Improvement</b>
                        <br></br>
                        <br></br>
                        <ul
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          {jsonOpenAuditData["Needs Improvement"] &&
                            jsonOpenAuditData["Needs Improvement"].map(
                              (el, i) => <div key={i}>{el}</div>
                            )}
                        </ul>
                      </Paper>

                     <Paper
                        className="aupage"
                       
                        onClick={downloadReportData}
                      >
                        <b>Download Agent Report</b>
                        <br></br>
                        <br></br>
                        <DownloadIcon  size="large" color="primary"/>

                      </Paper>  
                      
                    </Box>
                  </div>
                </div>
                <br></br>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audGraphVal}
                            onChange={handleChangeAudGraph}
                          >
                            <FormControlLabel
                              value="campaign"
                              control={<Radio size="small" />}
                              editable="true"
                              label="Campaign"
                              checked={checkCampaign}
                            />
                            <FormControlLabel
                              value="disposition"
                              control={<Radio size="small" />}
                              label="Disposition"
                            />
                            <FormControlLabel
                              value="skill"
                              control={<Radio size="small" />}
                              label="Skill"
                            />
                            <FormControlLabel
                              value="parameter"
                              control={<Radio size="small" />}
                              label="Parameter-wise"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* </Paper> */}

                        <div style={{ paddingTop: "20px" }}>
                          {Object.keys(dashboardAuditData).length !== 0 && (
                            <Grid container item spacing={3}>
                              {audGraphVal === "disposition" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "dispositionScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData[
                                          "dispositionScoreData"
                                        ]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : audGraphVal === "skill" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData["skillScoreOptions"]
                                      }
                                      data={
                                        dashboardAuditData["skillScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : audGraphVal === "parameter" ? (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "parameterScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData["parameterScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              ) : (
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={4}
                                    sx={{
                                      width: "580px",
                                      height: "400px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Bar
                                      options={
                                        dashboardAuditData[
                                          "campaignScoreOptions"
                                        ]
                                      }
                                      data={
                                        dashboardAuditData["campaignScoreData"]
                                      }
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                  <div>
                    <Box
                      sx={{
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 600,
                          height: 500,
                        },
                      }}
                    >
                      <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={audPieVal}
                            onChange={handleChangeAudPie}
                          >
                            <FormControlLabel
                              value="language"
                              control={<Radio size="small" />}
                              editable="true"
                              label="Language"
                              checked={checkLanguage}
                            />
                            <FormControlLabel
                              value="disposition"
                              control={<Radio size="small" />}
                              label="Disposition"
                            />
                          </RadioGroup>
                        </FormControl>

                        <div style={{ paddingTop: "25px" }}>
                          {Object.keys(dashboardAuditData).length !== 0 ? (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {audPieVal === "language" ? (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "520px",
                                        height: "400px",
                                        marginLeft: "40px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "30px",
                                          width: "410px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardAuditData[
                                              "pieChartsOptions"
                                            ]
                                          }
                                          data={
                                            dashboardAuditData[
                                              "languagePieChartData"
                                            ]
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "520px",
                                        height: "400px",
                                        marginLeft: "40px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "30px",
                                          width: "410px",
                                        }}
                                      >
                                        <Pie
                                          options={
                                            dashboardAuditData[
                                              "dispositionPieChartOptions"
                                            ]
                                          }
                                          data={
                                            dashboardAuditData[
                                              "dispositionPieChartData"
                                            ]
                                          }
                                          plugins={[ChartDataLabels]}
                                        />
                                      </div>
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container item spacing={3}>
                              <label
                                style={{
                                  marginLeft: "30px",
                                  width: "410px",
                                }}
                              >
                                No Data For Analysis
                              </label>
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Box>
                  </div>
                  <br></br>
                </div>
                <Box
                  sx={{
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: 1275,
                      height: 555,
                    },
                  }}
                >
                  <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                    <div style={{ paddingTop: "20px" }}>
                      {Object.keys(dashboardAuditData).length !== 0 ? (
                        <Grid container item spacing={3}>
                          <Grid item xs={6}>
                            {" "}
                            <Paper
                              elevation={3}
                              sx={{
                                width: "1240px",
                                height: "495px",
                                marginLeft: "20px",
                              }}
                            >
                              <Line
                                options={dashboardAuditData["timeScoreOptions"]}
                                data={dashboardAuditData["timeScoreData"]}
                                plugins={[ChartDataLabels]}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={3}>
                          <label>No Data For Time-Score Analysis</label>
                        </Grid>
                      )}
                    </div>
                  </Paper>
                </Box>
                <br></br>
                <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                  <BisleriAuditTable
                    fetchAudit={[
                      jsonOpenAuditData,
                      fetchOpenSearchAuditData,

                     
                   {
                        startDateAudit: startDateAudit,
                        endDateAudit: endDateAudit,
                        campaignAuditVal: selectionList(campaignAuditVal),
                        callTypeAuditVal: selectionList(callTypeAuditVal),
                        agentAuditVal: selectionList(agentAuditVal),
                        dispositionAuditVal: selectionList(dispositionAuditVal),
                        languageAuditVal: selectionList(languageAuditVal),
                        skillAuditVal: selectionList(skillAuditVal),
                        callerIDAuditVal: !_.isEmpty(callerIDAuditVal) ? String(callerIDAuditVal) : "0",
                        ucidAuditVal: !_.isEmpty(ucidAuditVal) ? String(ucidAuditVal) : "0",    
                        scoreInputVal: scoreInputVal,
                        scoreAuditVal: scoreAuditVal,
                                              
                      },
                      languageAuditVal,
                    ]}
                  ></BisleriAuditTable>
                </Grid>
              </TabPanel>
            ) : null}

            {
            window.localStorage.getItem("user") === "bisleri" ? (
              <TabPanel
                value="2"
                style={{ pointerEvents: load ? "none" : "auto" }}
              >
                <Grid container item spacing={2}>
                <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      hideTabs
                      showTodayButton
                      //check date
                      maxDate={endDateAudit ? moment(endDateAudit).toDate() : moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={startDateAudit}
                      onChange={(newValue) => {
                        handleFromDateChange(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div></div>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      label="End Date"
                      value={endDateAudit}
                      disableFuture
                      hideTabs
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      minDate={startDateAudit}
                      onChange={(newValue) => {
                        handleToDateChange(
                          String(moment(newValue).format("YYYY-MM-DD"))
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={campaignAuditOptions}
                      value={campaignAuditVal}
                      onChange={handleChangeCampaignAudit}
                      labelledBy="Camapaign"
                      overrideStrings={{ selectSomeItems: "Campaigns" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={agentAuditOptions}
                      value={agentAuditVal}
                      onChange={handleChangeAgentAudit}
                      labelledBy="Agents"
                      overrideStrings={{ selectSomeItems: "Agents" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                 
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={dispositionAuditOptions}
                      value={dispositionAuditVal}
                      onChange={handleChangeDispositionAudit}
                      labelledBy="Disposition"
                      overrideStrings={{ selectSomeItems: "Dispositions" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                  
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={skillOptions}
                      value={skillAuditVal}
                      onChange={handleChangeSkillAudit}
                      labelledBy="Skill"
                      overrideStrings={{ selectSomeItems: "Skills" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl>                
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-language">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-language"
                      id="demo-multiple-checkbox"
                      multiple
                      value={languageAuditVal}
                      onChange={handleChangeLanguageAudit}
                      input={<OutlinedInput label="LanguageAudit" />}
                      renderValue={(selected) =>
                        selected.map((x) => x.value).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {languageAuditOptions.map((m) => (
                        <MenuItem key={m.label} value={m}>
                          <Checkbox
                            checked={
                              languageAuditVal.findIndex(
                                (item) => item.label === m.label
                              ) >= 0
                            }
                          />
                          <ListItemText primary={m.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl variant="outlined" style={{ width: "125px" }}>
                    <MultiSelect
                      options={callTypeOptions}
                      value={callTypeAuditVal}
                      onChange={handleChangeCallTypeAudit}
                      labelledBy="Call-Type"
                      overrideStrings={{ selectSomeItems: "Call-Types" }}
                      hasSelectAll={true}
                      searchable={true} // Enable search functionality
                      ArrowRenderer={ArrowRenderer}
                      ClearIcon={<CustomClearIcon />}
                      ClearSelectedIcon={<CustomClearIcon />}
                      // className="CI-dashboaed-filters"
                    />
                  </FormControl> 
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-score">Score</InputLabel>
                    <Select
                      labelId="demo-score"
                      id="demo-checkbox"
                      value={scoreAuditVal}
                      // multiple
                      onChange={handleChangeScoreAudit}
                      input={<OutlinedInput label="Score" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {scores.map((m) => (
                        <MenuItem key={m} value={m}>
                          <Checkbox checked={scoreAuditVal.indexOf(m) > -1} />
                          <ListItemText primary={m} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  {scoreSelected ? (
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter Score"
                          value={scoreInputVal}
                          onChange={handleChangeScoreInput}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Grid></Grid>
                  )}
                  <div className="date-container">
                    &nbsp;
                    <br />
                  </div>

                  <FormControl style={{ width: "125px" }}>
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter CallerID"
                          value={callerIDAuditVal}
                          onChange={handlechangeCallerIDVal}
                          inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                        />
                      </Box>
                    </div>
                    </FormControl>
                    <FormControl style={{ width: "125px" }}>
                    <div>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: 98 },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Input
                          style={{ fontSize: "0.8rem" }}
                          placeholder="Enter UCID"
                          value={ucidAuditVal}
                          onChange={handlechangeUcidVal}
                          inputProps={{ pattern: "^[0-9]*$" }} // Only allow numbers
                        />
                      </Box>
                    </div>

                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl>
                    <Button
                      variant="contained"
                      style={{ padding: "13px 8px", fontSize: "0.8rem", marginLeft: "10px"  }}
                      onClick={() => {
                        fetchOpenSearchCallsData(
                          startDateAudit,
                          endDateAudit,
                          campaignAuditVal,
                          callTypeAuditVal,
                          agentAuditVal,
                          dispositionAuditVal,
                          languageAuditVal,
                          ucidAuditVal,
                          callerIDAuditVal,
                          skillAuditVal,
                          scoreInputVal,
                          scoreAuditVal,
                        );
                      }}
                    >
                      Search
                    </Button>
                  </FormControl>

                  {load ? (
                    <div className="loader_digital" id="loader_digital"></div>
                  ) : null}
                </Grid>
                <div className="date-container">&nbsp;</div>
                <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                  <BisleriCallsTable
                   fetchCalls={[
                    jsonOpenCallsData,
                    fetchOpenSearchCallsDataFromCallsTab,
                    downloadCallData
                  ]}
                  ></BisleriCallsTable>
                </Grid>
              </TabPanel>
            ) : null}
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
};
export default BisleriDashBoard;
