import {toast } from 'react-toastify';
const toast_options ={
    autoClose:5000
}
class toastService {

    notifyError = (msg:any)=> { 
      toast.error(msg,toast_options);
      };
    notifySuccess = (msg:any)=> { 
        toast.success(msg,toast_options);
      };
    notifyWarn = (msg:any)=> { 
        toast.warn(msg,toast_options);
      };
}

export default new toastService();
