import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import Axios from "axios";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SampleTable from "./SampleTable.tsx";
import VocSearchDataTable from "./VoiceBot/VocSearchDataTable";
import AuditTable from "./AuditTable.tsx";
import AuditOutTable from "./AuditOutTable.tsx";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";

import { CircularProgressbar } from "react-circular-progressbar";
import VoiceBot from "./VoiceBot/VoiceBot";
import * as _ from "lodash";
import { Pie } from "react-chartjs-2";
import OpensearchAuditTable from "./OpensearchAuditTable";
import VocTranscriptTable from "./VocTranscriptTable";
import OpensearchCallsTable from "./OpenSearchCallsTable";
import CDRTable from "./CDRTable";
import { fontWeight } from "@mui/system";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let variants = [
  {
    id: 1,
    label: "Positive",
    value: "positive",
  },
  {
    id: 2,
    label: "Negative",
    value: "negative",
  },
  {
    id: 3,
    label: "Neutral",
    value: "neutral",
  },
];
const languageCodeMap = {
  "en-IN": "English",
  "ta-IN": "Tamil",
  "ml-IN": "Malayalam",
  "mr-IN": "Marathi",
  "hi-IN": "Hindi",
  "te-IN": "Telugu",
  "kn-IN": "Kannada",
  "gu-IN": "Gujarati",
  // Add more language codes and names as needed
};
const langVariant = [
  { label: "English", value: "en-IN" },
  { label: "Tamil", value: "ta-IN" },
  { label: "Malayalam", value: "ml-IN" },
  { label: "Marathi", value: "mr-IN" },
  { label: "Hindi", value: "hi-IN" },
  { label: "Telugu", value: "te-IN" },
  { label: "Kannada", value: "kn-IN" },
  { label: "Gujarati", value: "gu-IN" },
];
const scores = ["None", ">", "<"];
let channels = [
  {
    id: 1,
    label: "Agent",
    value: "agent",
  },
  {
    id: 2,
    label: "Customer",
    value: "customer",
  },
];
let channelchat = [
  {
    id: 1,
    label: "Email",
    value: "email",
  },
  {
    id: 2,
    label: "Whatsapp",
    value: "whatsapp",
  },
  {
    id: 3,
    label: "Chat",
    value: "chat",
  },
];
let gender = [
  {
    id: 0,
    label: "Male",
    value: "male",
  },
  {
    id: 1,
    label: "Female",
    value: "female",
  },
  {
    id: 2,
    label: "Unknown",
    value: "unknown",
  },
];

const didFilter = process.env.REACT_APP_BASE_URL + "/didFilter";
const voiceBotDataURL =
  process.env.REACT_APP_BASE_URL + "/getElasticVoicebotData";
const voiceBotUnidentifiedDataURL =
  process.env.REACT_APP_BASE_URL + "/getVoiceUnidentifiedUtterances";
const elasticDataUrl = process.env.REACT_APP_BASE_URL + "/getAnalyticData";
const elasticChatUrl = process.env.REACT_APP_BASE_URL + "/getElasticChatData";
const elasticDataTranscriptUrl =
  process.env.REACT_APP_BASE_URL + "/getTranscriptData";
const elasticDataFiltersUrl =
  process.env.REACT_APP_BASE_URL + "/getElasticFiltersData";
const elasticVOCSearchFilterUrl =
  process.env.REACT_APP_BASE_URL + "/VOCSearchBoxData";
const VOCSummaryUrl = process.env.REACT_APP_BASE_URL + "/searchVocSummary";
const elasticVOCTranscriptUrl =
  process.env.REACT_APP_BASE_URL + "/vocTranscriptReport";
const elasticAuditFiltersUrl =
  process.env.REACT_APP_BASE_URL + "/getElasticAuditFiltersData";
const openAuditFiltersUrl =
  process.env.REACT_APP_BASE_URL + "/shobha/openSearchAuditFilters";
const elasticDataAuditUrl = process.env.REACT_APP_BASE_URL + "/getAuditData";
const openCDRFiltersUrl =
  process.env.REACT_APP_BASE_URL + "/mindTree/getFilters";
const openDataCDRUrl = process.env.REACT_APP_BASE_URL + "/mindTree/getCDRData";

const openBillingDataUrl = `${process.env.REACT_APP_BASE_URL}/vocBillingReport`;

const elasticDataAuditOutUrl =
  process.env.REACT_APP_BASE_URL + "/getAuditOutboundData";
const openSearchAuditDataUrl =
  process.env.REACT_APP_BASE_URL + "/shobha/getOpenAuditData";
const openSearchCallsDataUrl =
  process.env.REACT_APP_BASE_URL + "/shobha/getCallsData";
const axios = require("axios");
axios.defaults.headers.common["user"] = localStorage.getItem("user");
var objFilter = {};
var vocSearchObj = {};
var vocSearchDescriptions = [];
var vocSearchField = [];
var campaignFinalFilter = [];
var languageFinalFilter = [];
var statusFinalFilter = [];
var skillFinalFilter = [];
var phoneFinalFilter = [];
var locationFinalFilter = [];
var agentFinalFilter = {};
var dispositionFinalFilter = {};
var intentFinalFilter = {};
var searchFinalFilter = {};
let intentDrill = [];
let intentChatDrill = [];
let sentimentChatDrill = [];
let sentimentDrill = [];
let genderDrill = [];
export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const SpeechDashboard = () => {
  // alert("check");
  const [vocBarGraphData, setvocBarGraphData] = useState([]);
  const [vocBarGraphData2, setvocBarGraphData2] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [wordClusterData, setWordClusterData] = useState(null);
  const [vocSearchFieldFilters, setVOCsearchFieldFilters] = useState([]);
  const [vocCampaignFieldFilters, setVOCCampaignFieldFilters] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardChatData, setDashboardChatData] = useState({});
  const [dashboardAuditData, setDashboardAuditData] = useState({});
  const [dashboardAuditOutData, setDashboardAuditOutData] = useState({});
  const [jsonData, setJsonData] = useState({});
  const [jsonDataTranscript, setJsonDataTranscript] = useState({});
  const [jsonVocDataTranscript, setJsonVocDataTranscript] = useState({});
  const [jsonVocDataBilling, setJsonVocDataBilling] = useState({});
  const [jsonVocSummaryData, setJsonVocSummaryData] = useState({});
  const [jsonChatData, setJsonChatData] = useState({});
  const [jsonAuditData, setJsonAuditData] = useState({});
  const [jsonOpenAuditData, setJsonOpenAuditData] = useState({});
  const [jsonOpenCallsData, setJsonOpenCallsData] = useState({});
  const [jsonOpenCdrData, setJsonOpenCdrData] = useState({});
  const [jsonAuditOutData, setJsonAuditOutData] = useState({});
  const [load, setIsload] = useState(true);
  const startDateVal = new Date(new Date().setDate(new Date().getDate() - 1));
  const emdDateVal = new Date(new Date().setDate(new Date().getDate() - 1));
  const [startDate, setStartDate] = useState(startDateVal.setHours(0, 0, 0));
  const [endDate, setEndDate] = useState(emdDateVal.setHours(23, 59, 59));
  const startDateTranscriptVal = new Date(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [startDateTranscript, setStartDateTranscript] = useState(
    startDateTranscriptVal.setHours(0, 0, 0)
  );
  const [endDateTranscript, setEndDateTranscript] = useState(
    emdDateVal.setHours(23, 59, 59)
  );
  const startDateChatVal = new Date(
    new Date().setDate(new Date().getDate() - 1)
  );
  const emdDateChatVal = new Date(new Date().setDate(new Date().getDate() - 1));
  const [startDateChat, setStartDateChat] = useState(
    startDateChatVal.setHours(0, 0, 0)
  );
  const [endDateChat, setEndDateChat] = useState(
    emdDateChatVal.setHours(23, 59, 59)
  );
  const startDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const emdDateAuditVal = String(moment().format("YYYY-MM-DD"));
  const [startDateCDR, setStartDateCDR] = useState(startDateAuditVal);
  const [endDateCDR, setEndDateCDR] = useState(emdDateAuditVal);
  const [startDateAudit, setStartDateAudit] = useState(startDateAuditVal);
  const [endDateAudit, setEndDateAudit] = useState(emdDateAuditVal);
  const startDateAuditOutVal = new Date(
    new Date().setDate(new Date().getDate() - 1)
  );
  const emdDateAuditOutVal = new Date(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [startDateAuditOut, setStartDateAuditOut] = useState(
    startDateAuditOutVal.setHours(0, 0, 0)
  );
  const [endDateAuditOut, setEndDateAuditOut] = useState(
    emdDateAuditOutVal.setHours(23, 59, 59)
  );
  const [intentVal, setIntentVal] = React.useState([]);
  const [intentChatVal, setIntentChatVal] = React.useState([]);
  const [intentTranscriptVal, setIntentTranscriptVal] = React.useState([]);
  const [agentVal, setAgentVal] = React.useState([]);
  const [didSelectedValues, setSelectedDidValue] = React.useState([]);
  const [voiceBotDates, setVoiceBotDates] = React.useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(),
  });
  const [channelVal, setChannelVal] = React.useState([]);
  const [agentTranscriptionVal, setAgentTranscriptionVal] = React.useState([]);
  const [campaignVal, setCampaignVal] = React.useState([]);
  const [campaignValInCalls, setCallCampaignVal] = React.useState([]);
  const [dispositionVal, setDispositionVal] = React.useState([]);
  const [campaignAuditVal, setCampaignAuditVal] = React.useState([]);
  const [dispositionAuditVal, setDispositionAuditVal] = React.useState([]);
  const [languageAuditVal, setLanguageAduitVal] = React.useState([]);
  const [skillAuditVal, setSkillAuditVal] = React.useState([]);
  const [scoreAuditVal, setScoreAuditVal] = React.useState([]);
  const [agentCDRVal, setAgentCDRVal] = React.useState([]);
  const [campaignCDRVal, setCampaignCDRVal] = React.useState([]);
  const [dispositionCDRVal, setDispositionCDRVal] = React.useState([]);
  const [skillCDRVal, setSkillCDRVal] = React.useState([]);
  const [locationCDRVal, setLocationCDRVal] = React.useState([]);
  const [phoneNameCDRVal, setPhoneNameCDRVal] = React.useState([]);
  const [statusCDRVal, setStatusCDRVal] = React.useState([]);
  const [callerCDRVal, setCallerCDRVal] = React.useState([]);
  const [campaignAuditOutVal, setCampaignAuditOutVal] = React.useState([]);
  const [dispositionAuditOutVal, setDispositionAuditOutVal] = React.useState(
    []
  );
  const [skillAuditOutVal, setSkillAuditOutVal] = React.useState([]);
  const [scoreAuditOutVal, setScoreAuditOutVal] = React.useState([]);
  const [scoreInputVal, setScoreInputVal] = React.useState([]);
  const [scoreOutInputVal, setScoreOutInputVal] = React.useState([]);
  const [dispositionTranscriptVal, setDispositionTranscriptVal] =
    React.useState([]);
  const [intentOptions, setIntentOptions] = React.useState([]);
  const [agentOptions, setAgentOptions] = React.useState([]);
  const [campaignOptions, setCampaignOptions] = React.useState([]);
  const [agentAuditOptions, setAgentAuditOptions] = React.useState([]);
  const [agentCDROptions, setAgentCDROptions] = React.useState([]);
  const [campaignCDROptions, setCampaignCDROptions] = React.useState([]);
  const [dispositionCDROptions, setDispositionCDROptions] = React.useState([]);
  const [skillCDROptions, setSkillCDROptions] = React.useState([]);
  const [locationCDROptions, setLocationCDROptions] = React.useState([]);
  const [phoneNameCDROptions, setPhoneNameCDROptions] = React.useState([]);
  const [statusCDROptions, setStatusCDROptions] = React.useState([]);
  // const [callerCDROptions, setCallerCDROptions] = React.useState([]);
  const [campaignAuditOptions, setCampaignAuditOptions] = React.useState([]);
  const [languageAuditOptions, setLanguageAuditOptions] = React.useState([]);
  const [skillOptions, setSkillOptions] = React.useState([]);
  const [dispositionOptions, setDispositionOptions] = React.useState([]);
  const [dispositionAuditOptions, setDispositionAuditOptions] = React.useState(
    []
  );
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [customerTranscriptOptions, setCustomerTranscriptOptions] =
    React.useState([]);
  const [agentDataOptions, setAgentDataOptions] = React.useState([]);
  const [value, setValue] = useState("1");
  const [sentimentVal, setSentimentVal] = React.useState([]);
  const [sentimentTranscriptVal, setSentimentTranscriptVal] = React.useState(
    []
  );
  const [sentimentChatVal, setSentimentChatVal] = React.useState([]);
  const [channelChatVal, setChannelChatVal] = React.useState([]);
  const [genderVal, setGenderVal] = React.useState([]);
  const [text, setText] = useState([]);
  const [searchInputVal, setSearchInputVal] = React.useState([]);
  const [vocSearchInputVal, setVocSearchInputVal] = React.useState([]);
  const [matchInputVal, setMatchInputVal] = React.useState([]);
  const [vocMatchInputVal, setVocMatchInputVal] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [audGraphVal, setAudGraphVal] = React.useState("campaign");
  const [audPieVal, setAudPieVal] = React.useState("language");
  const [audGraphOutVal, setAudGraphOutVal] = React.useState("campaign");
  const [checkCampaign, setCheckCampaign] = React.useState(true);
  const [checkLanguage, setCheckLanguage] = React.useState(true);
  const [checkOutCampaign, setCheckOutCampaign] = React.useState(true);
  const [scoreSelected, setScoreSelected] = React.useState(false);
  const [scoreOutSelected, setScoreOutSelected] = React.useState(false);
  const [channelSelected, setChannelSelected] = React.useState(false);
  const [agentAuditVal, setAgentAuditVal] = React.useState([]);
  const [agentAuditOutVal, setAgentAuditOutVal] = React.useState([]);
  const handleChangeAudGraph = (event) => {
    if (event.target.value !== "campaign") setCheckCampaign(false);
    else setCheckCampaign(true);
    setAudGraphVal(event.target.value);
  };
  const handleChangeAudPie = (event) => {
    if (event.target.value !== "language") setCheckLanguage(false);
    else setCheckLanguage(true);
    setAudPieVal(event.target.value);
  };
  const handleChangeAudOutGraph = (event) => {
    if (event.target.value !== "campaign") setCheckOutCampaign(false);
    else setCheckOutCampaign(true);
    setAudGraphOutVal(event.target.value);
  };
  const handleOpen = () => {
    if (text.length > 0) {
      setOpen(true);
    }
  };
  const handleChangeSelect = (event) => {
    const fil = handleFilters(event);
    setSentimentVal(fil);
  };
  const handleChangeSearch = (event, value) => {
    setSearchInputVal(value.label);
  };
  const handleChangeVocSearch = (event, value) => {
    setVocSearchInputVal(value.label);
    setVocMatchInputVal(value.label);
  };
  const handleChangeInput = (e) => {
    let finalSearchArr = [];
    let filterdSearchArr = [];
    let selectedMatch = "";
    let input = e.target.value.toLowerCase();
    setText(input);
    searchOptions.map((m) => {
      finalSearchArr.push(m.label);
    });
    finalSearchArr.filter((option) => {
      let m = option.toLowerCase();
      if (m.includes(input)) {
        filterdSearchArr.push(m);
      }
    });
    selectedMatch = filterdSearchArr.join(";");
    if (e.target.value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setMatchInputVal(e.target.value);
  };

  const handleChangeVocSearchInput = (e) => {
    let finalSearchArr = [];
    let filterdSearchArr = [];
    let selectedMatch = "";
    let input = e.target.value.toLowerCase();
    setText(input);
    vocSearchFieldFilters.map((m) => {
      finalSearchArr.push(m.label);
    });
    // console.log(" final search options are", finalSearchArr);
    finalSearchArr.filter((option) => {
      let m = option.toLowerCase();
      if (m.includes(input)) {
        filterdSearchArr.push(m);
      }
    });
    selectedMatch = filterdSearchArr.join(";");
    if (e.target.value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setVocMatchInputVal(e.target.value);
  };

  const handlePaste = (event) => {
    setSearchInputVal(event.clipboardData.getData("text"));
  };
  const handleVocSearchPaste = (event) => {
    setVocSearchInputVal(event.clipboardData.getData("text"));
  };
  const handleKey = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation(); // Prevent the default Enter key behavior
      return;
      // You can add your custom logic here, such as selecting the suggestion
    }
  };
  const handleChangeChatSelect = (event) => {
    const fil = handleFilters(event);
    setSentimentChatVal(fil);
  };
  const handleChangeSelectTranscript = (event) => {
    const fil = handleFilters(event);
    setSentimentTranscriptVal(fil);
  };
  const handleChangeGender = (event) => {
    const fil = handleFilters(event);
    setGenderVal(fil);
  };

  const handleChangeIntent = (event) => {
    const fil = handleFilters(event);
    setIntentVal(fil);
  };
  const handleChangeChatIntent = (event) => {
    const fil = handleFilters(event);
    setIntentChatVal(fil);
  };
  const handleChangeIntentTranscript = (event) => {
    const fil = handleFilters(event);
    setIntentTranscriptVal(fil);
  };
  const handleChangeAgent = (event) => {
    const fil = handleFilters(event);
    setAgentVal(fil);
  };
  const handleChangeChatChannel = (event) => {
    const fil = handleFilters(event);
    setChannelVal(fil);
  };
  const handleChangeAgentTranscription = (event) => {
    const fil = handleFilters(event);
    setAgentTranscriptionVal(fil);
  };
  const handleChangeDisposition = (event) => {
    const fil = handleFilters(event);
    setDispositionVal(fil);
  };
  const handleChangeDispositionTranscript = (event) => {
    const fil = handleFilters(event);
    setDispositionTranscriptVal(fil);
  };
  const handleChangeDispositionAudit = (event) => {
    const fil = handleFilters(event);
    setDispositionAuditVal(fil);
  };
  const handleChangeLanguageAudit = (event) => {
    const fil = handleFilters(event);
    setLanguageAduitVal(fil);
  };
  const handleChangeCampaignAudit = (event) => {
    const fil = handleFilters(event);
    setCampaignAuditVal(fil);
  };
  const handleChangeCampaignCdr = (event) => {
    const fil = handleFilters(event);
    setCampaignCDRVal(fil);
  };
  const handleChangeAgentCdr = (event) => {
    const fil = handleFilters(event);
    setAgentCDRVal(fil);
  };
  const handleChangeSkillCdr = (event) => {
    const fil = handleFilters(event);
    setSkillCDRVal(fil);
  };
  const handleChangeStatusCdr = (event) => {
    const fil = handleFilters(event);
    setStatusCDRVal(fil);
  };
  const handleChangeLocationCdr = (event) => {
    const fil = handleFilters(event);
    setLocationCDRVal(fil);
  };
  const handleChangeDispostionCdr = (event) => {
    const fil = handleFilters(event);
    setDispositionCDRVal(fil);
  };
  const handleChangePhoneCdr = (event) => {
    const fil = handleFilters(event);
    setPhoneNameCDRVal(fil);
  };
  const handleChangeCallerCdr = (event) => {
    setCallerCDRVal(event.target.value);
  };

  const handleChangeSkillOutAudit = (event) => {
    const fil = handleFilters(event);
    setSkillAuditOutVal(fil);
  };
  const handleChangeDispositionOutAudit = (event) => {
    const fil = handleFilters(event);
    setDispositionAuditOutVal(fil);
  };
  const handleChangeCampaignOutAudit = (event) => {
    const fil = handleFilters(event);
    setCampaignAuditOutVal(fil);
  };

  const handleChangeSkillAudit = (event) => {
    const fil = handleFilters(event);
    setSkillAuditVal(fil);
  };
  const handleChangeChannel = (event) => {
    // const fil = handleFilters(event);
    const {
      target: { value },
    } = event;
    setChannelChatVal(event.target.value);
    if (
      event.target.value === "email" ||
      event.target.value === "chat" ||
      event.target.value === "whatsapp"
    ) {
      setChannelSelected(true);
    } else {
      setChannelSelected(false);
    }
    // setChannelChatVal(event.target.value.value);
  };
  const handleChangeScoreAudit = (event) => {
    const {
      target: { value },
    } = event;
    setScoreAuditVal(event.target.value);
    if (
      event.target.value === "<" ||
      event.target.value === ">" ||
      event.target.value === "="
    ) {
      setScoreSelected(true);
    } else {
      setScoreSelected(false);
    }
  };
  const handleChangeScoreAuditOut = (event) => {
    const {
      target: { value },
    } = event;
    setScoreAuditOutVal(event.target.value);
    if (
      event.target.value === "<" ||
      event.target.value === ">" ||
      event.target.value === "="
    ) {
      setScoreOutSelected(true);
    } else {
      setScoreOutSelected(false);
    }
  };
  const handleChangeScoreInput = (event) => {
    setScoreInputVal(event.target.value);
  };
  const handleChangeScoreOutInput = (event) => {
    // const {
    //   target: { value },
    // } = event;
    setScoreOutInputVal(event.target.value);
  };
  const handleChangeAgentAudit = (event) => {
    const fil = handleFilters(event);
    setAgentAuditVal(fil);
  };
  const handleChangeAgentOutAudit = (event) => {
    const fil = handleFilters(event);
    setAgentAuditOutVal(fil);
  };
  const handleChangeCampaign = (event) => {
    const fil = handleFilters(event);
    setCampaignVal(fil);
  };
  const handleChangeCampaignInCalls = (event) => {
    const fil = handleFilters(event);
    setCallCampaignVal(fil);
  };

  const handleFilters = (event) => {
    //handle filters here. On change we have to update the graphs. Call the API again and get new data.
    // This is better than using Reactive componenets as we have to send the query for each component separately.

    const {
      target: { value },
    } = event;

    let map = {};

    for (let list of value) {
      map[Object.values(list).join("")] = list;
    }

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) > 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });
    return duplicateRemoved;
  };
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      fetchDashboardData(startDate);
    }, []);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      fetchFiltersData();
    }, []);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      fetchAuditFiltersData();
    }, []);
  }
  if (window.localStorage.getItem("user") === "sobha_limited") {
    useEffect(() => {
      fetchSobhaAuditFiltersData();
    }, []);
  }
  if (window.localStorage.getItem("user") === "mindtree") {
    useEffect(() => {
      fetchMindtreeCDRFiltersData();
    }, []);
  }
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {
      fetchVocSearchTextData();
    }, []);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      fetchAuditData(startDateAudit);
    }, []);
  }
  if (window.localStorage.getItem("user") === "sobha_limited") {
    useEffect(() => {
      fetchOpenSearchAuditData(startDateAudit, endDateAudit);
    }, []);
  }
  if (window.localStorage.getItem("user") === "mindtree") {
    useEffect(() => {
      fetchOpenCdrData(startDateCDR, endDateCDR);
    }, []);
  }
  if (window.localStorage.getItem("user") === "sobha_limited") {
    useEffect(() => {
      fetchOpenSearchCallsData(startDateAudit, endDateAudit);
    }, []);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      fetchAuditOutData(startDateAuditOut);
    }, []);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      if (Object.keys(jsonData).length !== 0) {
        setDashboardData(getGraphData());
      }
    }, [jsonData]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      if (Object.keys(jsonChatData).length !== 0) {
        setDashboardChatData(getChatGraphData());
      }
    }, [jsonChatData]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      if (
        Object.keys(jsonAuditData).length !== 0 &&
        jsonAuditData["Hits"] !== undefined &&
        jsonAuditData["Hits"] !== null &&
        jsonAuditData["Hits"].length !== 0
      ) {
        // if(jsonAuditData['Hits']!=undefined&&jsonAuditData['Hits']!=null&&jsonAuditData['Hits'].length!=0){
        setDashboardAuditData(getAuditGraphData());
      }
    }, [jsonAuditData]);
  }
  if (window.localStorage.getItem("user") === "sobha_limited") {
    useEffect(() => {
      if (
        jsonOpenAuditData &&
        Object.keys(jsonOpenAuditData).length !== 0 &&
        jsonOpenAuditData["Hits"] !== undefined &&
        jsonOpenAuditData["Hits"] !== null &&
        jsonOpenAuditData["Hits"].length !== 0
      ) {
        setDashboardAuditData(getOpenAuditGraphData());
      } else {
        setDashboardAuditData({});
      }
    }, [jsonOpenAuditData]);
  }
  if (window.localStorage.getItem("user") === "poonawalla_fincorp") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (Object.keys(jsonAuditOutData).length !== 0) {
        // if(jsonAuditOutData['Hits']!=undefined&&jsonAuditOutData['Hits']!=null&&jsonAuditOutData['Hits'].length!=0){
        setDashboardAuditOutData(getAuditOutGraphData());
      }
    }, [jsonAuditOutData]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      //console.log("Got dashboard data now")
      //console.log(dashboardData)
    }, [dashboardData]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      //console.log("Got dashboard data now")
      //console.log(dashboardData)
    }, [dashboardChatData]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      //console.log("Got dashboard data now")
      //console.log(dashboardData)
    }, [dashboardAuditData]);
  }
  const findName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findChatDataName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonChatData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findAuditDataName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonAuditData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findOpenAuditDataName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonOpenAuditData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findAuditOutDataName = (name) => {
    //console.log(name, jsonData);
    for (const element of jsonAuditOutData["aggregations"]) {
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findFilters = (name) => {
    for (const element of objFilter) {
      // console.log("Element filter is",element)
      if (element["name"] === name) {
        return element;
      }
    }
  };
  const findVocSearch = (name) => {
    for (const element of vocSearchObj) {
      if (element.name === name) {
        return element;
      }
    }
  };
  function getLanguageNames(languageCodes) {
    return languageCodes.map(
      (code) => languageCodeMap[code] || "Unknown Language"
    );
  }
  const getAuditOutGraphData = () => {
    const scoreAggregation = jsonAuditOutData["Score Histogram"];
    let scoreAggregationLabelPer = [];
    let scoreAggregationDataPer = [];
    let scoreAggregationAggregationObj = {};
    if (scoreAggregation.length !== 0) {
      scoreAggregation[0].labels.forEach((element, index) => {
        scoreAggregationAggregationObj[element] =
          scoreAggregation[0].data[index];
      });
    } else {
      scoreAggregationAggregationObj = {};
    }
    Object.entries(scoreAggregationAggregationObj).forEach(([label, val]) => {
      scoreAggregationLabelPer.push(label);
      scoreAggregationDataPer.push(val.toFixed(1));
    });

    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: scoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Score Data",
          stack: 1,
        },
      ],
      labels: scoreAggregationLabelPer.slice(0, 12),
    };
    const campaignScoreAggregation = findAuditOutDataName(
      "Score By Campaign",
      jsonAuditOutData
    );

    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationAggregationObj = {};
    campaignScoreAggregation["labels"].forEach((element, index) => {
      campaignScoreAggregationAggregationObj[element] =
        campaignScoreAggregation["data"][index];
    });
    Object.entries(campaignScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        campaignScoreAggregationLabelPer.push(label);
        campaignScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: campaignScoreAggregationLabelPer.slice(0, 12),
    };

    const skillScoreAggregation = findAuditOutDataName(
      "Score By Skill",
      jsonAuditOutData
    );

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    skillScoreAggregation["labels"].forEach((element, index) => {
      skillScoreAggregationAggregationObj[element] =
        skillScoreAggregation["data"][index];
    });
    Object.entries(skillScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        skillScoreAggregationLabelPer.push(label);
        skillScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: skillScoreAggregationLabelPer.slice(0, 12),
    };

    const dispositionScoreAggregation = findAuditOutDataName(
      "Score By Disposition",
      jsonAuditOutData
    );

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    dispositionScoreAggregation["labels"].forEach((element, index) => {
      dispositionScoreAggregationAggregationObj[element] =
        dispositionScoreAggregation["data"][index];
    });
    Object.entries(dispositionScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        dispositionScoreAggregationLabelPer.push(label);
        dispositionScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value, context) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: dispositionScoreAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      scoreData: scoreData,
      scoreOptions: scoreOptions,
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
    };
  };
  const getAuditGraphData = () => {
    const scoreAggregation = jsonAuditData["Score Histogram"];
    let scoreAggregationLabelPer = [];
    let scoreAggregationDataPer = [];
    let scoreAggregationAggregationObj = {};
    scoreAggregation[0].labels.forEach((element, index) => {
      scoreAggregationAggregationObj[element] = scoreAggregation[0].data[index];
    });
    Object.entries(scoreAggregationAggregationObj).forEach(([label, val]) => {
      scoreAggregationLabelPer.push(label);
      scoreAggregationDataPer.push(val.toFixed(1));
    });

    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value, context) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: scoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Score Data",
          stack: 1,
        },
      ],
      labels: scoreAggregationLabelPer.slice(0, 12),
    };

    const campaignScoreAggregation = findAuditDataName(
      "Score By Campaign",
      jsonAuditData
    );

    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationAggregationObj = {};
    campaignScoreAggregation["labels"].forEach((element, index) => {
      campaignScoreAggregationAggregationObj[element] =
        campaignScoreAggregation["data"][index];
    });
    Object.entries(campaignScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        campaignScoreAggregationLabelPer.push(label);
        campaignScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: campaignScoreAggregationLabelPer.slice(0, 12),
    };

    const skillScoreAggregation = findAuditDataName(
      "Score By Skill",
      jsonAuditData
    );

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    skillScoreAggregation["labels"].forEach((element, index) => {
      skillScoreAggregationAggregationObj[element] =
        skillScoreAggregation["data"][index];
    });
    Object.entries(skillScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        skillScoreAggregationLabelPer.push(label);
        skillScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: skillScoreAggregationLabelPer.slice(0, 12),
    };

    const dispositionScoreAggregation = findAuditDataName(
      "Score By Disposition",
      jsonAuditData
    );

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    dispositionScoreAggregation["labels"].forEach((element, index) => {
      dispositionScoreAggregationAggregationObj[element] =
        dispositionScoreAggregation["data"][index];
    });
    Object.entries(dispositionScoreAggregationAggregationObj).forEach(
      ([label, val]) => {
        dispositionScoreAggregationLabelPer.push(label);
        dispositionScoreAggregationDataPer.push(val.toFixed(1));
      }
    );

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: dispositionScoreAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      scoreData: scoreData,
      scoreOptions: scoreOptions,
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
    };
  };

  const getOpenAuditGraphData = () => {
    const languagePieAggregation = jsonOpenAuditData["Language count"];
    let languagePieLabel = [];
    let languagePieData = [];
    if (languagePieAggregation.length !== 0) {
      languagePieLabel = getLanguageNames(languagePieAggregation[0].labels);
      languagePieData = languagePieAggregation[0].data;
    }
    const languagePieChartData = {
      labels: languagePieLabel,
      datasets: [
        {
          label: "# of calls in language",
          data: languagePieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const pieDispositionOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Disposition Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const pieChartsOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "left",
          align: true,
          text: "Language Analysis",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    const dispositionPieAggregation = jsonOpenAuditData["dispostion count"];
    let dispositionPieLabel = [];
    let dispositionPieData = [];
    if (dispositionPieAggregation.length !== 0) {
      dispositionPieLabel = dispositionPieAggregation[0].labels;
      dispositionPieData = dispositionPieAggregation[0].data;
    }
    const dispositionPieChartData = {
      labels: dispositionPieLabel,
      datasets: [
        {
          label: "# of call dispositions",
          data: dispositionPieData,
          backgroundColor: [
            "#1876D1",
            "#9AEAA7",
            "#ECC1C9",
            "#FFBF69",
            "#CCD6EB",
          ], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
          hoverOffset: 4,
        },
      ],
    };
    const timeScoreAggregation = jsonOpenAuditData["TimeScore Histogram"];
    let timeScoreAggrLabelPer = [];
    let timeScoreAggrDataPer = [];
    let timeScoreAggregationObj = {};
    if (timeScoreAggregation.length !== 0 || !_.isEmpty(timeScoreAggregation)) {
      timeScoreAggregation[0].labels.forEach((element, index) => {
        timeScoreAggregationObj[element] = timeScoreAggregation[0].data[index];
      });
      Object.entries(timeScoreAggregationObj).forEach(([label, val]) => {
        timeScoreAggrLabelPer.push(label);
        timeScoreAggrDataPer.push(val.toFixed(1));
      });
    }

    const timeScoreData = {
      datasets: [
        {
          data: timeScoreAggrDataPer,
          backgroundColor: "#01FF84",
          // borderColor: "#000000",
          borderWidth: 1,
          label: "Trends of Score",
          fill: false,
          lineTension: 0.2,
          borderColor: "#01FF84",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#01FF84",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#01FF84",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        },
      ],
      labels: timeScoreAggrLabelPer,
    };

    const timeScoreOptions = {
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Trends Over Time",
        },
        scrollbar: {
          enabled: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      responsiveAnimationDuration: 0, // Disables animation for better performance
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const parameterAggregation = jsonOpenAuditData["Parameter-wise Adherence"];
    let parameterAggrLabelPer = [];
    let parameterAggrDataPer = [];
    let parameterAggregationObj = {};
    if (parameterAggregation.length !== 0) {
      parameterAggregation[0].labels.forEach((element, index) => {
        parameterAggregationObj[element] = parameterAggregation[0].data[index];
      });
      Object.entries(parameterAggregationObj).forEach(([label, val]) => {
        parameterAggrLabelPer.push(label);
        parameterAggrDataPer.push(val.toFixed(1));
      });
    }
    const parameterScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Parameter-wise Adherence",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const parameterScoreData = {
      datasets: [
        {
          data: parameterAggrDataPer,
          backgroundColor: "#01FF84",
          label: "Parameter-wise Score Data in %",
          stack: 1,
        },
      ],
      labels: transformArray(parameterAggrLabelPer),
    };

    const scoreAggregation = jsonOpenAuditData["Score Histogram"];
    let scoreAggregationLabelPer = [];
    let scoreAggregationDataPer = [];
    let scoreAggregationAggregationObj = {};
    if (!_.isEmpty(scoreAggregation)) {
      scoreAggregation[0].labels.forEach((element, index) => {
        scoreAggregationAggregationObj[element] =
          scoreAggregation[0].data[index];
      });
      Object.entries(scoreAggregationAggregationObj).forEach(([label, val]) => {
        scoreAggregationLabelPer.push(label);
        scoreAggregationDataPer.push(val.toFixed(1));
      });
    }

    const scoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const scoreData = {
      datasets: [
        {
          data: scoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Score Data",
          stack: 1,
        },
      ],
      labels: scoreAggregationLabelPer.slice(0, 12),
    };

    const campaignScoreAggregation = findOpenAuditDataName(
      "Score By Campaign",
      jsonOpenAuditData
    );

    let campaignScoreAggregationLabelPer = [];
    let campaignScoreAggregationDataPer = [];
    let campaignScoreAggregationAggregationObj = {};
    if (!_.isEmpty(campaignScoreAggregation)) {
      campaignScoreAggregation["labels"].forEach((element, index) => {
        campaignScoreAggregationAggregationObj[element] =
          campaignScoreAggregation["data"][index];
      });
      Object.entries(campaignScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          campaignScoreAggregationLabelPer.push(label);
          campaignScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const campaignScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Campaign Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const campaignScoreData = {
      datasets: [
        {
          data: campaignScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Campaign Score Data in %",
          stack: 1,
        },
      ],
      labels: campaignScoreAggregationLabelPer.slice(0, 12),
    };

    const skillScoreAggregation = findOpenAuditDataName(
      "Score By Skill",
      jsonOpenAuditData
    );

    let skillScoreAggregationLabelPer = [];
    let skillScoreAggregationDataPer = [];
    let skillScoreAggregationAggregationObj = {};
    if (!_.isEmpty(skillScoreAggregation)) {
      skillScoreAggregation["labels"].forEach((element, index) => {
        skillScoreAggregationAggregationObj[element] =
          skillScoreAggregation["data"][index];
      });
      Object.entries(skillScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          skillScoreAggregationLabelPer.push(label);
          skillScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const skillScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Skill Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const skillScoreData = {
      datasets: [
        {
          data: skillScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Skill Score Data in %",
          stack: 1,
        },
      ],
      labels: skillScoreAggregationLabelPer.slice(0, 12),
    };

    const dispositionScoreAggregation = findOpenAuditDataName(
      "Score By Disposition",
      jsonOpenAuditData
    );

    let dispositionScoreAggregationLabelPer = [];
    let dispositionScoreAggregationDataPer = [];
    let dispositionScoreAggregationAggregationObj = {};
    if (!_.isEmpty(dispositionScoreAggregation)) {
      dispositionScoreAggregation["labels"].forEach((element, index) => {
        dispositionScoreAggregationAggregationObj[element] =
          dispositionScoreAggregation["data"][index];
      });
      Object.entries(dispositionScoreAggregationAggregationObj).forEach(
        ([label, val]) => {
          dispositionScoreAggregationLabelPer.push(label);
          dispositionScoreAggregationDataPer.push(val.toFixed(1));
        }
      );
    }

    const dispositionScoreOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Disposition Score Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    const dispositionScoreData = {
      datasets: [
        {
          data: dispositionScoreAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Disposition Score Data in %",
          stack: 1,
        },
      ],
      labels: dispositionScoreAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      scoreData: scoreData,
      scoreOptions: scoreOptions,
      campaignScoreData: campaignScoreData,
      campaignScoreOptions: campaignScoreOptions,
      skillScoreData: skillScoreData,
      skillScoreOptions: skillScoreOptions,
      dispositionScoreData: dispositionScoreData,
      dispositionScoreOptions: dispositionScoreOptions,
      languagePieChartData: languagePieChartData,
      dispositionPieChartOptions: pieDispositionOptions,
      dispositionPieChartData: dispositionPieChartData,
      pieChartsOptions: pieChartsOptions,
      parameterScoreOptions: parameterScoreOptions,
      parameterScoreData: parameterScoreData,
      timeScoreData: timeScoreData,
      timeScoreOptions: timeScoreOptions,
    };
  };

  const getChatGraphData = () => {
    const intentOptions = {
      indexAxis: "y",
      // elements: {
      //   bar: {
      //     borderWidth: 2,
      //   },
      // },
      scales: {
        x: {},
        y: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          // position: 'right',
          display: true,
        },
        datalabels: {
          display: true,
          color: "black",
          // align: "end",
          formatter: function (value) {
            return value + " %";
          },
          // formatter:
          // font: {
          //   weight: 'bold'
          // }
        },
        title: {
          display: true,
          text: "Intent Distribution",
        },
      },

      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentChatDrill.includes(
              agentintentFinallabelPercentage[el[0].index]
            )
          ) {
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatVal,
              intentChatDrill
            );
          } else {
            intentChatDrill.push(agentintentFinallabelPercentage[el[0].index]);
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatVal,
              intentChatDrill
            );
          }
        }
      },
    };
    let agentFiltersAggregationObj = {};
    let agentintentArrFinal = {};
    let customerintentArrFinal = {};

    const agentIntentAgg = findChatDataName("Agent Intents", jsonChatData);
    const agentFilters = findChatDataName("Calls Per Agent", jsonChatData);
    const customerIntentAgg = findChatDataName(
      "Customer Intents",
      jsonChatData
    );
    let totalIntents = 0;
    let intentArrFinal = setIntentArrays(
      agentIntentAgg["labels"],
      customerIntentAgg["labels"],
      agentIntentAgg["data"],
      customerIntentAgg["data"]
    );
    agentFilters["labels"].forEach((element, index) => {
      agentFiltersAggregationObj[element] = agentFilters["data"][index];
    });

    agentIntentAgg["labels"].forEach((element, index) => {
      agentintentArrFinal[element] = agentIntentAgg["data"][index];
    });
    customerIntentAgg["labels"].forEach((element, index) => {
      customerintentArrFinal[element] = customerIntentAgg["data"][index];
    });

    const agentintentFinalData = [];
    let agentintentFinalDataPercentage = [];
    let agentintentFinallabelPercentage = [];
    const customerintentFinalData = [];
    let customerintentFinalDataPercentage = [];
    let customerintentFinallabelPercentage = [];
    const agentintentFinallabel = [];
    const customerintentFinallabel = [];
    let intentArrFilters = [];
    let agentArrFilters = [];
    // let campaignArrFilters=[];
    let agentDataArrFilters = [];
    let customerTranscript = [];
    agentIntentAgg["labels"].forEach((data) => {
      intentArrFilters.push(data);
    });
    agentFilters["labels"].forEach((data) => {
      agentArrFilters.push(data);
    });
    Object.entries(agentFiltersAggregationObj).forEach((data) => {
      agentDataArrFilters.push(data);
    });
    if (window.localStorage.getItem("user") === "techm_adani") {
      delete agentintentArrFinal["empathy"];
      delete agentintentArrFinal["repeat_call"];
      delete agentintentArrFinal["escalation"];
    }
    Object.entries(agentintentArrFinal).forEach(([label, val]) => {
      agentintentFinallabel.push(label);
      agentintentFinalData.push(val);
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      window.localStorage.removeItem("intents");
      totalIntents = agentintentFinalData.reduce((a, b) => a + b, 0);
      window.localStorage.setItem(
        "intents",
        JSON.stringify(agentintentArrFinal)
      );
      for (const ele of agentintentFinalData) {
        agentintentFinalDataPercentage.push(
          ((ele / jsonChatData["Total Calls"]) * 100).toFixed(2)
        );
      }
      agentintentFinallabelPercentage = agentintentFinallabel;
    } else {
      const per = window.localStorage.getItem("intents");
      intentVal.forEach((data) => {
        delete agentintentArrFinal[data["label"]];
      });

      Object.entries(JSON.parse(per)).forEach(([label, val]) => {
        Object.entries(agentintentArrFinal).forEach(([label1, val1]) => {
          if (label === label1) {
            agentintentFinallabelPercentage.push(label);
            agentintentFinalDataPercentage.push(
              ((val1 / val) * 100).toFixed(2)
            );
          }
        });
      });
    }
    Object.entries(customerintentArrFinal).forEach(([label, val]) => {
      customerintentFinallabel.push(label);
      customerintentFinalData.push(val);
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      window.localStorage.removeItem("intents");
      totalIntents = customerintentFinalData.reduce((a, b) => a + b, 0);
      window.localStorage.setItem(
        "intents",
        JSON.stringify(customerintentArrFinal)
      );
      for (const ele of customerintentFinalData) {
        customerintentFinalDataPercentage.push(
          ((ele / jsonChatData["Total Calls"]) * 100).toFixed(2)
        );
      }
      customerintentFinallabelPercentage = customerintentFinallabel;
    } else {
      const per = window.localStorage.getItem("intents");
      intentVal.forEach((data) => {
        delete customerintentArrFinal[data["label"]];
      });

      Object.entries(JSON.parse(per)).forEach(([label, val]) => {
        Object.entries(customerintentArrFinal).forEach(([label1, val1]) => {
          if (label === label1) {
            customerintentFinallabelPercentage.push(label);
            customerintentFinalDataPercentage.push(
              ((val1 / val) * 100).toFixed(2)
            );
          }
        });
      });
    }

    const agentDataArr = getIntentData(agentDataArrFilters);
    setAgentDataOptions(agentDataArr);
    const customerTranscriptArr = getIntentData(customerTranscript);
    setCustomerTranscriptOptions(customerTranscriptArr);
    // const agentTranscriptArr = getIntentData(agentTranscript);
    // setAgentTranscriptOptions(agentTranscriptArr);
    const intentData = {
      datasets: [
        {
          data: agentintentFinalDataPercentage.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Intents",
          fontSize: "10px",
        },
        {
          data: customerintentFinalDataPercentage.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Intents",
          fontSize: "10px",
        },
      ],
      labels: customerintentFinallabelPercentage.slice(0, 12),
    };

    const negativeOptions = {
      responsive: true,
      scales: {
        y: {},
        x: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: true,
          text: "Agent Negative Chart",
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          // font: {
          //   weight: 'bold'
          // }
        },
      },
    };

    let agentSentimentAggregationObj = {};
    let customerSentimentAggregationObj = {};
    const agentSentimentAggregation = findChatDataName(
      "Agent Sentiment",
      jsonChatData
    );
    agentSentimentAggregation["labels"].forEach((element, index) => {
      agentSentimentAggregationObj[element] =
        agentSentimentAggregation["data"][index];
    });
    const customerSentimentAggregation = findChatDataName(
      "Customer Sentiment",
      jsonChatData
    );
    customerSentimentAggregation["labels"].forEach((element, index) => {
      customerSentimentAggregationObj[element] =
        customerSentimentAggregation["data"][index];
    });
    let agentSentimentPercentage = [];
    let customerSentimentPercentage = [];
    let totalAgentSentimentAggregation = 0;
    let totalCustomerSentimentAggregation = 0;
    agentSentimentAggregation["data"].forEach((data) => {
      totalAgentSentimentAggregation += data;
    });
    agentSentimentAggregation["data"].forEach((data) => {
      if (
        selectionList(sentimentVal) === undefined ||
        (selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral")
      ) {
        agentSentimentPercentage.push(
          ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
        );
      } else {
        if (
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "neutral" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral"
        ) {
          agentSentimentPercentage.push(
            ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
          );
        } else if (
          selectionList(sentimentVal) === "positive" ||
          selectionList(sentimentVal) === "negative" ||
          selectionList(sentimentVal) === "neutral"
        ) {
          agentSentimentPercentage.push(
            ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
          );
        }
      }
    });
    customerSentimentAggregation["data"].forEach((data) => {
      totalCustomerSentimentAggregation += data;
    });

    customerSentimentAggregation["data"].forEach((data) => {
      if (
        selectionList(sentimentVal) === undefined ||
        (selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral")
      ) {
        customerSentimentPercentage.push(
          ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
        );
      } else {
        if (
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "neutral" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral"
        ) {
          customerSentimentPercentage.push(
            ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
          );
        } else if (
          selectionList(sentimentVal) === "positive" ||
          selectionList(sentimentVal) === "negative" ||
          selectionList(sentimentVal) === "neutral"
        ) {
          customerSentimentPercentage.push(
            ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
          );
        }
      }
    });
    const sentimentOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value, context) {
            return value + " %";
          },
        },
        title: {
          display: true,
          text: "Sentiment Overview",
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            sentimentChatDrill.includes(
              agentSentimentAggregation["labels"][el[0].index]
            )
          ) {
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatDrill,
              intentChatVal
            );
          } else {
            sentimentChatDrill.push(
              agentSentimentAggregation["labels"][el[0].index]
            );
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatDrill,
              intentChatVal
            );
          }
        }
      },
    };
    const sentimentData = {
      datasets: [
        {
          data: agentSentimentPercentage.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Sentiment",
        },
        {
          data: customerSentimentPercentage.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Sentiment",
        },
      ],
      labels: customerSentimentAggregation["labels"].slice(0, 12),
    };

    const agentNegativityAggregation = findChatDataName(
      "Agent Negativity By Intent",
      jsonChatData
    );
    const customerNegativityAggregation = findChatDataName(
      "Customer Negativity By Intent",
      jsonChatData
    );

    let agentNegativityAggregationLabelPer = [];
    let agentNegativityAggregationDataPer = [];
    let agentNegativityAggregationObj = {};
    agentNegativityAggregation["labels"].forEach((element, index) => {
      agentNegativityAggregationObj[element] =
        agentNegativityAggregation["data"][index];
    });
    if (intentVal === undefined || intentVal.length <= 0) {
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentNegativityAggregationObj["empathy"];
        delete agentNegativityAggregationObj["repeat_call"];
        delete agentNegativityAggregationObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete agentNegativityAggregationObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentNegativityAggregationObj["empathy"];
        delete agentNegativityAggregationObj["repeat_call"];
        delete agentNegativityAggregationObj["escalation"];
      }
    }
    Object.entries(agentNegativityAggregationObj).forEach(([label, val]) => {
      agentNegativityAggregationLabelPer.push(label);
      agentNegativityAggregationDataPer.push(
        ((val / agentSentimentAggregationObj["negative"]) * 100).toFixed(1)
      );
    });

    let customerNegativityAggregationLabelPer = [];
    let customerNegativityAggregationDataPer = [];
    let customerNegativityAggregationObj = {};
    customerNegativityAggregation["labels"].forEach((element, index) => {
      customerNegativityAggregationObj[element] =
        customerNegativityAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      // retrievalLabelPer =
    } else {
      intentVal.forEach((data) => {
        delete customerNegativityAggregationObj[data["label"]];
      });
    }
    Object.entries(customerNegativityAggregationObj).forEach(([label, val]) => {
      customerNegativityAggregationLabelPer.push(label);
      customerNegativityAggregationDataPer.push(
        ((val / customerSentimentAggregationObj["negative"]) * 100).toFixed(1)
      );
    });
    const negativityOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Negative Sentiment Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentChatDrill.includes(
              agentNegativityAggregationLabelPer[el[0].index]
            )
          ) {
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatVal,
              intentChatDrill
            );
          } else {
            intentChatDrill.push(
              agentNegativityAggregationLabelPer[el[0].index]
            );
            fetchChatDrillDownData(
              startDateChat,
              endDateChat,
              channelVal,
              sentimentChatVal,
              intentChatDrill
            );
          }
        }
      },
    };
    const negativityData = {
      datasets: [
        {
          data: agentNegativityAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Negative Sentiment in %",
          stack: 1,
        },
        {
          data: customerNegativityAggregationDataPer.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Negative Sentiment in %",
          stack: 2,
        },
      ],
      labels: customerNegativityAggregationLabelPer.slice(0, 12),
    };
    //add all defined variables into one object
    return {
      intentData: intentData,
      campaignOptions: campaignOptions,
      customerTranscriptOptions: customerTranscriptOptions,
      agentDataOptions: agentDataOptions,
      intentOptions: intentOptions,
      negativeOptions: negativeOptions,
      sentimentData: sentimentData,
      sentimentOptions: sentimentOptions,
      negativityData: negativityData,
      negativityOptions: negativityOptions,
    };
  };

  const getGraphData = () => {
    let noisyCallsDataPercent = 0;
    let loudCallsDataPercent = 0;
    if (jsonData["Total Calls"] !== 0) {
      noisyCallsDataPercent = (
        (jsonData["Noisy Calls"] / jsonData["Total Calls"]) *
        100
      ).toFixed(2);
      loudCallsDataPercent = (
        (jsonData["Loud Calls"] / jsonData["Total Calls"]) *
        100
      ).toFixed(2);
    }

    const intentOptions = {
      indexAxis: "y",
      scales: {
        x: {},
        y: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          display: true,
          color: "black",
          // align: "end",
          formatter: function (value) {
            return value + " %";
          },
        },
        title: {
          display: true,
          text: "Intent Distribution",
        },
      },

      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentDrill.includes(agentintentFinallabelPercentage[el[0].index])
          ) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(agentintentFinallabelPercentage[el[0].index]);
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal,
            genderVal
          );
        }
      },
    };
    let agentFiltersAggregationObj = {};
    let dispositionFiltersAggregationObj = {};
    let customerTranscriptLabel = [];
    let agentintentArrFinal = {};
    let customerintentArrFinal = {};
    const agentIntentAgg = findName("Agent Intents", jsonData);
    const agentFilters = findName("Calls Per Agent", jsonData);
    const searchFilters = findName("Search Transcript Filters", jsonData);
    const dispositionFilters = findName("Disposition Count", jsonData);
    const customerIntentAgg = findName("Customer Intents", jsonData);
    const customerTranscriptAgg = findName("Customer Transcript", jsonData);
    // const agentTranscriptAgg = findName('Agent Transcript', jsonData);
    let totalIntents = 0;
    // const intentArr = getIntentData(intentAggregation);
    let intentArrFinal = setIntentArrays(
      agentIntentAgg["labels"],
      customerIntentAgg["labels"],
      agentIntentAgg["data"],
      customerIntentAgg["data"]
    );
    agentFilters["labels"].forEach((element, index) => {
      agentFiltersAggregationObj[element] = agentFilters["data"][index];
    });
    // campaignFilters['labels'].forEach((element, index) => { campaignFiltersAggregationObj[element] = campaignFilters['data'][index]; });
    dispositionFilters["labels"].forEach((element, index) => {
      dispositionFiltersAggregationObj[element] =
        dispositionFilters["data"][index];
    });
    agentIntentAgg["labels"].forEach((element, index) => {
      agentintentArrFinal[element] = agentIntentAgg["data"][index];
    });
    customerIntentAgg["labels"].forEach((element, index) => {
      customerintentArrFinal[element] = customerIntentAgg["data"][index];
    });
    if (customerTranscriptAgg !== undefined) {
      customerTranscriptLabel = customerTranscriptAgg["labels"];
    }
    if (searchFilters !== undefined) {
    }
    const agentintentFinalData = [];
    let agentintentFinalDataPercentage = [];
    let agentintentFinallabelPercentage = [];
    const customerintentFinalData = [];
    let customerintentFinalDataPercentage = [];
    let customerintentFinallabelPercentage = [];
    const agentintentFinallabel = [];
    const customerintentFinallabel = [];
    let intentArrFilters = [];
    let agentArrFilters = [];
    // let campaignArrFilters=[];
    let dispositionArrFilters = [];
    let agentDataArrFilters = [];
    let customerTranscript = [];
    agentIntentAgg["labels"].forEach((data) => {
      intentArrFilters.push(data);
    });
    agentFilters["labels"].forEach((data) => {
      agentArrFilters.push(data);
    });

    dispositionFilters["labels"].forEach((data) => {
      dispositionArrFilters.push(data);
    });

    customerTranscriptLabel.forEach((data) => {
      customerTranscript.push(data);
    });
    Object.entries(agentFiltersAggregationObj).forEach((data) => {
      agentDataArrFilters.push(data);
    });

    if (window.localStorage.getItem("user") === "techm_adani") {
      delete agentintentArrFinal["empathy"];
      delete agentintentArrFinal["repeat_call"];
      delete agentintentArrFinal["escalation"];
    }
    if (window.localStorage.getItem("user") === "techm_adani") {
      delete customerintentArrFinal["empathy"];
      delete customerintentArrFinal["repeat_call"];
      delete customerintentArrFinal["escalation"];
    }
    Object.entries(agentintentArrFinal).forEach(([label, val]) => {
      agentintentFinallabel.push(label);
      agentintentFinalData.push(val);
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      window.localStorage.removeItem("intents");
      totalIntents = agentintentFinalData.reduce((a, b) => a + b, 0);
      window.localStorage.setItem(
        "intents",
        JSON.stringify(agentintentArrFinal)
      );
      for (const ele of agentintentFinalData) {
        agentintentFinalDataPercentage.push(
          ((ele / jsonData["Total Calls"]) * 100).toFixed(2)
        );
      }
      agentintentFinallabelPercentage = agentintentFinallabel;
    } else {
      const per = window.localStorage.getItem("intents");
      intentVal.forEach((data) => {
        delete agentintentArrFinal[data["label"]];
      });

      Object.entries(JSON.parse(per)).forEach(([label, val]) => {
        Object.entries(agentintentArrFinal).forEach(([label1, val1]) => {
          if (label === label1) {
            agentintentFinallabelPercentage.push(label);
            agentintentFinalDataPercentage.push(
              ((val1 / val) * 100).toFixed(2)
            );
          }
        });
      });
    }
    Object.entries(customerintentArrFinal).forEach(([label, val]) => {
      customerintentFinallabel.push(label);
      customerintentFinalData.push(val);
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      window.localStorage.removeItem("intents");
      totalIntents = customerintentFinalData.reduce((a, b) => a + b, 0);
      window.localStorage.setItem(
        "intents",
        JSON.stringify(customerintentArrFinal)
      );
      for (const ele of customerintentFinalData) {
        customerintentFinalDataPercentage.push(
          ((ele / jsonData["Total Calls"]) * 100).toFixed(2)
        );
      }
      customerintentFinallabelPercentage = customerintentFinallabel;
    } else {
      const per = window.localStorage.getItem("intents");
      intentVal.forEach((data) => {
        delete customerintentArrFinal[data["label"]];
      });

      Object.entries(JSON.parse(per)).forEach(([label, val]) => {
        Object.entries(customerintentArrFinal).forEach(([label1, val1]) => {
          if (label === label1) {
            customerintentFinallabelPercentage.push(label);
            customerintentFinalDataPercentage.push(
              ((val1 / val) * 100).toFixed(2)
            );
          }
        });
      });
    }

    const agentDataArr = getIntentData(agentDataArrFilters);
    setAgentDataOptions(agentDataArr);
    const customerTranscriptArr = getIntentData(customerTranscript);
    setCustomerTranscriptOptions(customerTranscriptArr);

    const intentData = {
      datasets: [
        {
          data: agentintentFinalDataPercentage.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Intents",
          fontSize: "10px",
        },
        {
          data: customerintentFinalDataPercentage.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Intents",
          fontSize: "10px",
        },
      ],
      labels: customerintentFinallabelPercentage.slice(0, 12),
    };
    const retrievalOptions = {
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      scales: {
        x: {},
        y: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: true,
          text: "Average Information Retrieval Time (Seconds) by Intent",
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          // font: {
          //   weight: 'bold'
          // }
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (intentDrill.includes(retrievalLabelPer[el[0].index])) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(retrievalLabelPer[el[0].index]);
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal,
            genderVal
          );
        }
      },
    };
    const callTimeOptions = {
      responsive: true,
      scales: {
        y: {},
        x: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: true,
          text: "Average Call Duration (Seconds)",
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          // font: {
          //   weight: 'bold'
          // }
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (intentDrill.includes(callTimeLabelPer[el[0].index])) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(callTimeLabelPer[el[0].index]);
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal
          );
        }
      },
    };
    const retrievalAggregation = findName("Retrieval Time By Intent", jsonData);

    let retrievalLabelPer = [];
    let retrievalDataPer = [];
    let retivalObj = {};
    retrievalAggregation["labels"].forEach((element, index) => {
      retivalObj[element] = retrievalAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete retivalObj["empathy"];
        delete retivalObj["repeat_call"];
        delete retivalObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete retivalObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete retivalObj["empathy"];
        delete retivalObj["repeat_call"];
        delete retivalObj["escalation"];
      }
    }
    Object.entries(retivalObj).forEach(([label, val]) => {
      retrievalLabelPer.push(label);
      retrievalDataPer.push(val.toFixed(2));
    });

    const retrievalData = {
      datasets: [
        {
          data: retrievalDataPer.slice(0, 12),
          // data: IntData,
          backgroundColor: "#01FF84",
        },
      ],
      labels: retrievalLabelPer.slice(0, 12),
      // labels: Intlab
    };

    const negativeOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: true,
          text: "Agent Negative Chart",
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          // font: {
          //   weight: 'bold'
          // }
        },
      },
    };
    let agentSentimentAggregationObj = {};
    let customerSentimentAggregationObj = {};
    const agentSentimentAggregation = findName("Agent Sentiment", jsonData);
    agentSentimentAggregation["labels"].forEach((element, index) => {
      agentSentimentAggregationObj[element] =
        agentSentimentAggregation["data"][index];
    });
    const customerSentimentAggregation = findName(
      "Customer Sentiment",
      jsonData
    );
    customerSentimentAggregation["labels"].forEach((element, index) => {
      customerSentimentAggregationObj[element] =
        customerSentimentAggregation["data"][index];
    });
    let agentSentimentPercentage = [];
    let customerSentimentPercentage = [];
    let totalAgentSentimentAggregation = 0;
    let totalCustomerSentimentAggregation = 0;
    agentSentimentAggregation["data"].forEach((data) => {
      totalAgentSentimentAggregation += data;
    });
    agentSentimentAggregation["data"].forEach((data) => {
      if (
        selectionList(sentimentVal) === undefined ||
        (selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral")
      ) {
        agentSentimentPercentage.push(
          ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
        );
      } else {
        if (
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "neutral" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral"
        ) {
          agentSentimentPercentage.push(
            ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
          );
        } else if (
          selectionList(sentimentVal) === "positive" ||
          selectionList(sentimentVal) === "negative" ||
          selectionList(sentimentVal) === "neutral"
        ) {
          agentSentimentPercentage.push(
            ((data / totalAgentSentimentAggregation) * 100).toFixed(2)
          );
        }
      }
    });
    customerSentimentAggregation["data"].forEach((data) => {
      totalCustomerSentimentAggregation += data;
    });

    customerSentimentAggregation["data"].forEach((data) => {
      if (
        selectionList(sentimentVal) === undefined ||
        (selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral")
      ) {
        customerSentimentPercentage.push(
          ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
        );
      } else {
        if (
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "positive" &&
          selectionList(sentimentVal) !== "neutral" &&
          selectionList(sentimentVal) !== "negative" &&
          selectionList(sentimentVal) !== "neutral"
        ) {
          customerSentimentPercentage.push(
            ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
          );
        } else if (
          selectionList(sentimentVal) === "positive" ||
          selectionList(sentimentVal) === "negative" ||
          selectionList(sentimentVal) === "neutral"
        ) {
          customerSentimentPercentage.push(
            ((data / totalCustomerSentimentAggregation) * 100).toFixed(2)
          );
        }
      }
    });
    const sentimentOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value + " %";
          },
        },
        title: {
          display: true,
          text: "Sentiment Overview",
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            sentimentDrill.includes(
              agentSentimentAggregation["labels"][el[0].index]
            )
          ) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentVal,
              sentimentDrill,
              agentVal,
              dispositionVal
            );
          } else {
            sentimentDrill.push(
              agentSentimentAggregation["labels"][el[0].index]
            );
            fetchDrillDownData(
              startDate,
              endDate,
              intentVal,
              sentimentDrill,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal
          );
        }
      },
    };
    const sentimentData = {
      datasets: [
        {
          data: agentSentimentPercentage.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Sentiment",
        },
        {
          data: customerSentimentPercentage.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Sentiment",
        },
      ],
      labels: customerSentimentAggregation["labels"].slice(0, 12),
    };
    const participationOptions = {
      plugins: {
        title: {
          display: true,
          text: "Participation Distribution",
        },
        datalabels: {
          enabled: true,
          display: true,
          color: "black",
          align: "end",
          // font: {
          //   weight: "bold",
          // },
        },
      },
      responsive: true,
      scales: {
        x: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentDrill.includes(
              agentParticipationAggregationLabelPer[el[0].index]
            )
          ) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(
              agentParticipationAggregationLabelPer[el[0].index]
            );
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal
          );
        }
      },
    };
    const agentParticipationAggregation = findName(
      "Agent Participation By Intent",
      jsonData
    );
    const customerParticipationAggregation = findName(
      "Avg Customer Participation Per Intent"
    );
    let agentParticipationAggregationLabelPer = [];
    let agentParticipationAggregationDataPer = [];
    let agentParticipationAggregationObj = {};
    agentParticipationAggregation["labels"].forEach((element, index) => {
      agentParticipationAggregationObj[element] =
        agentParticipationAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentParticipationAggregationObj["empathy"];
        delete agentParticipationAggregationObj["repeat_call"];
        delete agentParticipationAggregationObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete agentParticipationAggregationObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentParticipationAggregationObj["empathy"];
        delete agentParticipationAggregationObj["repeat_call"];
        delete agentParticipationAggregationObj["escalation"];
      }
    }
    Object.entries(agentParticipationAggregationObj).forEach(([label, val]) => {
      agentParticipationAggregationLabelPer.push(label);
      agentParticipationAggregationDataPer.push(val.toFixed(2));
    });
    let customerParticipationAggregationLabelPer = [];
    let customerParticipationAggregationDataPer = [];
    let customerParticipationAggregationObj = {};
    customerParticipationAggregation["labels"].forEach((element, index) => {
      customerParticipationAggregationObj[element] =
        customerParticipationAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete customerParticipationAggregationObj["empathy"];
        delete customerParticipationAggregationObj["repeat_call"];
        delete customerParticipationAggregationObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete customerParticipationAggregationObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete customerParticipationAggregationObj["empathy"];
        delete customerParticipationAggregationObj["repeat_call"];
        delete customerParticipationAggregationObj["escalation"];
      }
    }
    Object.entries(customerParticipationAggregationObj).forEach(
      ([label, val]) => {
        customerParticipationAggregationLabelPer.push(label);
        customerParticipationAggregationDataPer.push(val.toFixed(2));
      }
    );
    const participationData = {
      datasets: [
        {
          data: agentParticipationAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Participation Ratio",
        },
        {
          data: customerParticipationAggregationDataPer.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Participation Ratio",
        },
      ],
      labels: agentParticipationAggregationLabelPer.slice(0, 12),
    };
    const interruptOptions = {
      plugins: {
        title: {
          display: true,
          text: "Interrupts Distribution",
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          formatter: function (value) {
            return value;
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentDrill.includes(
              agentInterruptsAggregationLabelPer[el[0].index]
            )
          ) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(agentInterruptsAggregationLabelPer[el[0].index]);
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal
          );
        }
      },
    };
    const agentInterruptsAggregation = findName(
      "Agent Interrupts By Intents",
      jsonData
    );
    const customerInterruptsAggregation = findName(
      "Customer Interrupts By Intents",
      jsonData
    );
    let agentInterruptsAggregationLabelPer = [];
    let agentInterruptsAggregationDataPer = [];
    let agentInterruptsAggregationObj = {};
    agentInterruptsAggregation["labels"].forEach((element, index) => {
      agentInterruptsAggregationObj[element] =
        agentInterruptsAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      // retrievalLabelPer =
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentInterruptsAggregationObj["empathy"];
        delete agentInterruptsAggregationObj["repeat_call"];
        delete agentInterruptsAggregationObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete agentInterruptsAggregationObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentInterruptsAggregationObj["empathy"];
        delete agentInterruptsAggregationObj["repeat_call"];
        delete agentInterruptsAggregationObj["escalation"];
      }
    }
    Object.entries(agentInterruptsAggregationObj).forEach(([label, val]) => {
      agentInterruptsAggregationLabelPer.push(label);
      agentInterruptsAggregationDataPer.push(val.toFixed(2));
    });
    let customerInterruptsAggregationLabelPer = [];
    let customerInterruptsAggregationDataPer = [];
    let customerInterruptsAggregationObj = {};
    customerInterruptsAggregation["labels"].forEach((element, index) => {
      customerInterruptsAggregationObj[element] =
        agentInterruptsAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      // retrievalLabelPer =
    } else {
      intentVal.forEach((data) => {
        delete customerInterruptsAggregationObj[data["label"]];
      });
    }
    Object.entries(customerInterruptsAggregationObj).forEach(([label, val]) => {
      customerInterruptsAggregationLabelPer.push(label);
      customerInterruptsAggregationDataPer.push(val.toFixed(2));
    });
    const interruptData = {
      datasets: [
        {
          data: agentInterruptsAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Interrupts",
        },
        {
          data: customerInterruptsAggregationDataPer.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Interrupts",
        },
      ],
      labels: agentInterruptsAggregationLabelPer.slice(0, 12),
    };
    const agentNegativityAggregation = findName(
      "Agent Negativity By Intent",
      jsonData
    );
    const customerNegativityAggregation = findName(
      "Customer Negativity By Intent",
      jsonData
    );

    let agentNegativityAggregationLabelPer = [];
    let agentNegativityAggregationDataPer = [];
    let agentNegativityAggregationObj = {};
    agentNegativityAggregation["labels"].forEach((element, index) => {
      agentNegativityAggregationObj[element] =
        agentNegativityAggregation["data"][index];
    });
    if (intentVal === undefined || intentVal.length <= 0) {
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentNegativityAggregationObj["empathy"];
        delete agentNegativityAggregationObj["repeat_call"];
        delete agentNegativityAggregationObj["escalation"];
      }
    } else {
      intentVal.forEach((data) => {
        delete agentNegativityAggregationObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete agentNegativityAggregationObj["empathy"];
        delete agentNegativityAggregationObj["repeat_call"];
        delete agentNegativityAggregationObj["escalation"];
      }
    }
    Object.entries(agentNegativityAggregationObj).forEach(([label, val]) => {
      // console.log("Value is (((((((((((",val);
      agentNegativityAggregationLabelPer.push(label);
      agentNegativityAggregationDataPer.push(
        ((val / agentSentimentAggregationObj["negative"]) * 100).toFixed(1)
      );
    });
    let customerNegativityAggregationLabelPer = [];
    let customerNegativityAggregationDataPer = [];
    let customerNegativityAggregationObj = {};
    customerNegativityAggregation["labels"].forEach((element, index) => {
      customerNegativityAggregationObj[element] =
        customerNegativityAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      // retrievalLabelPer =
    } else {
      intentVal.forEach((data) => {
        delete customerNegativityAggregationObj[data["label"]];
      });
    }
    Object.entries(customerNegativityAggregationObj).forEach(([label, val]) => {
      customerNegativityAggregationLabelPer.push(label);
      customerNegativityAggregationDataPer.push(
        ((val / customerSentimentAggregationObj["negative"]) * 100).toFixed(1)
      );
    });
    const negativityOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value;
          },
        },
        title: {
          display: true,
          text: "Negative Sentiment Distribution",
        },
      },
      responsive: true,
      scales: {
        x: {
          ticks: {
            callback: function (value) {
              return this.getLabelForValue(value).length > 15
                ? this.getLabelForValue(value).substr(0, 15) + "..."
                : this.getLabelForValue(value);
            },
          },
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (
            intentDrill.includes(
              agentNegativityAggregationLabelPer[el[0].index]
            )
          ) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          } else {
            intentDrill.push(agentNegativityAggregationLabelPer[el[0].index]);
            fetchDrillDownData(
              startDate,
              endDate,
              intentDrill,
              sentimentVal,
              agentVal,
              dispositionVal
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal
          );
        }
      },
    };
    const negativityData = {
      datasets: [
        {
          data: agentNegativityAggregationDataPer.slice(0, 12),
          backgroundColor: "#01FF84",
          label: "Agent Negative Sentiment in %",
          stack: 1,
        },
        {
          data: customerNegativityAggregationDataPer.slice(0, 12),
          backgroundColor: "#008DFF",
          label: "Customer Negative Sentiment in %",
          stack: 2,
        },
      ],
      labels: customerNegativityAggregationLabelPer.slice(0, 12),
    };
    const callTimeAggregation = findName("Avg Call Per Intent", jsonData);

    let callTimeLabelPer = [];
    let callTimeDataPer = [];
    let callTimeObj = {};
    callTimeAggregation["labels"].forEach((element, index) => {
      callTimeObj[element] = callTimeAggregation["data"][index];
    });

    if (intentVal === undefined || intentVal.length <= 0) {
      // retrievalLabelPer =
      delete callTimeObj["empathy"];
      delete callTimeObj["repeat_call"];
      delete callTimeObj["escalation"];
    } else {
      intentVal.forEach((data) => {
        delete callTimeObj[data["label"]];
      });
      if (window.localStorage.getItem("user") === "techm_adani") {
        delete callTimeObj["empathy"];
        delete callTimeObj["repeat_call"];
        delete callTimeObj["escalation"];
      }
    }
    Object.entries(callTimeObj).forEach(([label, val]) => {
      callTimeLabelPer.push(label);
      callTimeDataPer.push(val.toFixed(2));
    });

    const callTimeData = {
      datasets: [
        {
          data: callTimeDataPer.slice(0, 12),
          backgroundColor: "#008DFF",
          // label: "Average Call Duration (Seconds)"
        },
      ],
      labels: callTimeLabelPer.slice(0, 12),
    };
    const aggGender = jsonData.Male + jsonData.Female;
    let aggGenderMaleData = 0;
    let aggGenderFemaleData = 0;
    if (aggGender !== 0) {
      aggGenderMaleData = ((jsonData.Male / aggGender) * 100).toFixed(2);
      aggGenderFemaleData = ((jsonData.Female / aggGender) * 100).toFixed(2);
    }
    const genderData = {
      labels: ["Male", "Female"],
      datasets: [
        {
          label: "Gender",
          data: [aggGenderMaleData, aggGenderFemaleData],
          backgroundColor: ["#008DFF", "#01FF84"],
          borderColor: ["#008DFF", "#01FF84"],
          borderWidth: 1,
        },
      ],
    };
    const noisyData = {
      labels: ["Noisy Calls (%)"],
      datasets: [
        {
          label: "Noisy",
          data: [noisyCallsDataPercent, 100 - noisyCallsDataPercent],
          backgroundColor: ["#008DFF", "#f2f4f2"],
          borderColor: ["#008DFF", "#f2f4f2"],
          borderWidth: 1,
        },
      ],
    };
    const volumeData = {
      labels: ["Low Volume Calls (%)"],
      datasets: [
        {
          label: "Volume",
          data: [loudCallsDataPercent, 100 - loudCallsDataPercent],
          backgroundColor: ["#008DFF", "#f2f4f2"],
          borderColor: ["#008DFF", "#f2f4f2"],
          borderWidth: 1,
        },
      ],
    };
    const doughnutOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value, context) {
            return value + "%";
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            color: "rgb(38, 31, 98)",
          },
        },
      },
      onClick: (e, el) => {
        if (el.length > 0) {
          if (genderDrill.includes(gender[el[0].index].value)) {
            fetchDrillDownData(
              startDate,
              endDate,
              intentVal,
              sentimentVal,
              agentVal,
              dispositionVal,
              genderDrill
            );
          } else {
            if (el[0].index === 1) {
              genderDrill.push("female");
            } else {
              if (el[0].index === 0) {
                genderDrill.push("male");
              }
            }
            fetchDrillDownData(
              startDate,
              endDate,
              intentVal,
              sentimentVal,
              agentVal,
              dispositionVal,
              genderDrill
            );
          }
        } else {
          fetchDashboardData(
            startDate,
            endDate,
            intentVal,
            sentimentVal,
            agentVal,
            dispositionVal,
            genderVal
          );
        }
      },
    };
    const noisyOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          formatter: function (value) {
            return value + "%";
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            color: "rgb(38, 31, 98)",
          },
        },
      },
    };
    const volumeOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          // align: "end",
          // font: {
          //   weight: 'bold'
          // }
          formatter: function (value) {
            return value + "%";
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            color: "rgb(38, 31, 98)",
          },
        },
      },
    };
    //add all defined variables into one object
    return {
      noisyCallsDataPercent: noisyCallsDataPercent,
      loudCallsDataPercent: loudCallsDataPercent,
      intentData: intentData,
      campaignOptions: campaignOptions,
      dispositionOptions: dispositionOptions,
      customerTranscriptOptions: customerTranscriptOptions,
      agentDataOptions: agentDataOptions,
      intentOptions: intentOptions,
      retrievalData: retrievalData,
      retrievalOptions: retrievalOptions,
      callTimeOptions: callTimeOptions,
      negativeOptions: negativeOptions,
      sentimentData: sentimentData,
      sentimentOptions: sentimentOptions,
      participationData: participationData,
      participationOptions: participationOptions,
      interruptData: interruptData,
      interruptOptions: interruptOptions,
      negativityData: negativityData,
      negativityOptions: negativityOptions,
      callTimeData: callTimeData,
      genderData: genderData,
      noisyData: noisyData,
      volumeData: volumeData,
      doughnutOptions: doughnutOptions,
      noisyOptions: noisyOptions,
      volumeOptions: volumeOptions,
    };
  };
  const getIntentData = (intent) => {
    let intentArray = [];
    for (const element of intent) {
      intentArray.push({ label: element, value: element });
    }
    return intentArray;
  };
  const getSearchData = (intent) => {
    let searchArray = [];
    for (const element of intent) {
      if (element.trim() !== "") {
        searchArray.push({ label: element, value: element });
        // searchArray.push({ label: element.length > 40 ? element.substr(0, 40) + "...." : element, value: element.length > 40 ? element.substr(0, 40) + "...." : element });
      }
    }
    return searchArray;
  };
  const selectionList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];
    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele["value"]);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };
  const selectedDrillList = (arrOfObj) => {
    let selectionString = "";
    let selectionArr = [];

    if (arrOfObj !== undefined && arrOfObj.length > 0) {
      for (const ele of arrOfObj) {
        selectionArr.push(ele);
      }
      selectionString = String(selectionArr);
    } else {
      selectionString = "0";
    }
    return selectionString;
  };

  const setIntentArrays = (
    agentLabelArr,
    custLabelArr,
    agentDataArr,
    custDataArr
  ) => {
    const intentLabelArr1 = agentLabelArr.concat(
      custLabelArr.filter((item) => agentLabelArr.indexOf(item) < 0)
    );
    const intentFinalData1 = [];
    const intentFinallabel1 = [];
    const customerObj1 = {};
    const agentObj1 = {};
    custLabelArr.forEach((element, index) => {
      customerObj1[element] = custDataArr[index];
    });
    agentLabelArr.forEach((element, index) => {
      agentObj1[element] = agentDataArr[index];
    });

    Object.entries(customerObj1).forEach(([custKey, custValue]) => {
      Object.entries(agentObj1).forEach(([agentKey, agentValue]) => {
        if (custKey === agentKey) {
          intentFinalData1.push(Number(custValue) + Number(agentValue));
          intentFinallabel1.push(custKey);
        }
      });
    });
    let customerNotMatch1 = [];
    let agentNotMatch1 = [];
    intentLabelArr1.map((i) => {
      if (!agentLabelArr.includes(i)) {
        agentNotMatch1.push(i);
      }
      if (!custLabelArr.includes(i)) {
        customerNotMatch1.push(i);
      }
    });
    agentNotMatch1.forEach((element) => {
      intentFinallabel1.push(custLabelArr[custLabelArr.indexOf(element)]);
      intentFinalData1.push(custDataArr[custLabelArr.indexOf(element)]);
    });
    customerNotMatch1.forEach((element) => {
      intentFinallabel1.push(agentLabelArr[agentLabelArr.indexOf(element)]);
      intentFinalData1.push(agentDataArr[agentLabelArr.indexOf(element)]);
    });
    const finalObj = {};
    intentFinallabel1.forEach((element, index) => {
      finalObj[element] = intentFinalData1[index];
    });
    return finalObj;
  };

  const handleChange = (event, newValue) => {
    // console.log("Tabs value", newValue);
    setValue(newValue);
  };
  const [vocReasonFilter, setVocReasonFilter] = useState([]);
  const [vocCallTypeFilter, setVocCallTypeFilter] = useState([]);
  const [vocCampaignFilter, setVocCampaignFilter] = useState([]);
  const [selectedReasonFilter, setSelectedReasonFilter] = useState([]);
  const [selectedTrxReasonFilter, setSelectedTrxReasonFilter] = useState([]);
  const [selectedTrxSentimentFilter, setSelectedTrxSentimentFilter] = useState(
    []
  );
  const [selectedTrxCallTypeFilter, setSelectedTrxCallTypeFilter] = useState(
    []
  );
  const [selectedTrxCampaignFilter, setSelectedTrxCampaignFilter] = useState(
    []
  );
  const [selectedSentimentFilter, setSelectedSentimentFilter] = useState([]);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState([]);
  const [selectedSummaryCampaignFilter, setSelectedSummaryCampaignFilter] =
    useState([]);
  const [vocFromDate, setVocFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [vocToDate, setVocTODate] = useState(moment().format("YYYY-MM-DD"));
  const handleVocDates = (e, name) => {
    if (name === "startDate") {
      setVocFromDate(String(moment(e).format("YYYY-MM-DD")));
    } else {
      setVocTODate(String(moment(e).format("YYYY-MM-DD")));
    }
  };

  const handleVOCfilter = (e, name) => {
    if (name === "reason") {
      setSelectedReasonFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedCampaignFilter(e.target.value);
    } else {
      setSelectedSentimentFilter(e.target.value);
    }
  };

  const handleVOCTranscriptFilter = (e, name) => {
    if (name === "reason") {
      setSelectedTrxReasonFilter(e.target.value);
    } else if (name === "sentiment") {
      setSelectedTrxSentimentFilter(e.target.value);
    } else if (name === "call-type") {
      setSelectedTrxCallTypeFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedTrxCampaignFilter(e.target.value);
    }
  };

  const handleVOCSummaryFilter = (e, name) => {
    if ((name = "campaign")) {
      setSelectedSummaryCampaignFilter(e.target.value);
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {
      VOCfilter();
    }, []);
  }
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {
      vocdownloadFilter();
    }, []);
  }
  const vocFilter = process.env.REACT_APP_BASE_URL + "/VOCFilter";
  const vocDataURL = process.env.REACT_APP_BASE_URL + "/getVoiceBotVocData";
  const vocWordCluster = process.env.REACT_APP_BASE_URL + "/api/wordCloud";
  const vocTranscriptFilter =
    process.env.REACT_APP_BASE_URL + "/vocDashBoardReportFilters";

  const VOCfilter = async () => {
    try {
      const filterData = await axios.get(vocFilter);
      if (!_.isEmpty(filterData)) {
        let formatedData = convertToSelectBoxFormat(filterData.data);
        if (!_.isEmpty(formatedData)) {
          setVocReasonFilter(formatedData);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };

  const vocdownloadFilter = async () => {
    try {
      const filterResponse = await axios.get(vocTranscriptFilter);
      if (!_.isEmpty(filterResponse)) {
        let formatedReaonTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.reason_types
        );
        if (!_.isEmpty(formatedReaonTypes)) {
          setVocReasonFilter(formatedReaonTypes);
        }
        let formatedCallTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.call_types
        );
        if (!_.isEmpty(formatedCallTypes)) {
          setVocCallTypeFilter(formatedCallTypes);
        }
        let formatedCampaigns = convertToSelectBoxFormat(
          filterResponse.data.aggregations.campaigns
        );
        if (!_.isEmpty(formatedCampaigns)) {
          setVocCampaignFilter(formatedCampaigns);
          setVOCCampaignFieldFilters(formatedCampaigns);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };

  let stopWordsArr = [
    "customer",
    "order",
    "receive",
    "product",
    "item",
    "issue",
    "deliver",
    "delivery",
    "complaint",
    "complain",
    "bigbasket",
    "website",
    "face",
  ];
  const getWordCloudImg = async (sentences) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // console.log(sentences);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sentences,
        width: "1150",
        height: "525",
        background_color: "white",
        stopwords: stopWordsArr,
        // min_font_size: 20,
      }),
      redirect: "follow",
    };

    await fetch(vocWordCluster, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        console.log(result);
        setWordClusterData(URL.createObjectURL(result));
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
  };

  const handleVocData = async () => {
    setIsload(true);
    let params = {};
    if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        sentiment: String(selectedSentimentFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        campaign: String(selectedCampaignFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        sentiment: String(selectedSentimentFilter),
        campaign: String(selectedCampaignFilter),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        sentiment: String(selectedSentimentFilter),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        campaign: String(selectedCampaignFilter),
      };
    } else {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        sentiment: String(selectedSentimentFilter),
        campaign: String(selectedCampaignFilter),
      };
    }

    await axios
      .get(vocDataURL, { params })
      .then((response) => {
        if (!_.isEmpty(response)) {
          const bardata1 =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.languages_spoken.buckets;
          const bardata2 =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.reason_types.buckets;

          const vocPieChart =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.sentiment_dist.buckets;

          const wordCloudSentences =
            response &&
            response.data &&
            response.data.hits &&
            response.data.hits.hits &&
            response.data.hits.hits.map((item) =>
              item._source &&
              item._source.summary_report &&
              item._source.summary_report.summary_info &&
              item._source.summary_report.summary_info.reason_for_uninstall &&
              item._source.summary_report.summary_info.reason_for_uninstall
                .reason &&
              item._source.summary_report.summary_info.reason_for_uninstall
                .reason.description &&
              !_.isUndefined(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              ) &&
              !_.isNil(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              ) &&
              !_.isNull(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              )
                ? item._source.summary_report.summary_info.reason_for_uninstall.reason.description.join(
                    ", "
                  )
                : " "
            );
          // const wordCloudSentence =

          // response &&
          // response.data &&
          // response.data.hits &&
          // response.data.hits.hits &&
          // response.data.hits.hits.map((item) =>
          //   item._source &&
          //   item._source.summary_report &&
          //   item._source.summary_report.summary_info &&
          //   !_.isUndefined(
          //     item._source.summary_report.summary_info.issues_details
          //   ) &&
          //   !_.isNil(
          //     item._source.summary_report.summary_info.issues_details
          //   ) &&
          //   !_.isNull(
          //     item._source.summary_report.summary_info.issues_details
          //   )&&
          //   _.isString(item._source.summary_report.summary_info.issues_details)
          //     ? item._source.summary_report.summary_info.issues_details
          //     : " "
          // );
          if (!_.isEmpty(bardata1)) {
            setvocBarGraphData(convertToBarGraphFormat(bardata1));
          } else {
            setvocBarGraphData([]);
          }
          if (!_.isEmpty(bardata2)) {
            setvocBarGraphData2(convertToBarGraphFormat(bardata2));
          } else {
            setvocBarGraphData2([]);
          }
          if (!_.isEmpty(vocPieChart)) {
            setPieChartData(vocPieChart);
          } else {
            setPieChartData([]);
          }
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
        }
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
    setIsload(false);
  };

  const handleTabClose = (tab) => {
    var index = intentDrill.indexOf(tab);
    // delete intentDrill[index];
    for (var i = 0; i < intentDrill.length; i++) {
      if (intentDrill[i] === intentDrill[index]) {
        intentDrill.splice(i, 1);
      }
    }
    if (intentDrill.length > 0) {
      fetchDrillDownData(
        startDate,
        endDate,
        intentDrill,
        sentimentVal,
        agentVal,
        dispositionVal
      );
    } else {
      fetchDashboardData(
        startDate,
        endDate,
        intentVal,
        sentimentVal,
        agentVal,
        dispositionVal
      );
    }
  };
  const handleTabCloseSentiment = (tab) => {
    var index = sentimentDrill.indexOf(tab);
    // delete sentimentDrill[index];
    for (var i = 0; i < sentimentDrill.length; i++) {
      if (sentimentDrill[i] === sentimentDrill[index]) {
        sentimentDrill.splice(i, 1);
      }
    }
    if (sentimentDrill.length > 0) {
      fetchDrillDownData(
        startDate,
        endDate,
        intentVal,
        sentimentDrill,
        agentVal,
        dispositionVal
      );
    } else {
      fetchDashboardData(
        startDate,
        endDate,
        intentVal,
        sentimentVal,
        agentVal,
        dispositionVal
      );
    }
  };
  const handleChatTabClose = (tab) => {
    var index = intentChatDrill.indexOf(tab);
    // delete intentChatDrill[index];
    for (var i = 0; i < intentChatDrill.length; i++) {
      if (intentChatDrill[i] === intentChatDrill[index]) {
        intentChatDrill.splice(i, 1);
      }
    }
    if (intentChatDrill.length > 0) {
      fetchChatDrillDownData(
        startDateChat,
        endDateChat,
        channelVal,
        sentimentChatVal,
        intentChatDrill
      );
    } else {
      fetchChatData(
        startDateChat,
        endDateChat,
        channelVal,
        sentimentChatVal,
        intentChatVal
      );
    }
  };
  const handleChatTabCloseSentiment = (tab) => {
    var index = sentimentChatDrill.indexOf(tab);
    // delete sentimentChatDrill[index];
    for (var i = 0; i < sentimentChatDrill.length; i++) {
      if (sentimentChatDrill[i] === sentimentChatDrill[index]) {
        sentimentChatDrill.splice(i, 1);
      }
    }
    if (sentimentChatDrill.length > 0) {
      fetchChatDrillDownData(
        startDateChat,
        endDateChat,
        channelVal,
        sentimentChatDrill,
        intentChatVal
      );
    } else {
      fetchChatData(
        startDateChat,
        endDateChat,
        channelVal,
        sentimentChatVal,
        intentChatVal
      );
    }
  };
  const handleTabCloseGender = (tab) => {
    var index = genderDrill.indexOf(tab);
    // delete genderDrill[index];
    for (var i = 0; i < genderDrill.length; i++) {
      if (genderDrill[i] === genderDrill[index]) {
        genderDrill.splice(i, 1);
      }
    }
    if (genderDrill.length > 0) {
      fetchDrillDownData(
        startDate,
        endDate,
        intentVal,
        sentimentVal,
        agentVal,
        dispositionVal,
        genderDrill
      );
    } else {
      fetchDashboardData(
        startDate,
        endDate,
        intentVal,
        sentimentVal,
        agentVal,
        dispositionVal,
        campaignValInCalls,
        genderVal
      );
    }
  };
  const fetchDashboardData = async (
    startDate,
    endDate,
    intent,
    sentiment,
    agent,
    disposition,
    genderVal
  ) => {
    setIsload(!load);

    if (startDate) {
      const response = await axios.get(elasticDataUrl, {
        params: {
          startDate: startDate,
          endDate: endDate,
          intent: selectionList(intent),
          sentiment: selectionList(sentiment),
          agent: selectionList(agent),
          disposition: selectionList(disposition),
          campaignValInCalls: selectionList(campaignValInCalls),
          genderVal: selectionList(genderVal),
        },
      });

      setJsonData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataUrl);
      setJsonData(response.data);
      setIsload(false);
    }
  };

  const fetchChatData = async (
    startDateChat,
    endDateChat,
    channelVal,
    sentimentChatVal,
    intentChatVal,
    channelChatVal
  ) => {
    setIsload(!load);

    if (startDateChat) {
      const chatResponse = await axios.get(elasticChatUrl, {
        params: {
          startDateChat: startDateChat,
          endDateChat: endDateChat,
          channelVal: selectionList(channelVal),
          sentimentChatVal: selectionList(sentimentChatVal),
          intentChatVal: selectionList(intentChatVal),
          channelChatVal: selectionList(channelChatVal),
        },
      });
      setJsonChatData(chatResponse.data);
      setIsload(false);
    } else {
      const chatResponse = await Axios(elasticChatUrl);
      setJsonChatData(chatResponse.data);
      setIsload(false);
    }
  };

  const fetchAuditData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let params = {
      startDateAudit: startDateAudit,
      endDateAudit: endDateAudit,
      campaignAuditVal: selectionList(campaignAuditVal),
      agentAuditVal: selectionList(agentAuditVal),
      dispositionAuditVal: selectionList(dispositionAuditVal),
      skillAuditVal: selectionList(skillAuditVal),
      scoreInputVal: scoreInputVal ? scoreInputVal : 0,
      scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
      size: size ? size : 5,
      from: from ? from : 0,
    };
    if (startDateAudit) {
      const response = await axios.get(elasticDataAuditUrl, {
        params: params,
      });

      setJsonAuditData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataAuditUrl);
      setJsonAuditData(response.data);
      setIsload(false);
    }
  };
  function transformArray(arr) {
    if (arr.length !== 0) {
      return arr.map((item) => {
        // Split the string by underscores
        const words = item.split("_");
        // Capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        // Join the words with spaces and return the transformed string
        return capitalizedWords && capitalizedWords.join(" ");
      });
    } else {
      return [];
    }
  }

  const fetchOpenSearchAuditData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(openSearchAuditDataUrl, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 25,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonAuditData({});
      }
      setIsload(false);
    } else {
      const response = await Axios(openSearchAuditDataUrl);
      setJsonOpenAuditData(response.data);
      if (_.isEmpty(response.data)) {
        setJsonAuditData({});
      }
      setIsload(false);
    }
  };

  function replaceLanguageNames(inputArray) {
    // Create an output array by mapping over the input array
    if (
      _.isArray(inputArray) &&
      !_.isEmpty(inputArray) &&
      !_.isNull(inputArray) &&
      !_.isUndefined(inputArray)
    ) {
      const outputArray = inputArray
        .map((inputItem) => {
          const matchingLang = langVariant.find(
            (langItem) => langItem.label === inputItem.value
          );
          if (matchingLang) {
            return { label: matchingLang.value, value: matchingLang.value };
          } else {
            return null; // Handle cases where no match is found
          }
        })
        .filter((item) => item !== null);
      return outputArray;
    } else {
      return [];
    }
  }

  const fetchOpenSearchAuditDataCallBack = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    let langVal =
      !_.isEmpty(languageAuditVal) &&
      !_.isNull(languageAuditVal) &&
      !_.isEqual("0")
        ? replaceLanguageNames(languageAuditVal)
        : [];
    let langSelect = selectionList(langVal);
    if (startDateAudit) {
      const response = await axios.get(openSearchAuditDataUrl, {
        params: {
          startDateAudit: startDateAudit,
          endDateAudit: endDateAudit,
          campaignAuditVal: campaignAuditVal,
          agentAuditVal: agentAuditVal,
          dispositionAuditVal: dispositionAuditVal,
          languageAuditVal: langSelect,
          skillAuditVal: skillAuditVal,
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 25,
          from: from ? from : 0,
        },
      });
      setJsonOpenAuditData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchAuditDataUrl);
      setJsonOpenAuditData(response.data);
      setIsload(false);
    }
  };

  const fetchOpenCdrData = async (
    startDateCDR,
    endDateCDR,
    campaignCDRVal,
    callerCDRVal,
    agentCDRVal,
    dispositionCDRVal,
    locationCDRVal,
    skillCDRVal,
    statusCDRVal,
    phoneNameCDRVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCdrData([]);
    if (startDate) {
      const response = await axios.get(openDataCDRUrl, {
        params: {
          startDate: String(startDateCDR),
          endDate: String(endDateCDR),
          campaignVal: String(selectionList(campaignCDRVal)),
          callerVal: !_.isEmpty(callerCDRVal) ? String(callerCDRVal) : 0,
          agentVal: String(selectionList(agentCDRVal)),
          dispositionVal: String(selectionList(dispositionCDRVal)),
          locationVal: String(selectionList(locationCDRVal)),
          skillVal: String(selectionList(skillCDRVal)),
          statusVal: String(selectionList(statusCDRVal)),
          phoneVal: String(selectionList(phoneNameCDRVal)),
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCdrData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openDataCDRUrl);
      setJsonOpenCdrData(response.data);
      setIsload(false);
    }
  };
  if (window.localStorage.getItem("user") === "mindtree") {
    useEffect(() => {}, [jsonOpenCdrData]);
  }

  const fetchOpenCdrDataCallBack = async (
    startDateCDR,
    endDateCDR,
    campaignCDRVal,
    callerCDRVal,
    agentCDRVal,
    dispositionCDRVal,
    locationCDRVal,
    skillCDRVal,
    statusCDRVal,
    phoneNameCDRVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCdrData([]);
    if (startDate) {
      const response = await axios.get(openDataCDRUrl, {
        params: {
          startDate: startDateCDR,
          endDate: endDateCDR,
          campaignVal: campaignCDRVal,
          callerVal: !_.isEmpty(callerCDRVal) ? callerCDRVal : 0,
          agentVal: agentCDRVal,
          dispositionVal: dispositionCDRVal,
          locationVal: locationCDRVal,
          skillVal: skillCDRVal,
          statusVal: statusCDRVal,
          phoneVal: phoneNameCDRVal,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCdrData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openDataCDRUrl);
      setJsonOpenCdrData(response.data);
      setIsload(false);
    }
  };

  const fetchOpenSearchCallsData = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    languageAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCallsData([]);
    let langVal =
      !_.isEmpty(languageAuditVal) && !_.isNull(languageAuditVal)
        ? replaceLanguageNames(languageAuditVal)
        : [];
    if (startDateAudit) {
      const response = await axios.get(openSearchCallsDataUrl, {
        params: {
          startDateAudit: String(startDateAudit),
          endDateAudit: String(endDateAudit),
          campaignAuditVal: selectionList(campaignAuditVal),
          agentAuditVal: selectionList(agentAuditVal),
          dispositionAuditVal: selectionList(dispositionAuditVal),
          languageAuditVal: selectionList(langVal),
          skillAuditVal: selectionList(skillAuditVal),
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCallsData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchCallsDataUrl);
      setJsonOpenCallsData(response.data);
      setIsload(false);
    }
  };
  if (window.localStorage.getItem("user") === "sobha_limited") {
    useEffect(() => {}, [jsonOpenCallsData]);
  }
  const fetchOpenSearchCallsDataCallBack = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    languageAuditVal,
    dispositionAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);
    setJsonOpenCallsData([]);
    let langVal =
      !_.isEmpty(languageAuditVal) &&
      !_.isNull(languageAuditVal) &&
      !_.isEqual("0")
        ? replaceLanguageNames(languageAuditVal)
        : [];
    let langSelect = selectionList(langVal);
    if (startDateAudit) {
      const response = await axios.get(openSearchCallsDataUrl, {
        params: {
          startDateAudit: startDateAudit,
          endDateAudit: endDateAudit,
          campaignAuditVal: campaignAuditVal,
          languageAuditVal: langSelect,
          agentAuditVal: agentAuditVal,
          dispositionAuditVal: dispositionAuditVal,
          skillAuditVal: skillAuditVal,
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 50,
          from: from ? from : 0,
        },
      });
      setJsonOpenCallsData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(openSearchCallsDataUrl);
      setJsonOpenCallsData(response.data);
      setIsload(false);
    }
  };

  const fetchAuditDataCallBack = async (
    startDateAudit,
    endDateAudit,
    campaignAuditVal,
    agentAuditVal,
    dispositionAuditVal,
    skillAuditVal,
    scoreInputVal,
    scoreAuditVal,
    size,
    from
  ) => {
    setIsload(!load);

    if (startDateAudit) {
      const response = await axios.get(elasticDataAuditUrl, {
        params: {
          startDateAudit: startDateAudit,
          endDateAudit: endDateAudit,
          campaignAuditVal: campaignAuditVal,
          agentAuditVal: agentAuditVal,
          dispositionAuditVal: dispositionAuditVal,
          skillAuditVal: skillAuditVal,
          scoreInputVal: scoreInputVal ? scoreInputVal : 0,
          scoreAuditVal: scoreAuditVal ? scoreAuditVal : 0,
          size: size ? size : 5,
          from: from ? from : 0,
        },
      });

      setJsonAuditData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataAuditUrl);
      setJsonAuditData(response.data);
      setIsload(false);
    }
  };

  const fetchAuditOutData = async (
    startDateAuditOut,
    endDateAuditOut,
    campaignAuditOutVal,
    agentAuditOutVal,
    dispositionAuditOutVal,
    skillAuditOutVal,
    scoreOutInputVal,
    scoreAuditOutVal,
    size,
    from
  ) => {
    setIsload(!load);

    if (startDateAuditOut) {
      const response = await axios.get(elasticDataAuditOutUrl, {
        params: {
          startDateAuditOut: startDateAuditOut,
          endDateAuditOut: endDateAuditOut,
          campaignAuditOutVal: selectionList(campaignAuditOutVal),
          agentAuditOutVal: selectionList(agentAuditOutVal),
          dispositionAuditOutVal: selectionList(dispositionAuditOutVal),
          skillAuditOutVal: selectionList(skillAuditOutVal),
          scoreOutInputVal: scoreOutInputVal ? scoreOutInputVal : 0,
          scoreAuditOutVal: scoreAuditOutVal ? scoreAuditOutVal : 0,
          size: size ? size : 5,
          from: from ? from : 0,
        },
      });

      setJsonAuditOutData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataAuditOutUrl);
      setJsonAuditOutData(response.data);
      setIsload(false);
    }
  };

  const fetchAuditOutDataCallBack = async (
    startDateAuditOut,
    endDateAuditOut,
    campaignAuditOutVal,
    agentAuditOutVal,
    dispositionAuditOutVal,
    skillAuditOutVal,
    scoreOutInputVal,
    scoreAuditOutVal,
    size,
    from
  ) => {
    setIsload(!load);

    if (startDateAuditOut) {
      const response = await axios.get(elasticDataAuditOutUrl, {
        params: {
          startDateAuditOut: startDateAuditOut,
          endDateAuditOut: endDateAuditOut,
          campaignAuditOutVal: campaignAuditOutVal,
          agentAuditOutVal: agentAuditOutVal,
          dispositionAuditOutVal: dispositionAuditOutVal,
          skillAuditOutVal: skillAuditOutVal,
          scoreOutInputVal: scoreOutInputVal ? scoreOutInputVal : 0,
          scoreAuditOutVal: scoreAuditOutVal ? scoreAuditOutVal : 0,
          size: size ? size : 5,
          from: from ? from : 0,
        },
      });

      setJsonAuditOutData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataAuditOutUrl);
      setJsonAuditOutData(response.data);
      setIsload(false);
    }
  };
  const fetchChatDrillDownData = async (
    startDateChat,
    endDateChat,
    channelVal,
    sentimentChatVal,
    intentChatVal
  ) => {
    setIsload(!load);
    if (startDateChat) {
      const chatResponse = await axios.get(elasticChatUrl, {
        params: {
          startDateChat: startDateChat,
          endDateChat: endDateChat,
          channelVal: selectedDrillList(channelVal),
          sentimentChatVal: selectedDrillList(sentimentChatVal),
          intentChatVal: selectedDrillList(intentChatVal),
        },
      });
      setJsonChatData(chatResponse.data);
      setIsload(false);
    } else {
      const chatResponse = await Axios(elasticChatUrl);
      setJsonChatData(chatResponse.data);
      setIsload(false);
    }
  };
  const fetchDrillDownData = async (
    startDate,
    endDate,
    intent,
    sentiment,
    agent,
    disposition,
    genderVal
  ) => {
    setIsload(true);

    if (startDate) {
      const response = await axios.get(elasticDataUrl, {
        params: {
          startDate: startDate,
          endDate: endDate,
          intent: selectedDrillList(intent),
          sentiment: selectedDrillList(sentiment),
          agent: agent,
          disposition: disposition,
          genderVal: selectedDrillList(genderVal),
        },
      });

      setJsonData(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataUrl);
      setJsonData(response.data);
      setIsload(false);
    }
  };
  const fetchVocSearchTextData = async () => {
    sessionStorage.removeItem("vocsearchData");
    const vocsearch = await Axios(elasticVOCSearchFilterUrl);
    sessionStorage.setItem(
      "vocsearchData",
      JSON.stringify(vocsearch.data.aggregation)
    );
    if (typeof sessionStorage.vocsearchData !== "undefined") {
      vocSearchObj = JSON.parse(sessionStorage.vocsearchData);
    }
    const searchDescriptions = findVocSearch(
      "sterms#reason_description",
      vocSearchObj
    );
    vocSearchDescriptions = JSON.stringify(searchDescriptions["labels"]);
    if (typeof vocSearchDescriptions !== "undefined") {
      vocSearchField = JSON.parse(vocSearchDescriptions);
    }
    const vocSearchArr = getSearchData(vocSearchField);
    setVOCsearchFieldFilters(vocSearchArr);
    return {
      vocSearchFieldFilters: vocSearchFieldFilters,
    };
  };
  const fetchFiltersData = async () => {
    const filters = await Axios(elasticDataFiltersUrl);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filters.data.aggregations)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("Campaign Count", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignOptions(campaignArr);
    const agentFilters = findFilters("Calls Per Agent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentOptions(agentArr);
    const dispositionFilters = findFilters("Disposition Count", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionOptions(dispositionArr);
    const intentFilters = findFilters("Agent Intents", objFilter);
    sessionStorage.setItem(
      "intentFilters",
      JSON.stringify(intentFilters["labels"])
    );
    if (typeof sessionStorage.intentFilters !== "undefined") {
      intentFinalFilter = JSON.parse(sessionStorage.intentFilters);
    }
    const intentArr = getIntentData(intentFinalFilter);
    setIntentOptions(intentArr);
    // if(window.localStorage.getItem("user") != "pizzanova")
    // {
    const searchFilters = findFilters("Search Transcript Filters", objFilter);

    sessionStorage.setItem(
      "searchFilters",
      JSON.stringify(searchFilters["labels"])
    );
    if (typeof sessionStorage.searchFilters !== "undefined") {
      searchFinalFilter = JSON.parse(sessionStorage.searchFilters);
    }
    const searchArr = getSearchData(searchFinalFilter);
    setSearchOptions(searchArr);
    // }
    return {
      agentOptions: agentOptions,
      campaignOptions: campaignOptions,
      dispositionOptions: dispositionOptions,
      intentOptions: intentOptions,
      searchOptions: searchOptions,
    };
  };
  const fetchAuditFiltersData = async () => {
    const filtersAudit = await Axios(elasticAuditFiltersUrl);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersAudit.data.aggregations)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("Audit Campaign Count", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignAuditOptions(campaignArr);
    const agentFilters = findFilters("Audit Calls Per Agent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentAuditOptions(agentArr);

    const dispositionFilters = findFilters(
      "Audit Disposition Count",
      objFilter
    );
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionAuditOptions(dispositionArr);

    const skillFilters = findFilters("Audit Skill Count", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillOptions(skillArr);

    return {
      agentAuditOptions: agentAuditOptions,
      campaignAuditOptions: campaignAuditOptions,
      dispositionAuditOptions: dispositionAuditOptions,
      skillOptions: skillOptions,
    };
  };
  const fetchSobhaAuditFiltersData = async () => {
    const filtersAudit = await Axios(openAuditFiltersUrl);
    // console.log(filtersAudit.data.aggregation);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersAudit.data.aggregation)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("AuditCampaignCount", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignAuditOptions(campaignArr);

    const languageFilters = findFilters("AuditLanguageCount", objFilter);
    sessionStorage.setItem(
      "languageFilters",
      JSON.stringify(languageFilters["labels"])
    );
    if (typeof sessionStorage.languageFilters !== "undefined") {
      languageFinalFilter = JSON.parse(sessionStorage.languageFilters);
    }
    const languageNames = getLanguageNames(languageFinalFilter);
    const languageArr = getIntentData(languageNames);
    setLanguageAuditOptions(languageArr);

    const agentFilters = findFilters("AuditCallsPerAgent", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentAuditOptions(agentArr);

    const dispositionFilters = findFilters("AuditDispositionCount", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionAuditOptions(dispositionArr);

    const skillFilters = findFilters("AuditSkillCount", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillOptions(skillArr);

    return {
      agentAuditOptions: agentAuditOptions,
      campaignAuditOptions: campaignAuditOptions,
      dispositionAuditOptions: dispositionAuditOptions,
      languageOptions: languageAuditOptions,
      skillOptions: skillOptions,
    };
  };

  const fetchMindtreeCDRFiltersData = async () => {
    const filtersCdr = await Axios(openCDRFiltersUrl);
    console.log(filtersCdr.data.aggregation);
    sessionStorage.setItem(
      "filtersData",
      JSON.stringify(filtersCdr.data.aggregation)
    );
    if (typeof sessionStorage.filtersData !== "undefined") {
      objFilter = JSON.parse(sessionStorage.filtersData);
    }
    const campaignFilters = findFilters("CampaignCount", objFilter);
    sessionStorage.setItem(
      "campaignFilters",
      JSON.stringify(campaignFilters["labels"])
    );
    if (typeof sessionStorage.campaignFilters !== "undefined") {
      campaignFinalFilter = JSON.parse(sessionStorage.campaignFilters);
    }
    const campaignArr = getIntentData(campaignFinalFilter);
    setCampaignCDROptions(campaignArr);

    const statusFilters = findFilters("StatusCount", objFilter);
    sessionStorage.setItem(
      "statusFilters",
      JSON.stringify(statusFilters["labels"])
    );
    if (typeof sessionStorage.statusFilters !== "undefined") {
      statusFinalFilter = JSON.parse(sessionStorage.statusFilters);
    }
    const statusArr = getIntentData(statusFinalFilter);
    setStatusCDROptions(statusArr);

    const phoneFilters = findFilters("PhoneCount", objFilter);
    sessionStorage.setItem(
      "phoneFilters",
      JSON.stringify(phoneFilters["labels"])
    );
    if (typeof sessionStorage.phoneFilters !== "undefined") {
      phoneFinalFilter = JSON.parse(sessionStorage.phoneFilters);
    }
    const phoneArr = getIntentData(phoneFinalFilter);
    setPhoneNameCDROptions(phoneArr);

    const agentFilters = findFilters("AgentCount", objFilter);
    sessionStorage.setItem(
      "agentFilters",
      JSON.stringify(agentFilters["labels"])
    );
    if (typeof sessionStorage.agentFilters !== "undefined") {
      agentFinalFilter = JSON.parse(sessionStorage.agentFilters);
    }
    const agentArr = getIntentData(agentFinalFilter);
    setAgentCDROptions(agentArr);

    const locationFilters = findFilters("LocationCount", objFilter);
    sessionStorage.setItem(
      "locationFilters",
      JSON.stringify(locationFilters["labels"])
    );
    if (typeof sessionStorage.locationFilters !== "undefined") {
      locationFinalFilter = JSON.parse(sessionStorage.locationFilters);
    }
    const locationArr = getIntentData(locationFinalFilter);
    setLocationCDROptions(locationArr);

    const dispositionFilters = findFilters("DispositionCount", objFilter);
    sessionStorage.setItem(
      "dispositionFilters",
      JSON.stringify(dispositionFilters["labels"])
    );
    if (typeof sessionStorage.dispositionFilters !== "undefined") {
      dispositionFinalFilter = JSON.parse(sessionStorage.dispositionFilters);
    }
    const dispositionArr = getIntentData(dispositionFinalFilter);
    setDispositionCDROptions(dispositionArr);

    const skillFilters = findFilters("SkillCount", objFilter);
    sessionStorage.setItem(
      "skillFilters",
      JSON.stringify(skillFilters["labels"])
    );
    if (typeof sessionStorage.skillFilters !== "undefined") {
      skillFinalFilter = JSON.parse(sessionStorage.skillFilters);
    }
    const skillArr = getIntentData(skillFinalFilter);
    setSkillCDROptions(skillArr);

    return {
      agentOptions: agentCDROptions,
      campaignOptions: campaignCDROptions,
      dispositionOptions: dispositionCDROptions,
      skillOptions: skillCDROptions,
      statusOptions: statusCDROptions,
      locationOptions: locationCDROptions,
      phoneOptions: phoneNameCDROptions,
    };
  };

  const fetchVocSummariesData = async (
    vocFromDate,
    vocToDate,
    vocMatchInputVal,
    selectedSummaryCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCSummaryUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            keyPhrase: String(vocMatchInputVal),
            campaign: String(selectedSummaryCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCSummaryUrl);
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {}, [jsonVocSummaryData]);
  }
  const fetchVocSummariesDataCallBack = async (
    vocFromDate,
    vocToDate,
    vocMatchInputVal,
    selectedSummaryCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCSummaryUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            keyPhrase: String(vocMatchInputVal),
            campaign: String(selectedSummaryCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCSummaryUrl);
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {
      fetchVocTranscriptData(vocFromDate);
    }, []);
  }

  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {
      fetchVocBillingData(vocFromDate);
    }, []);
  }
  const fetchVocBillingData = async (vocFromDate, vocToDate) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(openBillingDataUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
          },
        });
        if (response) {
          setJsonVocDataBilling(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(openBillingDataUrl);
        if (response) {
          setJsonVocDataBilling(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {}, [jsonVocDataBilling]);
  }
  const fetchVocTranscriptData = async (
    vocFromDate,
    vocToDate,
    selectedTrxReasonFilter,
    selectedTrxSentimentFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(elasticVOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            reasonType: String(selectedTrxReasonFilter),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            campaign: String(selectedTrxCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(elasticVOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {}, [jsonVocDataTranscript]);
  }
  const fetchVocTranscriptDataCallBack = async (
    vocFromDate,
    vocToDate,
    selectedTrxReasonFilter,
    selectedTrxSentimentFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(elasticVOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            reasonType: String(selectedTrxReasonFilter),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            campaign: String(selectedTrxCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(elasticVOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };

  const fetchTranscriptData = async (
    startDateTranscript,
    endDateTranscript,
    intentTranscriptVal,
    sentimentTranscriptVal,
    agentTranscriptionVal,
    campaignVal,
    genderVal,
    dispositionTranscriptVal,
    searchInputVal,
    matchInputVal,
    size,
    from
  ) => {
    setIsload(!load);

    if (startDateTranscript) {
      const response = await axios.get(elasticDataTranscriptUrl, {
        params: {
          startDateTranscript: startDateTranscript,
          endDateTranscript: endDateTranscript,
          intentTranscriptVal: selectionList(intentTranscriptVal),
          sentimentTranscriptVal: selectionList(sentimentTranscriptVal),
          agentTranscriptionVal: selectionList(agentTranscriptionVal),
          campaignVal: selectionList(campaignVal),
          genderVal: selectionList(genderVal),
          dispositionTranscriptVal: selectionList(dispositionTranscriptVal),
          searchInputVal: searchInputVal ? searchInputVal : 0,
          matchInputVal: matchInputVal ? matchInputVal : 0,
          size: size ? size : 5,
          from: from ? from : 0,
        },
      });
      setJsonDataTranscript(response.data);
      setIsload(false);
    } else {
      const response = await Axios(elasticDataTranscriptUrl);
      setJsonDataTranscript(response.data.hits);
      setIsload(false);
    }
  };

  const fetchTranscriptDataCallBack = async (
    startDateTranscript,
    endDateTranscript,
    intentTranscriptVal,
    sentimentTranscriptVal,
    agentTranscriptionVal,
    campaignVal,
    genderVal,
    dispositionTranscriptVal,
    searchInputVal,
    matchInputVal,
    size,
    from
  ) => {
    setIsload(!load);
    try {
      if (startDateTranscript) {
        const response = await axios.get(elasticDataTranscriptUrl, {
          params: {
            startDateTranscript: startDateTranscript,
            endDateTranscript: endDateTranscript,
            intentTranscriptVal: intentTranscriptVal,
            sentimentTranscriptVal: sentimentTranscriptVal,
            agentTranscriptionVal: agentTranscriptionVal,
            campaignVal: campaignVal,
            genderVal: genderVal,
            dispositionTranscriptVal: dispositionTranscriptVal,
            searchInputVal: searchInputVal ? searchInputVal : 0,
            matchInputVal: matchInputVal ? matchInputVal : 0,
            size: size ? size : 5,
            from: from ? from : 0,
          },
        });
        setJsonDataTranscript(response.data);
        setIsload(false);
      } else {
        const response = await Axios(elasticDataTranscriptUrl);
        setJsonDataTranscript(response.data);
        setIsload(false);
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };

  const [didFilterData, setDidFilterData] = useState([]);
  const [voiceBotData, setVoiceBotData] = useState([]);
  const [voiceBotUnidentifiedData, setVoiceBotUnidentifiedData] = useState([]);
  const handleVoiceBotFilter = async () => {
    const filterData = await axios.get(didFilter);
    if (!_.isEmpty(filterData)) {
      let formatedData = convertToSelectBoxFormat(filterData.data);
      if (!_.isEmpty(formatedData)) {
        // setSelectedDidValue(formatedData[0]);
        setDidFilterData(formatedData);
      }
    }
  };
  const convertToSelectBoxFormat = (arrayElement) => {
    if (arrayElement) {
      arrayElement.map((ele) => {
        ele.label = ele.doc_count;
        ele.value = ele.key;
        delete ele.doc_count;
        delete ele.key;
      });
    }
    return arrayElement;
  };
  const convertToBarGraphFormat = (arrayElement) => {
    arrayElement.map((ele) => {
      ele.value = ele.doc_count;
      ele.text = ele.key;
      delete ele.doc_count;
      delete ele.key;
    });
    return arrayElement;
  };
  const handleVoiceBotData = async () => {
    let params = {};

    if (!_.isEmpty(didSelectedValues) || !_.isNull(didSelectedValues)) {
      params = {
        startDate: String(moment(voiceBotDates.startDate).format("YYYY-MM-DD")),
        endDate: String(moment(voiceBotDates.endDate).format("YYYY-MM-DD")),
        did: didSelectedValues.value,
      };
    } else {
      params = {
        startDate: String(moment(voiceBotDates.startDate).format("YYYY-MM-DD")),
        endDate: String(moment(voiceBotDates.endDate).format("YYYY-MM-DD")),
      };
    }

    const response = await axios.get(voiceBotDataURL, { params });
    if (!_.isEmpty(response)) {
      setVoiceBotData(response);
    }
  };
  const handleVoiceBotUnidentifiedData = async () => {
    let params = {};

    if (!_.isEmpty(didSelectedValues) || !_.isNull(didSelectedValues)) {
      params = {
        startDate: String(moment(voiceBotDates.startDate).format("YYYY-MM-DD")),
        endDate: String(moment(voiceBotDates.endDate).format("YYYY-MM-DD")),
        did: didSelectedValues.value,
      };
    } else {
      params = {
        startDate: String(moment(voiceBotDates.startDate).format("YYYY-MM-DD")),
        endDate: String(moment(voiceBotDates.endDate).format("YYYY-MM-DD")),
      };
    }
    const response = await axios.get(voiceBotUnidentifiedDataURL, {
      params,
    });
    if (!_.isEmpty(response)) {
      setVoiceBotUnidentifiedData(response);
    }
  };
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      handleVoiceBotFilter();
      handleVoiceBotData();
      handleVoiceBotUnidentifiedData();
    }, []);
  }
  const handleDidFilterChange = (e) => {
    setSelectedDidValue(e.target.value);
  };
  const handleVoiceBotDateChange = (date, label) => {
    if (label === "fromDate") {
      setVoiceBotDates((prevState) => ({
        ...prevState,
        startDate: date,
      }));
    } else {
      setVoiceBotDates((prevState) => ({
        ...prevState,
        endDate: date,
      }));
    }
  };
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      const fromDateObj = moment(voiceBotDates.startDate, "YYYY-MM-DD");
      const toDateObj = moment(voiceBotDates.endDate, "YYYY-MM-DD");
      if (toDateObj.isSameOrAfter(fromDateObj)) {
        handleVoiceBotData();
        handleVoiceBotUnidentifiedData();
      } else {
        alert("please select valid date");
      }
    }, [voiceBotDates]);
  }
  if (
    window.localStorage.getItem("user") !== "sobha_limited" &&
    window.localStorage.getItem("user") !== "mindtree" &&
    window.localStorage.getItem("user") !== "bigbasket_voc"
  ) {
    useEffect(() => {
      handleVoiceBotData();
      handleVoiceBotUnidentifiedData();
    }, [didSelectedValues]);
  }
  const PieChart = ({ data }) => {
    // Extracting labels and values from the data
    const labels = data.map((item) => item.key);
    const values = data.map((item) => item.doc_count);
    const filteredLabels = labels.filter((label) => label.trim() !== "");
    const filteredData = values.filter(
      (_, index) => labels[index].trim() !== ""
    );
    const filteredChartData = {
      labels: filteredLabels,
      datasets: [
        {
          data: filteredData,
          backgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
        },
      ],
    };
    const pieOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "top",
          align: true,
          text: "Sentiment Overview",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    return (
      <Pie
        data={filteredChartData}
        options={pieOptions}
        plugins={[ChartDataLabels]}
      />
    );
  };

  const BarGraph = ({ data }) => {
    const chartData = {
      labels: data.map((item) => item.text),
      datasets: [
        {
          label: "Data",
          data: data.map((item) => item.value),
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      animation: false,
      scales: {
        y: {
          beginAtZero: true,
          max: Math.max(...data.map((item) => item.value)) + 10,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return <Bar data={chartData} options={chartOptions} />;
  };

  return (
    <Container
      maxWidth="xl"
      className={
        window.localStorage.getItem("user") === "bigbasket"
          ? "voicebot-main-container"
          : ""
      }
    >
      {window.localStorage.getItem("user") !== "bigbasket" ? (
        <>
          {window.localStorage.getItem("user") === "bigbasket_voc" ? (
            <>
              <Container maxWidth="xl">
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <div className="logo-container">
                      <h1>Voice Of Customer</h1>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <span className="loggedInuser font-user">USER:</span>
                    <span className="font-user">
                      {" "}
                      {window.localStorage.getItem("user")} |{" "}
                    </span>
                    <span
                      className="logout-title font-user"
                      onClick={logoutUser}
                    >
                      Logout
                    </span>
                  </Grid>

                  <Box
                    sx={{
                      width: "100%",
                      typography: "body1",
                      pointerEvents: load ? "none" : "auto",
                    }}
                  >
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="VOC Analysis" value="1" />
                          <Tab label="Summaries" value="2" />
                          <Tab label="Transcripts" value="3" />
                          <Tab label="Billing" value="4" />
                        </TabList>
                      </Box>
                      <TabPanel
                        value="1"
                        style={{ pointerEvents: load ? "none" : "auto" }}
                      >
                        <div className="voc-filters-search-box-container">
                          <Grid container item spacing={2}>
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="Start Date"
                                value={vocFromDate}
                                onChange={(e) => {
                                  handleVocDates(e, "startDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="End Date"
                                value={vocToDate}
                                onChange={(e) => {
                                  handleVocDates(e, "endDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-checkbox">
                                Complaints
                              </InputLabel>
                              <Select
                                value={selectedReasonFilter}
                                onChange={(e) => {
                                  handleVOCfilter(e, "reason");
                                }}
                                labelId="demo-multiple-checkbox"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Reason" />}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {vocReasonFilter.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    <Checkbox
                                      checked={selectedReasonFilter.includes(
                                        option.value
                                      )}
                                    />
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-agent">
                                Sentiment
                              </InputLabel>
                              <Select
                                value={selectedSentimentFilter}
                                onChange={(e) => {
                                  handleVOCfilter(e, "sentiment");
                                }}
                                labelId="demo-multiple-agent"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Agent" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {variants.map((value) => (
                                  <MenuItem key={value.id} value={value.value}>
                                    <Checkbox
                                      checked={selectedSentimentFilter.includes(
                                        value.value
                                      )}
                                    />
                                    <ListItemText primary={value.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-checkbox">
                                Campaign
                              </InputLabel>
                              <Select
                                value={selectedCampaignFilter}
                                onChange={(e) => {
                                  handleVOCfilter(e, "campaign");
                                }}
                                labelId="demo-multiple-checkbox"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Campaign" />}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {vocCampaignFilter.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    <Checkbox
                                      checked={selectedCampaignFilter.includes(
                                        option.value
                                      )}
                                    />
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <Button
                              variant="contained"
                              style={{ padding: "12px 10px", width: "90px" }}
                              onClick={handleVocData}
                            >
                              Search
                            </Button>
                          </Grid>
                        </div>
                        <br></br>
                        {/* <label>Total Calls:{jsonData["Total Calls"]}</label> */}

                        <Grid item xs={1}></Grid>
                        {!_.isEmpty(vocBarGraphData) ||
                        !_.isEmpty(vocBarGraphData2) ||
                        !_.isEmpty(pieChartData) ? (
                          <Grid container spacing={3}>
                            <Grid container item spacing={3}>
                              <Grid item xs={12}>
                                <Paper elevation={3}>
                                  <div className="voc-bar-graph">
                                    <label className="voc-graphs-header-names">
                                      Complaint Types Overview Distribution
                                    </label>
                                    {!_.isEmpty(vocBarGraphData2) && (
                                      <BarGraph data={vocBarGraphData2} />
                                    )}
                                  </div>
                                </Paper>
                              </Grid>
                            </Grid>

                            <Grid container item spacing={3}>
                              <Grid item xs={12}>
                                {!_.isEmpty(pieChartData) && (
                                  <div className="voc-word-cloud-container">
                                    <Paper>
                                      <label className="voc-graphs-header-names">
                                        Issue Cloud
                                      </label>
                                      <img
                                        src={wordClusterData}
                                        className="word-cloud-properties"
                                        alt="logo"
                                      />
                                    </Paper>
                                  </div>
                                )}
                              </Grid>
                            </Grid>

                            <Grid container item spacing={3}>
                              <Grid item xs={12}>
                                <Paper
                                  elevation={3}
                                  className="voc-pie-chart-box"
                                >
                                  <div className="voc-pie-chart">
                                    {pieChartData && (
                                      <PieChart data={pieChartData} />
                                    )}
                                  </div>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <div className="voc-no-data-found-container">
                            No Data found ...
                          </div>
                        )}
                      </TabPanel>

                      <TabPanel
                        value="2"
                        style={{ pointerEvents: load ? "none" : "auto" }}
                      >
                        <Grid item xs={4}>
                          <Autocomplete
                            style={{ marginTop: "-33px" }}
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={vocSearchFieldFilters}
                            autoHighlight={true}
                            getOptionLabel={(option) => {
                              if (option["label"]) {
                                return option["label"];
                              }
                              if (typeof option === "string") {
                                return option;
                              }
                              return "";
                            }}
                            value={vocSearchInputVal}
                            onChange={handleChangeVocSearch}
                            // onKeyPress = {handleKey}
                            open={open}
                            filterSelectedOptions
                            includeInputInList
                            onOpen={handleOpen}
                            onClose={() => setOpen(false)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search input at VOC"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  onChange: handleChangeVocSearchInput,
                                  onPaste: handleVocSearchPaste,
                                  onKeyDown: handleKey,
                                  // onKeyUp: handleKey,
                                }}
                              />
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              const matches = match(option.value, inputValue, {
                                insideWords: true,
                              });
                              const parts = parse(option.value, matches);
                              if (
                                inputValue &&
                                !_.isEmpty(inputValue) &&
                                option === inputValue
                              ) {
                                // Render input value as the first option
                                return (
                                  <div>
                                    <li {...props}>
                                      <div>
                                        <span style={{ fontWeight: 700 }}>
                                          {inputValue}
                                        </span>
                                      </div>
                                    </li>
                                  </div>
                                );
                              }
                              return (
                                <div>
                                  {/* <div>hello</div> */}
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                </div>
                              );
                            }}
                          />
                        </Grid>
                        <br></br> <br></br>
                        <Grid container item spacing={2}>
                          <div className="date-container">&nbsp;</div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  style={{ width: "124px" }}
                                />
                              )}
                              disableFuture
                              // hideTabs
                              showTodayButton
                              maxDate={moment().toDate()}
                              todayText="now"
                              label="Start Date"
                              value={vocFromDate}
                              onChange={(e) => {
                                handleVocDates(e, "startDate");
                              }}
                            />
                          </LocalizationProvider>
                          <div className="date-container">&nbsp;</div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  style={{ width: "124px" }}
                                />
                              )}
                              disableFuture
                              // hideTabs
                              showTodayButton
                              maxDate={moment().toDate()}
                              todayText="now"
                              label="End Date"
                              value={vocToDate}
                              onChange={(e) => {
                                handleVocDates(e, "endDate");
                              }}
                            />
                          </LocalizationProvider>
                          <div className="date-container">&nbsp;</div>
                          <FormControl style={{ width: "125px" }}>
                            <InputLabel id="demo-multiple-checkbox">
                              Campaign
                            </InputLabel>
                            <Select
                              value={selectedSummaryCampaignFilter}
                              onChange={(e) => {
                                handleVOCSummaryFilter(e, "campaign");
                              }}
                              labelId="demo-multiple-checkbox"
                              id="demo-multiple-checkbox"
                              multiple
                              input={<OutlinedInput label="Campaign" />}
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {vocCampaignFieldFilters.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                  <Checkbox
                                    checked={selectedSummaryCampaignFilter.includes(
                                      option.value
                                    )}
                                  />
                                  <ListItemText primary={option.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className="date-container">&nbsp;</div>
                          <FormControl>
                            <Button
                              variant="contained"
                              style={{ padding: "12px 14px" }}
                              onClick={() => {
                                fetchVocSummariesData(
                                  vocFromDate,
                                  vocToDate,
                                  vocMatchInputVal,
                                  selectedSummaryCampaignFilter
                                );
                              }}
                            >
                              Search
                            </Button>
                          </FormControl>
                          {load ? (
                            <div
                              className="loader_digital"
                              id="loader_digital"
                            ></div>
                          ) : null}
                          <Grid item xs={12}>
                            {!_.isEmpty(jsonVocSummaryData) ? (
                              <VocSearchDataTable
                                fetchVocSummarydata={jsonVocSummaryData}
                                fetchVOCSummaryFunction={
                                  fetchVocSummariesDataCallBack
                                }
                                fetchData={{
                                  fetchVocSummariesDataCallBack,
                                  startDate: vocFromDate,
                                  endDate: vocToDate,
                                  keyPhrase: String(vocMatchInputVal),
                                  campaign: String(selectedTrxCampaignFilter),
                                }}
                              ></VocSearchDataTable>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>

                      <TabPanel
                        value="3"
                        style={{ pointerEvents: load ? "none" : "auto" }}
                      >
                        <div className="voc-filters-search-box-container">
                          <Grid container item spacing={2}>
                            {/* <Grid item xs={1.5}> */}
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="Start Date"
                                value={vocFromDate}
                                onChange={(e) => {
                                  handleVocDates(e, "startDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="Start Date Time"
                                value={vocToDate}
                                onChange={(e) => {
                                  handleVocDates(e, "endDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-checkbox">
                                Complaints
                              </InputLabel>
                              <Select
                                value={selectedTrxReasonFilter}
                                onChange={(e) => {
                                  handleVOCTranscriptFilter(e, "reason");
                                }}
                                labelId="demo-multiple-checkbox"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Reason" />}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {vocReasonFilter.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    <Checkbox
                                      checked={selectedTrxReasonFilter.includes(
                                        option.value
                                      )}
                                    />
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-agent">
                                Sentiment
                              </InputLabel>
                              <Select
                                value={selectedTrxSentimentFilter}
                                onChange={(e) => {
                                  handleVOCTranscriptFilter(e, "sentiment");
                                }}
                                labelId="demo-multiple-agent"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Agent" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {variants.map((value) => (
                                  <MenuItem key={value.id} value={value.value}>
                                    <Checkbox
                                      checked={selectedTrxSentimentFilter.includes(
                                        value.value
                                      )}
                                    />
                                    <ListItemText primary={value.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-checkbox">
                                CallTypes
                              </InputLabel>
                              <Select
                                value={selectedTrxCallTypeFilter}
                                onChange={(e) => {
                                  handleVOCTranscriptFilter(e, "call-type");
                                }}
                                labelId="demo-multiple-checkbox"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="CallType" />}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {vocCallTypeFilter.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    <Checkbox
                                      checked={selectedTrxCallTypeFilter.includes(
                                        option.value
                                      )}
                                    />
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl style={{ width: "125px" }}>
                              <InputLabel id="demo-multiple-checkbox">
                                Campaign
                              </InputLabel>
                              <Select
                                value={selectedTrxCampaignFilter}
                                onChange={(e) => {
                                  handleVOCTranscriptFilter(e, "campaign");
                                }}
                                labelId="demo-multiple-checkbox"
                                id="demo-multiple-checkbox"
                                multiple
                                input={<OutlinedInput label="Campaign" />}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {vocCampaignFilter.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    <Checkbox
                                      checked={selectedTrxCampaignFilter.includes(
                                        option.value
                                      )}
                                    />
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <div className="date-container">&nbsp;</div>
                            <FormControl>
                              <Button
                                variant="contained"
                                style={{ padding: "12px 10px", width: "90px" }}
                                onClick={() => {
                                  fetchVocTranscriptData(
                                    vocFromDate,
                                    vocToDate,
                                    selectedTrxReasonFilter,
                                    selectedTrxSentimentFilter,
                                    selectedTrxCallTypeFilter,
                                    selectedTrxCampaignFilter
                                  );
                                }}
                              >
                                Search
                              </Button>
                            </FormControl>
                            {load ? (
                              <div
                                className="loader_digital"
                                id="loader_digital"
                              ></div>
                            ) : null}
                            <Grid item xs={12}>
                              <VocTranscriptTable
                                fetchVocTranscriptData={jsonVocDataTranscript}
                                fetchVOCTranscriptFunction={
                                  fetchVocTranscriptDataCallBack
                                }
                                fetchData={{
                                  fetchVocTranscriptDataCallBack,
                                  startDate: vocFromDate,
                                  endDate: vocToDate,
                                  reasonType: String(selectedTrxReasonFilter),
                                  sentiment: String(selectedTrxSentimentFilter),
                                  callType: String(selectedTrxCallTypeFilter),
                                  campaign: String(selectedTrxCampaignFilter),
                                }}
                              ></VocTranscriptTable>
                            </Grid>
                          </Grid>
                        </div>
                      </TabPanel>

                      <TabPanel
                        value="4"
                        style={{ pointerEvents: load ? "none" : "auto" }}
                      >
                        <div className="voc-filters-search-box-container">
                          <Grid container item spacing={2}>
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="Start Date"
                                value={vocFromDate}
                                onChange={(e) => {
                                  handleVocDates(e, "startDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    style={{ width: "124px" }}
                                  />
                                )}
                                disableFuture
                                showTodayButton
                                maxDate={moment().toDate()}
                                todayText="now"
                                label="End Date"
                                value={vocToDate}
                                onChange={(e) => {
                                  handleVocDates(e, "endDate");
                                }}
                              />
                            </LocalizationProvider>
                            <div className="date-container">&nbsp;</div>
                            <Button
                              variant="contained"
                              style={{ padding: "12px 10px", width: "90px" }}
                              onClick={() =>{fetchVocBillingData(vocFromDate,vocToDate)}}
                            >
                              Search
                            </Button>
                          </Grid>
                        </div>
                        <br></br>                       
                        {!_.isEmpty(jsonVocDataBilling) ? (
                          <div className="col-md-8">
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                "& > :not(style)": {
                                  m: 0.5,
                                  width: 245,
                                  height: 120,
                                },
                              }}
                            >                             
                              <Paper
                                className="aupage"
                                style={{
                                  marginLeft: "-10px",
                                  marginTop: "5px",
                                }}
                              >
                                <b>Total Duration(in Hours) </b>
                                <br></br>
                                <br></br>
                                <ul
                                  style={{
                                    paddingLeft: "10px",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "500"
                                  }}
                                >
                                  {jsonVocDataBilling &&
                                    jsonVocDataBilling.total_duration_hours}
                                </ul>
                              </Paper>
                              <Paper className="aupage">
                                <b>Total Duration in Seconds</b>
                                <br></br>
                                <br></br>
                                <ul
                                  style={{
                                    paddingLeft: "10px",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "500"
                                  }}
                                >
                                  {jsonVocDataBilling && jsonVocDataBilling.total_duration_in_sec}
                                </ul>
                              </Paper>
                              <Paper className="aupage">
                                <b>Call Count</b>
                                <br></br>
                                <br></br>
                                <ul
                                  style={{
                                    paddingLeft: "10px",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "500"
                                  }}
                                >
                                  {jsonVocDataBilling &&
                                    jsonVocDataBilling.call_count}
                                </ul>
                              </Paper>
                            </Box>
                          </div>
                        ) : (
                          <div className="voc-no-data-found-container">
                            No Data found ...
                          </div>
                        )}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
                {load ? (
                  <div className="loader_digital" id="loader_digital"></div>
                ) : null}
              </Container>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div className="logo-container">
                  <h1>Conversational Intelligence</h1>
                </div>
              </Grid>
              <Grid item xs={2}>
                <span className="loggedInuser font-user">USER:</span>
                <span className="font-user">
                  {" "}
                  {window.localStorage.getItem("user")} |{" "}
                </span>
                <span className="logout-title font-user" onClick={logoutUser}>
                  Logout
                </span>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  pointerEvents: load ? "none" : "auto",
                }}
                className={load ? "hidden-cus" : ""}
              >
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      {window.localStorage.getItem("user") !==
                        "poonawalla_fincorp" &&
                      window.localStorage.getItem("user") !== "sobha_limited" &&
                      window.localStorage.getItem("user") !== "mindtree" &&
                      window.localStorage.getItem("user") !== "matrimony" ? (
                        <Tab label="Calls" value="1" />
                      ) : null}
                      {window.localStorage.getItem("user") !==
                        "poonawalla_fincorp" &&
                      window.localStorage.getItem("user") !== "sobha_limited" &&
                      window.localStorage.getItem("user") !== "mindtree" &&
                      window.localStorage.getItem("user") !== "matrimony" ? (
                        <Tab label="Digital" value="2" />
                      ) : null}
                      {window.localStorage.getItem("user") ===
                        "poonawalla_fincorp" ||
                      window.localStorage.getItem("user") === "matrimony" ? (
                        <Tab label="Transcripts" value="1" />
                      ) : window.localStorage.getItem("user") !== "mindtree" &&
                        window.localStorage.getItem("user") !==
                          "sobha_limited" ? (
                        <Tab label="Transcripts" value="3" />
                      ) : null}
                      {window.localStorage.getItem("user") ===
                      "sobha_limited" ? (
                        <Tab label="Audit" value="1" />
                      ) : null}
                      {window.localStorage.getItem("user") ===
                      "sobha_limited" ? (
                        <Tab label="Calls" value="2" />
                      ) : null}
                      {window.localStorage.getItem("user") === "mindtree" ? (
                        <Tab label="CDR" value="1" />
                      ) : null}
                      {window.localStorage.getItem("user") ===
                      "redcliffe_lab" ? (
                        <Tab label="Audit" value="4" />
                      ) : window.localStorage.getItem("user") ===
                        "poonawalla_fincorp" ? (
                        <Tab label="Audit Inbound" value="2" />
                      ) : window.localStorage.getItem("user") ===
                        "matrimony" ? (
                        <Tab label="Audit" value="2" />
                      ) : null}
                      {window.localStorage.getItem("user") ===
                      "poonawalla_fincorp" ? (
                        <Tab label="Audit Outbound" value="5" />
                      ) : null}
                    </TabList>
                  </Box>

                  {window.localStorage.getItem("user") !==
                    "poonawalla_fincorp" &&
                  window.localStorage.getItem("user") !== "sobha_limited" &&
                  window.localStorage.getItem("user") !== "mindtree" &&
                  window.localStorage.getItem("user") !== "matrimony" ? (
                    <TabPanel
                      value="1"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        <div></div>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDate}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                          />
                        </LocalizationProvider>

                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent"
                            id="demo-multiple-checkbox"
                            multiple
                            value={agentVal}
                            onChange={handleChangeAgent}
                            input={<OutlinedInput label="Agent" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {/* {this.checkIntentArr()?( */}
                            {/* <div> */}
                            {agentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-disposition"
                            id="demo-checkbox"
                            multiple
                            value={dispositionVal}
                            onChange={handleChangeDisposition}
                            input={<OutlinedInput label="Disposition" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {/* {this.checkIntentArr()?( */}
                            {/* <div> */}
                            {dispositionOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                            {/* </div> */}
                            {/* ):null} */}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignValInCalls}
                            onChange={handleChangeCampaignInCalls}
                            input={<OutlinedInput label="Campaign" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignValInCalls.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple">Intent</InputLabel>
                          <Select
                            labelId="demo-multiple"
                            id="demo-multiple-checkbox"
                            multiple
                            value={intentVal}
                            onChange={handleChangeIntent}
                            input={<OutlinedInput label="Intent" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {/* {this.checkIntentArr()?( */}
                            {/* <div> */}
                            {intentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    intentVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Sentiment
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={sentimentVal}
                            onChange={handleChangeSelect}
                            input={<OutlinedInput label="Sentiment" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {variants.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    sentimentVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl > */}
                        <Button
                          variant="contained"
                          style={{ padding: "12px 10px", width: "90px" }}
                          onClick={() => {
                            fetchDashboardData(
                              startDate,
                              endDate,
                              intentVal,
                              sentimentVal,
                              agentVal,
                              dispositionVal,
                              genderVal
                            );
                          }}
                        >
                          Search
                        </Button>
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>
                      <label>Total Calls:{jsonData["Total Calls"]}</label>
                      {intentDrill.map((tab) => (
                        <Chip
                          label={tab}
                          // onClick={handleClick}
                          onDelete={() => handleTabClose(tab)}
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ))}
                      {sentimentDrill.map((tab) => (
                        <Chip
                          label={tab}
                          onDelete={() => handleTabCloseSentiment(tab)}
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ))}
                      {genderDrill.map((tab) => (
                        <Chip
                          label={tab}
                          onDelete={() => handleTabCloseGender(tab)}
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ))}

                      <Grid item xs={1}></Grid>
                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardData).length !== 0 && (
                          <Grid container item spacing={3}>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["intentOptions"]}
                                  data={dashboardData["intentData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["sentimentOptions"]}
                                  data={dashboardData["sentimentData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Paper
                                elevation={3}
                                sx={{ height: "100%", paddingTop: 5 }}
                              >
                                <div className="summ">
                                  <label>Customer Center Summary</label>
                                </div>
                                <Stack direction="row">
                                  <Grid item xs={4}>
                                    <div style={{ width: 169 }}>
                                      <CircularProgressbar
                                        value={
                                          dashboardData["noisyCallsDataPercent"]
                                        }
                                        text={`${dashboardData["noisyCallsDataPercent"]}%`}
                                      />
                                      <label style={{ marginLeft: "24px" }}>
                                        Noisy calls (%)
                                      </label>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div style={{ width: 169 }}>
                                      <CircularProgressbar
                                        value={
                                          dashboardData["loudCallsDataPercent"]
                                        }
                                        text={`${dashboardData["loudCallsDataPercent"]}%`}
                                      />
                                      <label>Low Volume calls (%)</label>
                                    </div>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Doughnut
                                      className="ci"
                                      data={dashboardData["genderData"]}
                                      options={dashboardData["doughnutOptions"]}
                                      plugins={[ChartDataLabels]}
                                    />
                                    <div
                                      style={{
                                        textAlign: "center",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      <label>Customer Gender</label>
                                    </div>
                                  </Grid>
                                </Stack>
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["negativityOptions"]}
                                  data={dashboardData["negativityData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={
                                    dashboardData["participationOptions"]
                                  }
                                  data={dashboardData["participationData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["interruptOptions"]}
                                  data={dashboardData["interruptData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["retrievalOptions"]}
                                  data={dashboardData["retrievalData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardData["callTimeOptions"]}
                                  data={dashboardData["callTimeData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </TabPanel>
                  ) : null}

                  {window.localStorage.getItem("user") !==
                    "poonawalla_fincorp" &&
                  window.localStorage.getItem("user") !== "sobha_limited" &&
                  window.localStorage.getItem("user") !== "mindtree" &&
                  window.localStorage.getItem("user") !== "matrimony" ? (
                    <TabPanel
                      value="2"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateChat}
                            onChange={(newValue) => {
                              setStartDateChat(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        <div></div>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateChat}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateChat(newValue);
                            }}
                          />
                        </LocalizationProvider>

                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-channel">
                            Speaker
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-channel"
                            id="demo-multiple-checkbox"
                            multiple
                            value={channelVal}
                            onChange={handleChangeChatChannel}
                            input={<OutlinedInput label="Speaker" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {/* {this.checkIntentArr()?( */}
                            {/* <div> */}
                            {channels.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    channelVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                            {/* </div> */}
                            {/* ):null} */}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple">Intent</InputLabel>
                          <Select
                            labelId="demo-multiple-inett"
                            id="demo-multiple-checkbox"
                            multiple
                            value={intentChatVal}
                            onChange={handleChangeChatIntent}
                            input={<OutlinedInput label="IntentChat" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {/* {this.checkIntentArr()?( */}
                            {/* <div> */}
                            {intentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    intentChatVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                            {/* </div> */}
                            {/* ):null} */}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl sx={{ m: 0, width: 180 }}> */}
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-chatsen-label">
                            Sentiment
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-chatsen-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={sentimentChatVal}
                            onChange={handleChangeChatSelect}
                            input={<OutlinedInput label="SentimentChat" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {variants.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    sentimentChatVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-chatchannel-label">
                            Channel
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-chatchannel-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={channelChatVal}
                            onChange={handleChangeChannel}
                            input={<OutlinedInput label="ChannelChat" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {channelchat.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    // channelChatVal.indexOf(m) > -1
                                    channelChatVal.findIndex(
                                      (item) => item.value === m.value
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "12px 14px", width: "90px" }}
                            onClick={() => {
                              fetchChatData(
                                startDateChat,
                                endDateChat,
                                channelVal,
                                sentimentChatVal,
                                intentChatVal,
                                channelChatVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {/* </Grid> */}
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>
                      <div>
                        <label style={{ marginBottom: "10px" }}>
                          Total Interactions:{jsonChatData["Total Calls"]}
                        </label>
                      </div>
                      {intentChatDrill.map((tab) => (
                        <Chip
                          label={tab}
                          // onClick={handleClick}
                          onDelete={() => handleChatTabClose(tab)}
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ))}
                      {sentimentChatDrill.map((tab) => (
                        <Chip
                          label={tab}
                          onDelete={() => handleChatTabCloseSentiment(tab)}
                          deleteIcon={<DeleteIcon />}
                          variant="outlined"
                        />
                      ))}
                      <Grid item xs={1}></Grid>
                      <div style={{ paddingTop: "20px" }}>
                        {Object.keys(dashboardChatData).length !== 0 && (
                          <Grid container item spacing={3}>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={dashboardChatData["intentOptions"]}
                                  data={dashboardChatData["intentData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={
                                    dashboardChatData["sentimentOptions"]
                                  }
                                  data={dashboardChatData["sentimentData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              {" "}
                              <Paper elevation={3}>
                                <Bar
                                  options={
                                    dashboardChatData["negativityOptions"]
                                  }
                                  data={dashboardChatData["negativityData"]}
                                  plugins={[ChartDataLabels]}
                                />
                              </Paper>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </TabPanel>
                  ) : null}

                  {(window.localStorage.getItem("user") !== "sobha_limited" &&
                    window.localStorage.getItem("user") !== "mindtree" &&
                    window.localStorage.getItem("user") ===
                      "poonawalla_fincorp") ||
                  window.localStorage.getItem("user") === "matrimony" ? (
                    <TabPanel value="1">
                      <Grid item xs={4}>
                        <Autocomplete
                          style={{ marginTop: "-33px" }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          options={searchOptions}
                          autoHighlight={true}
                          getOptionLabel={(option) => {
                            if (option["label"]) {
                              return option["label"];
                            }
                            if (typeof option === "string") {
                              return option;
                            }
                            return "";
                          }}
                          value={searchInputVal}
                          onChange={handleChangeSearch}
                          open={open}
                          filterSelectedOptions
                          onOpen={handleOpen}
                          onClose={() => setOpen(false)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search input"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                onChange: handleChangeInput,
                                onPaste: handlePaste,
                              }}
                            />
                          )}
                          renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.value, inputValue, {
                              insideWords: true,
                            });
                            const parts = parse(option.value, matches);
                            return (
                              <li {...props}>
                                <div>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                          }}
                        />
                      </Grid>
                      {/* </Grid> */}
                      <br></br>

                      <br></br>
                      <Grid container item spacing={2}>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateTranscript}
                            onChange={(newValue) => {
                              setStartDateTranscript(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateTranscript}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateTranscript(newValue);
                            }}
                          />
                        </LocalizationProvider>

                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignVal}
                            onChange={handleChangeCampaign}
                            input={<OutlinedInput label="Campaign" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-transcript">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-transcript"
                            id="demo-agent-transcript"
                            multiple
                            value={agentTranscriptionVal}
                            onChange={handleChangeAgentTranscription}
                            input={<OutlinedInput label="AgentTranscript" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentTranscriptionVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionTranscriptVal}
                            onChange={handleChangeDispositionTranscript}
                            input={
                              <OutlinedInput label="DispositionTranscript" />
                            }
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionTranscriptVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple">Intent</InputLabel>
                          <Select
                            labelId="demo-multiple"
                            id="demo-multiple-checkbox"
                            multiple
                            value={intentTranscriptVal}
                            onChange={handleChangeIntentTranscript}
                            input={<OutlinedInput label="IntentTranscript" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {intentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    intentTranscriptVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl sx={{ m: 0, width: 180 }}> */}
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="Gender">Gender</InputLabel>
                          <Select
                            labelId="Gender"
                            id="Gender"
                            multiple
                            value={genderVal}
                            onChange={handleChangeGender}
                            input={<OutlinedInput label="Gender" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {gender.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    genderVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}

                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl sx={{ m: 0, width: 180 }}> */}
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Sentiment
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={sentimentTranscriptVal}
                            onChange={handleChangeSelectTranscript}
                            input={
                              <OutlinedInput label="SentimentTranscript" />
                            }
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {variants.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    sentimentTranscriptVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "12px 14px" }}
                            onClick={() => {
                              fetchTranscriptData(
                                startDateTranscript,
                                endDateTranscript,
                                intentTranscriptVal,
                                sentimentTranscriptVal,
                                agentTranscriptionVal,
                                campaignVal,
                                genderVal,
                                dispositionTranscriptVal,
                                searchInputVal,
                                matchInputVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {/* </Grid> */}
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                        <Grid item xs={12}>
                          <SampleTable
                            fetchTranscript={[
                              jsonDataTranscript,
                              fetchTranscriptDataCallBack,
                              {
                                startDateTranscript: startDateTranscript,
                                endDateTranscript: endDateTranscript,
                                intentTranscriptVal:
                                  selectionList(intentTranscriptVal),
                                sentimentTranscriptVal: selectionList(
                                  sentimentTranscriptVal
                                ),
                                agentTranscriptionVal: selectionList(
                                  agentTranscriptionVal
                                ),
                                campaignVal: selectionList(campaignVal),
                                genderVal: selectionList(genderVal),
                                dispositionTranscriptVal: selectionList(
                                  dispositionTranscriptVal
                                ),
                                searchInputVal,
                                matchInputVal,
                              },
                            ]}
                          ></SampleTable>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : (
                    <TabPanel value="3">
                      {/* <Grid container spacing={2}> */}
                      <Grid item xs={4}>
                        <Autocomplete
                          style={{ marginTop: "-33px" }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          // options={searchOptions.map((option) => option.label.trim())}
                          options={searchOptions}
                          // getOptionLabel={(option) => option.label}
                          autoHighlight={true}
                          getOptionLabel={(option) => {
                            if (option["label"]) {
                              return option["label"];
                            }
                            if (typeof option === "string") {
                              return option;
                            }
                            return "";
                          }}
                          value={searchInputVal}
                          // onInputChange={handleChangeSearch}
                          onChange={handleChangeSearch}
                          open={open}
                          // filterOptions={(x) => x}
                          filterSelectedOptions
                          // includeInputInList
                          onOpen={handleOpen}
                          onClose={() => setOpen(false)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search input"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                onChange: handleChangeInput,
                                onPaste: handlePaste,
                              }}
                            />
                          )}
                          renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.value, inputValue, {
                              insideWords: true,
                            });
                            const parts = parse(option.value, matches);
                            return (
                              <li {...props}>
                                <div>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                          }}
                        />
                      </Grid>
                      {/* </Grid> */}
                      <br></br>

                      <br></br>
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateTranscript}
                            onChange={(newValue) => {
                              setStartDateTranscript(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateTranscript}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateTranscript(newValue);
                            }}
                          />
                        </LocalizationProvider>

                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignVal}
                            onChange={handleChangeCampaign}
                            input={<OutlinedInput label="Campaign" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-transcript">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-transcript"
                            id="demo-agent-transcript"
                            multiple
                            value={agentTranscriptionVal}
                            onChange={handleChangeAgentTranscription}
                            input={<OutlinedInput label="AgentTranscript" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentTranscriptionVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionTranscriptVal}
                            onChange={handleChangeDispositionTranscript}
                            input={
                              <OutlinedInput label="DispositionTranscript" />
                            }
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionTranscriptVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple">Intent</InputLabel>
                          <Select
                            labelId="demo-multiple"
                            id="demo-multiple-checkbox"
                            multiple
                            value={intentTranscriptVal}
                            onChange={handleChangeIntentTranscript}
                            input={<OutlinedInput label="IntentTranscript" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {intentOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    intentTranscriptVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl sx={{ m: 0, width: 180 }}> */}
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="Gender">Gender</InputLabel>
                          <Select
                            labelId="Gender"
                            id="Gender"
                            multiple
                            value={genderVal}
                            onChange={handleChangeGender}
                            input={<OutlinedInput label="Gender" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {gender.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    genderVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}

                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        {/* <FormControl sx={{ m: 0, width: 180 }}> */}
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Sentiment
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={sentimentTranscriptVal}
                            onChange={handleChangeSelectTranscript}
                            input={
                              <OutlinedInput label="SentimentTranscript" />
                            }
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {variants.map((variant) => (
                              <MenuItem key={variant.label} value={variant}>
                                <Checkbox
                                  checked={
                                    sentimentTranscriptVal.findIndex(
                                      (item) => item.label === variant.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "12px 14px" }}
                            onClick={() => {
                              fetchTranscriptData(
                                startDateTranscript,
                                endDateTranscript,
                                intentTranscriptVal,
                                sentimentTranscriptVal,
                                agentTranscriptionVal,
                                campaignVal,
                                genderVal,
                                dispositionTranscriptVal,
                                searchInputVal,
                                matchInputVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {/* </Grid> */}
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                        <Grid item xs={12}>
                          <SampleTable
                            fetchTranscript={[
                              jsonDataTranscript,
                              fetchTranscriptDataCallBack,
                              {
                                startDateTranscript: startDateTranscript,
                                endDateTranscript: endDateTranscript,
                                intentTranscriptVal:
                                  selectionList(intentTranscriptVal),
                                sentimentTranscriptVal: selectionList(
                                  sentimentTranscriptVal
                                ),
                                agentTranscriptionVal: selectionList(
                                  agentTranscriptionVal
                                ),
                                campaignVal: selectionList(campaignVal),
                                genderVal: selectionList(genderVal),
                                dispositionTranscriptVal: selectionList(
                                  dispositionTranscriptVal
                                ),
                                searchInputVal,
                                matchInputVal,
                              },
                            ]}
                          ></SampleTable>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  )}
                  {window.localStorage.getItem("user") !== "mindtree" &&
                  window.localStorage.getItem("user") === "sobha_limited" ? (
                    <TabPanel
                      value="1"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            label="Start Date"
                            value={startDateAudit}
                            onChange={(newValue) => {
                              setStartDateAudit(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div></div>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date"
                            value={endDateAudit}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateAudit(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignAuditVal}
                            onChange={handleChangeCampaignAudit}
                            input={<OutlinedInput label="CampaignAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-audit">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-audit"
                            id="demo-agent-audit"
                            multiple
                            value={agentAuditVal}
                            onChange={handleChangeAgentAudit}
                            input={<OutlinedInput label="AgentAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionAuditVal}
                            onChange={handleChangeDispositionAudit}
                            input={<OutlinedInput label="DispositionAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillAuditVal}
                            onChange={handleChangeSkillAudit}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-language">
                            Language
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-language"
                            id="demo-multiple-checkbox"
                            multiple
                            value={languageAuditVal}
                            onChange={handleChangeLanguageAudit}
                            input={<OutlinedInput label="LanguageAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {languageAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    languageAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-score">
                            Score
                          </InputLabel>
                          <Select
                            labelId="demo-score"
                            id="demo-checkbox"
                            value={scoreAuditVal}
                            // multiple
                            onChange={handleChangeScoreAudit}
                            input={<OutlinedInput label="Score" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                          >
                            {scores.map((m) => (
                              <MenuItem key={m} value={m}>
                                <Checkbox
                                  checked={scoreAuditVal.indexOf(m) > -1}
                                />
                                <ListItemText primary={m} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        {scoreSelected ? (
                          <div>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1, width: 98 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Input
                                style={{ fontSize: "0.8rem" }}
                                placeholder="Enter Score"
                                value={scoreInputVal}
                                onChange={handleChangeScoreInput}
                              />
                            </Box>
                          </div>
                        ) : (
                          <Grid></Grid>
                        )}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchOpenSearchAuditData(
                                startDateAudit,
                                endDateAudit,
                                campaignAuditVal,
                                agentAuditVal,
                                dispositionAuditVal,
                                languageAuditVal,
                                skillAuditVal,
                                scoreInputVal,
                                scoreAuditVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>
                      <div className="col-md-12 row">
                        <div className="col-md-4">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginLeft: -2,
                              "& > :not(style)": {
                                m: 0.5,
                                width: 115,
                                height: 60,
                              },
                            }}
                          >
                            <Paper className="aupage">
                              <b>Total Calls</b>
                              <br></br>
                              <div className="dapage">
                                {" "}
                                {jsonOpenAuditData["Total Calls"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls above 90%</b>
                              <br></br>
                              <div className="dapage">
                                {jsonOpenAuditData["Threshold Above 90"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls below 60%</b>
                              <br></br>
                              <div className="dapage">
                                {jsonOpenAuditData["Threshold Below 60"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Total Agents</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonOpenAuditData["Total Agents"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Avg Score</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonOpenAuditData["Average Score"]}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <div className="col-md-8">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 0.5,
                                width: 245,
                                height: 120,
                              },
                            }}
                          >
                            <Paper
                              className="aupage"
                              style={{
                                marginLeft: "-28px",
                                marginTop: "5px",
                              }}
                            >
                              <b>Best Parameters</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonOpenAuditData["Best Parameters"] &&
                                  transformArray(
                                    jsonOpenAuditData["Best Parameters"]
                                  ).map((el, i) => (
                                    <div key={i}>{el.replace(/_/g, " ")}</div>
                                  ))}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Best Agents</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonOpenAuditData["Best Agents"] &&
                                  jsonOpenAuditData["Best Agents"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Need Improvement</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonOpenAuditData["Needs Improvement"] &&
                                  jsonOpenAuditData["Needs Improvement"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                          </Box>
                        </div>
                      </div>
                      <br></br>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Box
                            sx={{
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 1,
                                width: 600,
                                height: 500,
                              },
                            }}
                          >
                            <Paper
                              elevation={3}
                              style={{ marginLeft: "-13px" }}
                            >
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={audGraphVal}
                                  onChange={handleChangeAudGraph}
                                >
                                  <FormControlLabel
                                    value="campaign"
                                    control={<Radio size="small" />}
                                    editable="true"
                                    label="Campaign"
                                    checked={checkCampaign}
                                  />
                                  <FormControlLabel
                                    value="disposition"
                                    control={<Radio size="small" />}
                                    label="Disposition"
                                  />
                                  <FormControlLabel
                                    value="skill"
                                    control={<Radio size="small" />}
                                    label="Skill"
                                  />
                                  <FormControlLabel
                                    value="parameter"
                                    control={<Radio size="small" />}
                                    label="Parameter-wise"
                                  />
                                </RadioGroup>
                              </FormControl>
                              {/* </Paper> */}

                              <div style={{ paddingTop: "20px" }}>
                                {Object.keys(dashboardAuditData).length !==
                                  0 && (
                                  <Grid container item spacing={3}>
                                    {audGraphVal === "disposition" ? (
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={3}
                                          sx={{
                                            width: "580px",
                                            height: "400px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditData[
                                                "dispositionScoreOptions"
                                              ]
                                            }
                                            data={
                                              dashboardAuditData[
                                                "dispositionScoreData"
                                              ]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    ) : audGraphVal === "skill" ? (
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={3}
                                          sx={{
                                            width: "580px",
                                            height: "400px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditData[
                                                "skillScoreOptions"
                                              ]
                                            }
                                            data={
                                              dashboardAuditData[
                                                "skillScoreData"
                                              ]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    ) : audGraphVal === "parameter" ? (
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={3}
                                          sx={{
                                            width: "580px",
                                            height: "400px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditData[
                                                "parameterScoreOptions"
                                              ]
                                            }
                                            data={
                                              dashboardAuditData[
                                                "parameterScoreData"
                                              ]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={4}
                                          sx={{
                                            width: "580px",
                                            height: "400px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditData[
                                                "campaignScoreOptions"
                                              ]
                                            }
                                            data={
                                              dashboardAuditData[
                                                "campaignScoreData"
                                              ]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <br></br>
                        <div>
                          <Box
                            sx={{
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 1,
                                width: 600,
                                height: 500,
                              },
                            }}
                          >
                            <Paper
                              elevation={3}
                              style={{ marginLeft: "-13px" }}
                            >
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={audPieVal}
                                  onChange={handleChangeAudPie}
                                >
                                  <FormControlLabel
                                    value="language"
                                    control={<Radio size="small" />}
                                    editable="true"
                                    label="Language"
                                    checked={checkLanguage}
                                  />
                                  <FormControlLabel
                                    value="disposition"
                                    control={<Radio size="small" />}
                                    label="Disposition"
                                  />
                                </RadioGroup>
                              </FormControl>

                              <div style={{ paddingTop: "25px" }}>
                                {Object.keys(dashboardAuditData).length !==
                                0 ? (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      {audPieVal === "language" ? (
                                        <Grid item xs={6}>
                                          <Paper
                                            elevation={3}
                                            sx={{
                                              width: "520px",
                                              height: "400px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginLeft: "30px",
                                                width: "410px",
                                              }}
                                            >
                                              <Pie
                                                options={
                                                  dashboardAuditData[
                                                    "pieChartsOptions"
                                                  ]
                                                }
                                                data={
                                                  dashboardAuditData[
                                                    "languagePieChartData"
                                                  ]
                                                }
                                                plugins={[ChartDataLabels]}
                                              />
                                            </div>
                                          </Paper>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={6}>
                                          <Paper
                                            elevation={3}
                                            sx={{
                                              width: "520px",
                                              height: "400px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginLeft: "30px",
                                                width: "410px",
                                              }}
                                            >
                                              <Pie
                                                options={
                                                  dashboardAuditData[
                                                    "dispositionPieChartOptions"
                                                  ]
                                                }
                                                data={
                                                  dashboardAuditData[
                                                    "dispositionPieChartData"
                                                  ]
                                                }
                                                plugins={[ChartDataLabels]}
                                              />
                                            </div>
                                          </Paper>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid container item spacing={3}>
                                    <label
                                      style={{
                                        marginLeft: "30px",
                                        width: "410px",
                                      }}
                                    >
                                      No Data For Analysis
                                    </label>
                                  </Grid>
                                )}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <br></br>
                      </div>
                      <Box
                        sx={{
                          flexWrap: "wrap",
                          "& > :not(style)": {
                            m: 1,
                            width: 1275,
                            height: 555,
                          },
                        }}
                      >
                        <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                          <div style={{ paddingTop: "20px" }}>
                            {Object.keys(dashboardAuditData).length !== 0 ? (
                              <Grid container item spacing={3}>
                                <Grid item xs={6}>
                                  {" "}
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      width: "1240px",
                                      height: "495px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <Line
                                      options={
                                        dashboardAuditData["timeScoreOptions"]
                                      }
                                      data={dashboardAuditData["timeScoreData"]}
                                      plugins={[ChartDataLabels]}
                                    />
                                  </Paper>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container spacing={3}>
                                <label>No Data For Time-Score Analysis</label>
                              </Grid>
                            )}
                          </div>
                        </Paper>
                      </Box>
                      <br></br>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <OpensearchAuditTable
                          fetchAudit={[
                            jsonOpenAuditData,
                            fetchOpenSearchAuditDataCallBack,
                            {
                              startDateAudit: startDateAudit,
                              endDateAudit: endDateAudit,
                              campaignAuditVal: selectionList(campaignAuditVal),
                              agentAuditVal: selectionList(agentAuditVal),
                              dispositionAuditVal:
                                selectionList(dispositionAuditVal),
                              languageAuditVal: selectionList(languageAuditVal),
                              skillAuditVal: selectionList(skillAuditVal),
                              scoreInputVal: scoreInputVal,
                              scoreAuditVal: scoreAuditVal,
                            },
                            languageAuditVal,
                          ]}
                        ></OpensearchAuditTable>
                      </Grid>
                    </TabPanel>
                  ) : null}

                  {window.localStorage.getItem("user") !== "mindtree" &&
                  window.localStorage.getItem("user") === "sobha_limited" ? (
                    <TabPanel
                      value="2"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            label="Start Date"
                            value={startDateAudit}
                            onChange={(newValue) => {
                              setStartDateAudit(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div></div>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date"
                            value={endDateAudit}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateAudit(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignAuditVal}
                            onChange={handleChangeCampaignAudit}
                            input={<OutlinedInput label="CampaignAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-audit">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-audit"
                            id="demo-agent-audit"
                            multiple
                            value={agentAuditVal}
                            onChange={handleChangeAgentAudit}
                            input={<OutlinedInput label="AgentAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionAuditVal}
                            onChange={handleChangeDispositionAudit}
                            input={<OutlinedInput label="DispositionAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillAuditVal}
                            onChange={handleChangeSkillAudit}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-language">
                            Language
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-language"
                            id="demo-multiple-checkbox"
                            multiple
                            value={languageAuditVal}
                            onChange={handleChangeLanguageAudit}
                            input={<OutlinedInput label="LanguageAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {languageAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    languageAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-score">
                            Score
                          </InputLabel>
                          <Select
                            labelId="demo-score"
                            id="demo-checkbox"
                            value={scoreAuditVal}
                            // multiple
                            onChange={handleChangeScoreAudit}
                            input={<OutlinedInput label="Score" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                          >
                            {scores.map((m) => (
                              <MenuItem key={m} value={m}>
                                <Checkbox
                                  checked={scoreAuditVal.indexOf(m) > -1}
                                />
                                <ListItemText primary={m} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        {scoreSelected ? (
                          <div>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1, width: 98 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Input
                                style={{ fontSize: "0.8rem" }}
                                placeholder="Enter Score"
                                value={scoreInputVal}
                                onChange={handleChangeScoreInput}
                              />
                            </Box>
                          </div>
                        ) : (
                          <Grid></Grid>
                        )}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchOpenSearchCallsData(
                                startDateAudit,
                                endDateAudit,
                                campaignAuditVal,
                                agentAuditVal,
                                dispositionAuditVal,
                                languageAuditVal,
                                skillAuditVal,
                                scoreInputVal,
                                scoreAuditVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>

                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <div className="date-container">&nbsp;</div>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <OpensearchCallsTable
                          fetchCalls={[
                            jsonOpenCallsData,
                            fetchOpenSearchCallsDataCallBack,
                            {
                              startDateAudit: startDateAudit,
                              endDateAudit: endDateAudit,
                              campaignAuditVal: selectionList(campaignAuditVal),
                              agentAuditVal: selectionList(agentAuditVal),
                              languageAuditVal: selectionList(languageAuditVal),
                              dispositionAuditVal:
                                selectionList(dispositionAuditVal),
                              skillAuditVal: selectionList(skillAuditVal),
                              scoreInputVal: scoreInputVal,
                              scoreAuditVal: scoreAuditVal,
                            },
                          ]}
                        ></OpensearchCallsTable>
                      </Grid>
                    </TabPanel>
                  ) : null}

                  {window.localStorage.getItem("user") === "mindtree" ? (
                    <TabPanel
                      value="1"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            label="Start Date"
                            value={startDateCDR}
                            onChange={(newValue) => {
                              setStartDateCDR(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div></div>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date"
                            value={endDateCDR}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateCDR(
                                String(moment(newValue).format("YYYY-MM-DD"))
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-campaign-cdr">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign-cdr"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignCDRVal}
                            onChange={handleChangeCampaignCdr}
                            input={<OutlinedInput label="CampaignCDR" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-cdr">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-cdr"
                            id="demo-agent-audit"
                            multiple
                            value={agentCDRVal}
                            onChange={handleChangeAgentCdr}
                            input={<OutlinedInput label="AgentCdr" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-phone-cdr">
                            Phone
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-phone-cdr"
                            id="demo-phone-cdr"
                            multiple
                            value={phoneNameCDRVal}
                            onChange={handleChangePhoneCdr}
                            input={<OutlinedInput label="PhoneCdr" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {phoneNameCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    phoneNameCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition-cdr">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition-cdr"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionCDRVal}
                            onChange={handleChangeDispostionCdr}
                            input={<OutlinedInput label="DispositionCdr" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill-cdr">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill-cdr"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillCDRVal}
                            onChange={handleChangeSkillCdr}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-location-cdr">
                            Location
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-location-cdr"
                            id="demo-multiple-checkbox"
                            multiple
                            value={locationCDRVal}
                            onChange={handleChangeLocationCdr}
                            input={<OutlinedInput label="Location" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {locationCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    locationCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-status">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-status-cdr"
                            id="demo-multiple-checkbox"
                            multiple
                            value={statusCDRVal}
                            onChange={handleChangeStatusCdr}
                            input={<OutlinedInput label="Status" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {statusCDROptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    statusCDRVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <div>
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { m: 1, width: 125 },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Input
                              style={{ fontSize: "0.8rem" }}
                              placeholder="Enter CallerID"
                              value={callerCDRVal}
                              onChange={handleChangeCallerCdr}
                            />
                          </Box>
                        </div>
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchOpenCdrData(
                                startDateCDR,
                                endDateCDR,
                                campaignCDRVal,
                                callerCDRVal,
                                agentCDRVal,
                                dispositionCDRVal,
                                locationCDRVal,
                                skillCDRVal,
                                statusCDRVal,
                                phoneNameCDRVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <div className="date-container">&nbsp;</div>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <CDRTable
                          fetchCalls={[
                            jsonOpenCdrData,
                            fetchOpenCdrDataCallBack,
                            {
                              startDate: String(startDateCDR),
                              endDate: String(endDateCDR),
                              campaignVal: selectionList(campaignCDRVal),
                              callerVal: !_.isEmpty(callerCDRVal)
                                ? String(callerCDRVal)
                                : 0,
                              agentVal: selectionList(agentCDRVal),
                              dispositionVal: selectionList(dispositionCDRVal),
                              locationVal: selectionList(locationCDRVal),
                              skillVal: selectionList(skillCDRVal),
                              statusVal: selectionList(statusCDRVal),
                              phoneVal: selectionList(phoneNameCDRVal),
                            },
                          ]}
                        ></CDRTable>
                      </Grid>
                    </TabPanel>
                  ) : null}

                  {window.localStorage.getItem("user") === "redcliffe_lab" ? (
                    <TabPanel
                      value="4"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateAudit}
                            onChange={(newValue) => {
                              setStartDateAudit(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        <div></div>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateAudit}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateAudit(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        {/* </Grid>
                <Grid container item spacing={2}> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignAuditVal}
                            onChange={handleChangeCampaignAudit}
                            input={<OutlinedInput label="CampaignAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-audit">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-audit"
                            id="demo-agent-audit"
                            multiple
                            value={agentAuditVal}
                            onChange={handleChangeAgentAudit}
                            input={<OutlinedInput label="AgentAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionAuditVal}
                            onChange={handleChangeDispositionAudit}
                            input={<OutlinedInput label="DispositionAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillAuditVal}
                            onChange={handleChangeSkillAudit}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* </Grid>
                <Grid container item spacing={2}> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-score">
                            Score
                          </InputLabel>
                          <Select
                            labelId="demo-score"
                            id="demo-checkbox"
                            value={scoreAuditVal}
                            // multiple
                            onChange={handleChangeScoreAudit}
                            input={<OutlinedInput label="Score" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                          >
                            {scores.map((m) => (
                              <MenuItem key={m} value={m}>
                                <Checkbox
                                  checked={scoreAuditVal.indexOf(m) > -1}
                                />
                                <ListItemText primary={m} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        <div className="date-container">&nbsp;</div>
                        {scoreSelected ? (
                          // <Grid item xs={1.3}>
                          <div>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1, width: 98 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Input
                                style={{ fontSize: "0.8rem" }}
                                placeholder="Enter Score"
                                value={scoreInputVal}
                                onChange={handleChangeScoreInput}
                              />
                            </Box>
                          </div>
                        ) : (
                          //  </Grid>
                          <Grid></Grid>
                        )}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        {/* <Grid item xs={1.3}> */}
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchAuditData(
                                startDateAudit,
                                endDateAudit,
                                campaignAuditVal,
                                agentAuditVal,
                                dispositionAuditVal,
                                skillAuditVal,
                                scoreInputVal,
                                scoreAuditVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {/* </Grid> */}
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>

                      {/* <Grid item xs={1}>

              </Grid> */}
                      <div className="col-md-12 row">
                        <div className="col-md-4">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginLeft: -2,
                              "& > :not(style)": {
                                m: 0.5,
                                width: 115,
                                height: 60,
                              },
                            }}
                          >
                            <Paper className="aupage">
                              <b>Total Calls</b>

                              <br></br>
                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Total Calls"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls above 90%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditData["Threshold Above 90"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls below 60%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditData["Threshold Below 60"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Total Agents</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Total Agents"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Avg Score</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Average Score"]}
                              </div>
                            </Paper>
                            <Paper className="aupage" style={{ color: "red" }}>
                              <b>Red Alert Calls</b>
                              <br></br>

                              <div className="dapage">
                                {jsonAuditData["Red Alert Calls"]}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <div className="col-md-8">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 0.5,
                                width: 245,
                                height: 120,
                              },
                            }}
                          >
                            <Paper
                              className="aupage"
                              style={{
                                marginLeft: "-28px",
                                marginTop: "5px",
                              }}
                            >
                              <b>Best Parameters</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Best Parameters"] &&
                                  jsonAuditData["Best Parameters"].map(
                                    (el, i) => (
                                      <div key={i}>{el.replace(/_/g, " ")}</div>
                                    )
                                  )}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Best Agents</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Best Agents"] &&
                                  jsonAuditData["Best Agents"].map((el, i) => (
                                    <div key={i}>{el}</div>
                                  ))}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Needs Improvement</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Needs Improvement"] &&
                                  jsonAuditData["Needs Improvement"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                          </Box>
                        </div>
                      </div>
                      <br></br>
                      <Box
                        sx={{
                          flexWrap: "wrap",
                          "& > :not(style)": {
                            m: 1,
                            width: 700,
                            height: 500,
                          },
                        }}
                      >
                        <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={audGraphVal}
                              onChange={handleChangeAudGraph}
                            >
                              <FormControlLabel
                                value="campaign"
                                control={<Radio size="small" />}
                                editable="true"
                                label="Campaign"
                                checked={checkCampaign}
                              />
                              <FormControlLabel
                                value="disposition"
                                control={<Radio size="small" />}
                                label="Disposition"
                              />
                              <FormControlLabel
                                value="skill"
                                control={<Radio size="small" />}
                                label="Skill"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* </Paper> */}

                          <div style={{ paddingTop: "20px" }}>
                            {Object.keys(dashboardAuditData).length !== 0 && (
                              <Grid container item spacing={3}>
                                {audGraphVal === "disposition" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "650px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "dispositionScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData[
                                            "dispositionScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : audGraphVal === "skill" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "650px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "skillScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData["skillScoreData"]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={4}
                                      sx={{
                                        width: "650px",
                                        height: "400px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "campaignScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData[
                                            "campaignScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </div>
                        </Paper>
                      </Box>
                      <br></br>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <AuditTable
                          fetchAudit={[
                            jsonAuditData,
                            fetchAuditDataCallBack,
                            {
                              startDateAudit: startDateAudit,
                              endDateAudit: endDateAudit,
                              campaignAuditVal: selectionList(campaignAuditVal),
                              agentAuditVal: selectionList(agentAuditVal),
                              dispositionAuditVal:
                                selectionList(dispositionAuditVal),
                              skillAuditVal: selectionList(skillAuditVal),
                              scoreInputVal: scoreInputVal,
                              scoreAuditVal: scoreAuditVal,
                            },
                          ]}
                        ></AuditTable>
                      </Grid>
                    </TabPanel>
                  ) : window.localStorage.getItem("user") ===
                      "poonawalla_fincorp" ||
                    window.localStorage.getItem("user") === "matrimony" ? (
                    <TabPanel
                      value="2"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateAudit}
                            onChange={(newValue) => {
                              setStartDateAudit(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        <div></div>
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateAudit}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateAudit(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignAuditVal}
                            onChange={handleChangeCampaignAudit}
                            input={<OutlinedInput label="CampaignAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-audit">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-audit"
                            id="demo-agent-audit"
                            multiple
                            value={agentAuditVal}
                            onChange={handleChangeAgentAudit}
                            input={<OutlinedInput label="AgentAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionAuditVal}
                            onChange={handleChangeDispositionAudit}
                            input={<OutlinedInput label="DispositionAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillAuditVal}
                            onChange={handleChangeSkillAudit}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillAuditVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* </Grid>
              <Grid container item spacing={2}> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-score">
                            Score
                          </InputLabel>
                          <Select
                            labelId="demo-score"
                            id="demo-checkbox"
                            value={scoreAuditVal}
                            // multiple
                            onChange={handleChangeScoreAudit}
                            input={<OutlinedInput label="Score" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                          >
                            {scores.map((m) => (
                              <MenuItem key={m} value={m}>
                                <Checkbox
                                  checked={scoreAuditVal.indexOf(m) > -1}
                                />
                                <ListItemText primary={m} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        <div className="date-container">&nbsp;</div>
                        {scoreSelected ? (
                          // <Grid item xs={1.3}>
                          <div>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1, width: 98 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Input
                                style={{ fontSize: "0.8rem" }}
                                placeholder="Enter Score"
                                value={scoreInputVal}
                                onChange={handleChangeScoreInput}
                              />
                            </Box>
                          </div>
                        ) : (
                          //  </Grid>
                          <Grid></Grid>
                        )}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        {/* <Grid item xs={1.3}> */}
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchAuditData(
                                startDateAudit,
                                endDateAudit,
                                campaignAuditVal,
                                agentAuditVal,
                                dispositionAuditVal,
                                skillAuditVal,
                                scoreInputVal,
                                scoreAuditVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {/* </Grid> */}
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>
                      <div className="col-md-12 row">
                        <div className="col-md-4">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginLeft: -2,
                              "& > :not(style)": {
                                m: 0.5,
                                width: 115,
                                height: 60,
                              },
                            }}
                          >
                            <Paper className="aupage">
                              <b>Total Calls</b>

                              <br></br>
                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Total Calls"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls above 90%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditData["Threshold Above 90"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls below 60%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditData["Threshold Below 60"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Total Agents</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Total Agents"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Avg Score</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditData["Average Score"]}
                              </div>
                            </Paper>
                            <Paper className="aupage" style={{ color: "red" }}>
                              <b>Red Alert Calls</b>
                              <br></br>

                              <div className="dapage">
                                {jsonAuditData["Red Alert Calls"]}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <div className="col-md-8">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 0.5,
                                width: 245,
                                height: 120,
                              },
                            }}
                          >
                            <Paper
                              className="aupage"
                              style={{
                                marginLeft: "-28px",
                                marginTop: "5px",
                              }}
                            >
                              <b>Best Parameters</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Best Parameters"] &&
                                  jsonAuditData["Best Parameters"].map(
                                    (el, i) => (
                                      <div key={i}>{el.replace(/_/g, " ")}</div>
                                    )
                                  )}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Best Agents</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Best Agents"] &&
                                  jsonAuditData["Best Agents"].map((el, i) => (
                                    <div key={i}>{el}</div>
                                  ))}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Needs Improvement</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditData["Needs Improvement"] &&
                                  jsonAuditData["Needs Improvement"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                          </Box>
                        </div>
                      </div>
                      <br></br>
                      <Box
                        sx={{
                          flexWrap: "wrap",
                          "& > :not(style)": {
                            m: 1,
                            width: 530,
                            height: 400,
                          },
                        }}
                      >
                        <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={audGraphVal}
                              onChange={handleChangeAudGraph}
                            >
                              <FormControlLabel
                                value="campaign"
                                control={<Radio size="small" />}
                                editable="true"
                                label="Campaign"
                                checked={checkCampaign}
                              />
                              <FormControlLabel
                                value="disposition"
                                control={<Radio size="small" />}
                                label="Disposition"
                              />
                              <FormControlLabel
                                value="skill"
                                control={<Radio size="small" />}
                                label="Skill"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* </Paper> */}

                          <div style={{ paddingTop: "20px" }}>
                            {Object.keys(dashboardAuditData).length !== 0 && (
                              <Grid container item spacing={2}>
                                {audGraphVal === "disposition" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "dispositionScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData[
                                            "dispositionScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : audGraphVal === "skill" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "skillScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData["skillScoreData"]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={4}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditData[
                                            "campaignScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditData[
                                            "campaignScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                )}
                                <Box
                                  sx={{
                                    "& > :not(style)": {
                                      m: 1,
                                      width: 530,
                                      height: 400,
                                      marginLeft: 80,
                                      marginTop: -44.5,
                                    },
                                  }}
                                >
                                  <Paper>
                                    <div style={{ paddingTop: "50px" }}>
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={4}
                                          sx={{
                                            width: "500px",
                                            height: "300px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditData["scoreOptions"]
                                            }
                                            data={
                                              dashboardAuditData["scoreData"]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    </div>
                                  </Paper>
                                </Box>
                              </Grid>
                            )}
                          </div>
                        </Paper>
                      </Box>
                      <br></br>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <AuditTable
                          fetchAudit={[
                            jsonAuditData,
                            fetchAuditDataCallBack,
                            {
                              startDateAudit: startDateAudit,
                              endDateAudit: endDateAudit,
                              campaignAuditVal: selectionList(campaignAuditVal),
                              agentAuditVal: selectionList(agentAuditVal),
                              dispositionAuditVal:
                                selectionList(dispositionAuditVal),
                              skillAuditVal: selectionList(skillAuditVal),
                              scoreInputVal: scoreInputVal,
                              scoreAuditVal: scoreAuditVal,
                            },
                          ]}
                        ></AuditTable>
                      </Grid>
                    </TabPanel>
                  ) : null}
                  {window.localStorage.getItem("user") ===
                  "poonawalla_fincorp" ? (
                    <TabPanel
                      value="5"
                      style={{ pointerEvents: load ? "none" : "auto" }}
                    >
                      <Grid container item spacing={2}>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            label="Start Date Time"
                            value={startDateAuditOut}
                            onChange={(newValue) => {
                              setStartDateAuditOut(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        <div></div>
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "124px" }}
                              />
                            )}
                            label="End Date Time"
                            value={endDateAuditOut}
                            disableFuture
                            hideTabs
                            showTodayButton
                            maxDate={moment().subtract(1, "days").toDate()}
                            todayText="now"
                            onChange={(newValue) => {
                              setEndDateAuditOut(newValue);
                            }}
                          />
                        </LocalizationProvider>
                        {/* </Grid> */}
                        {/* </Grid>
                <Grid container item spacing={2}> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-camapaign">
                            Campaign
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-campaign"
                            id="demo-multiple-checkbox"
                            multiple
                            value={campaignAuditOutVal}
                            onChange={handleChangeCampaignOutAudit}
                            input={<OutlinedInput label="CampaignAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {campaignAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    campaignAuditOutVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-agent-audit">
                            Agent
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-agent-audit"
                            id="demo-agent-audit"
                            multiple
                            value={agentAuditOutVal}
                            onChange={handleChangeAgentOutAudit}
                            input={<OutlinedInput label="AgentAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {agentAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    agentAuditOutVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-disposition">
                            Disposition
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-disposition"
                            id="demo-multiple-checkbox"
                            multiple
                            value={dispositionAuditOutVal}
                            onChange={handleChangeDispositionOutAudit}
                            input={<OutlinedInput label="DispositionAudit" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {dispositionAuditOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    dispositionAuditOutVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        {/* <Grid item xs={1.5}> */}
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-skill">
                            Skill
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-skill"
                            id="demo-multiple-checkbox"
                            multiple
                            value={skillAuditOutVal}
                            onChange={handleChangeSkillOutAudit}
                            input={<OutlinedInput label="Skill" />}
                            renderValue={(selected) =>
                              selected.map((x) => x.value).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {skillOptions.map((m) => (
                              <MenuItem key={m.label} value={m}>
                                <Checkbox
                                  checked={
                                    skillAuditOutVal.findIndex(
                                      (item) => item.label === m.label
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={m.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className="date-container">&nbsp;</div>
                        <FormControl style={{ width: "125px" }}>
                          <InputLabel id="demo-multiple-score">
                            Score
                          </InputLabel>
                          <Select
                            labelId="demo-score"
                            id="demo-checkbox"
                            value={scoreAuditOutVal}
                            // multiple
                            onChange={handleChangeScoreAuditOut}
                            input={<OutlinedInput label="Score" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                          >
                            {scores.map((m) => (
                              <MenuItem key={m} value={m}>
                                <Checkbox
                                  checked={scoreAuditOutVal.indexOf(m) > -1}
                                />
                                <ListItemText primary={m} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* </Grid> */}
                        <div className="date-container">&nbsp;</div>
                        {scoreOutSelected ? (
                          // <Grid item xs={1.3}>
                          <div>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": { m: 1, width: 98 },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Input
                                style={{ fontSize: "0.8rem" }}
                                placeholder="Enter Score"
                                value={scoreOutInputVal}
                                onChange={handleChangeScoreOutInput}
                              />
                            </Box>
                          </div>
                        ) : (
                          //  </Grid>
                          <Grid></Grid>
                        )}
                        <div className="date-container">
                          &nbsp;
                          <br />
                        </div>
                        <FormControl>
                          <Button
                            variant="contained"
                            style={{ padding: "13px 8px", fontSize: "0.8rem" }}
                            onClick={() => {
                              fetchAuditOutData(
                                startDateAuditOut,
                                endDateAuditOut,
                                campaignAuditOutVal,
                                agentAuditOutVal,
                                dispositionAuditOutVal,
                                skillAuditOutVal,
                                scoreOutInputVal,
                                scoreAuditOutVal
                              );
                            }}
                          >
                            Search
                          </Button>
                        </FormControl>
                        {load ? (
                          <div
                            className="loader_digital"
                            id="loader_digital"
                          ></div>
                        ) : null}
                      </Grid>
                      <br></br>
                      <div className="col-md-12 row">
                        <div className="col-md-4">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginLeft: -2,
                              "& > :not(style)": {
                                m: 0.5,
                                width: 115,
                                height: 60,
                              },
                            }}
                          >
                            <Paper className="aupage">
                              <b>Total Calls</b>

                              <br></br>
                              <div className="dapage">
                                {" "}
                                {jsonAuditOutData["Total Calls"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls above 90%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditOutData["Threshold Above 90"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Calls below 60%</b>

                              <br></br>
                              <div className="dapage">
                                {jsonAuditOutData["Threshold Below 60"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Total Agents</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditOutData["Total Agents"]}
                              </div>
                            </Paper>
                            <Paper className="aupage">
                              <b>Avg Score</b>
                              <br></br>

                              <div className="dapage">
                                {" "}
                                {jsonAuditOutData["Average Score"]}
                              </div>
                            </Paper>
                            <Paper className="aupage" style={{ color: "red" }}>
                              <b>Red Alert Calls</b>
                              <br></br>

                              <div className="dapage">
                                {jsonAuditOutData["Red Alert Calls"]}
                              </div>
                            </Paper>
                          </Box>
                        </div>
                        <div className="col-md-8">
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 0.5,
                                width: 245,
                                height: 120,
                              },
                            }}
                          >
                            <Paper
                              className="aupage"
                              style={{
                                marginLeft: "-28px",
                                marginTop: "5px",
                              }}
                            >
                              <b>Best Parameters</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  paddingLeft: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditOutData["Best Parameters"] &&
                                  jsonAuditOutData["Best Parameters"].map(
                                    (el, i) => (
                                      <div key={i}>{el.replace(/_/g, " ")}</div>
                                    )
                                  )}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Best Agents</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  "padding-left": "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditOutData["Best Agents"] &&
                                  jsonAuditOutData["Best Agents"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                            <Paper className="aupage">
                              <b>Needs Improvement</b>
                              <br></br>
                              <br></br>
                              <ul
                                style={{
                                  "padding-left": "10px",
                                  textAlign: "left",
                                }}
                              >
                                {jsonAuditOutData["Needs Improvement"] &&
                                  jsonAuditOutData["Needs Improvement"].map(
                                    (el, i) => <div key={i}>{el}</div>
                                  )}
                              </ul>
                            </Paper>
                          </Box>
                        </div>
                      </div>
                      <br></br>
                      <Box
                        sx={{
                          flexWrap: "wrap",
                          "& > :not(style)": {
                            m: 1,
                            width: 530,
                            height: 400,
                          },
                        }}
                      >
                        <Paper elevation={3} style={{ marginLeft: "-13px" }}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={audGraphOutVal}
                              onChange={handleChangeAudOutGraph}
                            >
                              <FormControlLabel
                                value="campaign"
                                control={<Radio size="small" />}
                                editable="true"
                                label="Campaign"
                                checked={checkOutCampaign}
                              />
                              <FormControlLabel
                                value="disposition"
                                control={<Radio size="small" />}
                                label="Disposition"
                              />
                              <FormControlLabel
                                value="skill"
                                control={<Radio size="small" />}
                                label="Skill"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* </Paper> */}

                          <div style={{ paddingTop: "20px" }}>
                            {Object.keys(dashboardAuditOutData).length !==
                              0 && (
                              <Grid container item spacing={3}>
                                {audGraphOutVal === "disposition" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditOutData[
                                            "dispositionScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditOutData[
                                            "dispositionScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : audGraphOutVal === "skill" ? (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={3}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditOutData[
                                            "skillScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditOutData[
                                            "skillScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid item xs={6}>
                                    {" "}
                                    <Paper
                                      elevation={4}
                                      sx={{
                                        width: "500px",
                                        height: "300px",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      <Bar
                                        options={
                                          dashboardAuditOutData[
                                            "campaignScoreOptions"
                                          ]
                                        }
                                        data={
                                          dashboardAuditOutData[
                                            "campaignScoreData"
                                          ]
                                        }
                                        plugins={[ChartDataLabels]}
                                      />
                                    </Paper>
                                  </Grid>
                                )}

                                <Box
                                  sx={{
                                    "& > :not(style)": {
                                      m: 1,
                                      width: 530,
                                      height: 400,
                                      marginLeft: 80,
                                      marginTop: -44.5,
                                    },
                                  }}
                                >
                                  <Paper>
                                    <div style={{ paddingTop: "50px" }}>
                                      <Grid item xs={6}>
                                        {" "}
                                        <Paper
                                          elevation={4}
                                          sx={{
                                            width: "500px",
                                            height: "300px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Bar
                                            options={
                                              dashboardAuditOutData[
                                                "scoreOptions"
                                              ]
                                            }
                                            data={
                                              dashboardAuditOutData["scoreData"]
                                            }
                                            plugins={[ChartDataLabels]}
                                          />
                                        </Paper>
                                      </Grid>
                                    </div>
                                  </Paper>
                                </Box>
                              </Grid>
                            )}
                          </div>
                        </Paper>
                      </Box>
                      <br></br>
                      <Grid item xs={12} style={{ marginLeft: "-13px" }}>
                        <AuditOutTable
                          fetchAuditOut={[
                            jsonAuditOutData,
                            fetchAuditOutDataCallBack,
                            {
                              startDateAuditOut: startDateAuditOut,
                              endDateAuditOut: endDateAuditOut,
                              campaignAuditOutVal:
                                selectionList(campaignAuditOutVal),
                              agentAuditOutVal: selectionList(agentAuditOutVal),
                              dispositionAuditOutVal: selectionList(
                                dispositionAuditOutVal
                              ),
                              skillAuditOutVal: selectionList(skillAuditOutVal),
                              scoreOutInputVal: scoreOutInputVal,
                              scoreAuditOutVal: scoreAuditOutVal,
                            },
                          ]}
                        ></AuditOutTable>
                      </Grid>
                    </TabPanel>
                  ) : null}
                </TabContext>

                {window.localStorage.getItem("user") === "bigbasket" && (
                  <div className="voicebot-container">hello</div>
                )}
              </Box>
            </Grid>
          )}
        </>
      ) : (
        <div className="voicebot-container">
          <div className="voicebot-header-container">
            <div className="logo-container">
              <h1>Voicebot Dashboard</h1>
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div style={{ marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="voice-bot-datepicker"
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    disableFuture
                    hideTabs
                    showTodayButton
                    maxDate={moment().subtract(1, "days").toDate()}
                    todayText="now"
                    value={voiceBotDates.startDate}
                    onChange={(newValue) => {
                      handleVoiceBotDateChange(newValue, "fromDate");
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="voice-bot-datepicker"
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    disableFuture
                    hideTabs
                    showTodayButton
                    maxDate={moment().subtract(1, "days").toDate()}
                    todayText="now"
                    value={voiceBotDates.endDate}
                    onChange={(newValue) => {
                      handleVoiceBotDateChange(newValue, "toDate");
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Select
                className="voicebot-select-box"
                // multiple
                value={didSelectedValues}
                onChange={(e) => {
                  handleDidFilterChange(e);
                }}
              >
                {didFilterData.map((m) => (
                  <MenuItem key={m.label} value={m}>
                    <ListItemText
                      primary={m.value}
                      className="voice-bot-filter-items"
                    />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="voicebot-logout">
              <span className="loggedInuser font-user">USER:</span>
              <span className="font-user">
                {" "}
                {window.localStorage.getItem("user")} |{" "}
              </span>
              <span className="logout-title font-user" onClick={logoutUser}>
                Logout
              </span>
            </div>
          </div>
          <VoiceBot
            voiceData={voiceBotData}
            voiceUtteranceData={voiceBotUnidentifiedData}
          />
        </div>
      )}
    </Container>
  );
};
export default SpeechDashboard;
