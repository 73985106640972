import styled from "styled-components";
import WavBar from "./WavBar";
import WavBarInvert from "./WavBarInvert";
import IntentLabel from "./IntentLabel";
import ReactTooltip from "react-tooltip";
import React from "react";
import { useRef } from "react";
const VoiceBarDiv = styled.div`
  display: grid;
  align-self: ${(props) => props.position};
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  height: 100px;
  padding-left: 2px;
`;

const BarSegment = styled.div`
  display: grid;
  grid-column-gap: 1px;
  grid-template-columns: repeat(${(props) => props.endTime}, 1fr);
`;

const LabelSegment = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.endTime}, 1fr);
  height: 30px;
`;
const showLabels = true;
function VoiceBar(props) {
  const transcriptRef = useRef();
  const handleClick = () => {
    setTimeout(function () {
      transcriptRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };
  if (props.type === "customer") {
    return (
      <>
        <VoiceBarDiv endTime={props.endTime} position={props.position}>
          <LabelSegment endTime={props.endTime} className="customerLabel">
            {showLabels &&
              props.transcription.map((data, key) => {
                return (
                  <>
                    <IntentLabel
                      className="labelIntent"
                      key={key + "L"}
                      start={Math.ceil(data.start)}
                      end={Math.ceil(data.end) + 1}
                      startr={15}
                      endr={25}
                    >
                      {}
                    </IntentLabel>
                  </>
                );
              })}
          </LabelSegment>
          <BarSegment endTime={props.endTime}>
            {props.transcription.map((data, key) => {
              return (
                <>
                  <WavBar
                    ref={transcriptRef}
                    type="customer"
                    key={key + "CSL"}
                    start={Math.ceil(data.start)}
                    end={Math.ceil(data.end) + 1}
                    startr="1"
                    endr="100"
                    sentiment={data.sentiment[0].label}
                    data-html={true}
                    onClick={() => handleClick()}
                  />
                  <ReactTooltip
                    type="light"
                    textColor="#ff7f00"
                    place="bottom"
                  />
                </>
              );
            })}
          </BarSegment>
        </VoiceBarDiv>
      </>
    );
  } else if (props.type === "agent") {
    return (
      <>
        <VoiceBarDiv endTime={props.endTime} position={props.position}>
          <BarSegment endTime={props.endTime}>
            {props.transcription.map((data, key) => {
              return (
                <>
                  <WavBarInvert
                    type="agent"
                    key={key + "ASL"}
                    start={Math.ceil(data.start)}
                    end={Math.ceil(data.end) + 1}
                    startr={1}
                    endr={100}
                    sentiment={data.sentiment[0].label}
                    data-html={true}
                  />
                  <ReactTooltip
                    type="light"
                    textColor="#ff7f00"
                    place="bottom"
                  />
                </>
              );
            })}
          </BarSegment>

          <LabelSegment endTime={props.endTime} className="agentLabel">
            {showLabels &&
              props.transcription.map((data, key) => {
                return (
                  <>
                    <IntentLabel
                      className="labelIntent"
                      key={key + "L"}
                      start={Math.ceil(data.start)}
                      end={Math.ceil(data.end) + 1}
                      startr={35}
                      endr={45}
                    >
                      {}
                    </IntentLabel>
                  </>
                );
              })}
          </LabelSegment>
        </VoiceBarDiv>
      </>
    );
  } else {
    return <h1>Please mention type</h1>;
  }
}
export default VoiceBar;
