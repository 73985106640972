import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Popover, TablePagination, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import _ from "lodash";
import "../App.css";
import * as XLSX from "xlsx";
import "../components/loader.css";
import * as images from "../utils/images/images.component";
import { AgentDataDetails } from "./AgentDataDetails";
import "../App.css";
import { log } from "console";

interface Column {
  id:
  |"audio_url"
  |"score"
  |"summary"
  |"score_fatal"
  |"call_time"
  |"CallerID"
  |"call_type" 
  |"EndTime"
  |"StartTime"
  |"disposition"
  |"ucid"
  |"agent"
  |"call_date"
  |"campaign"

  | "call_opening_thanking"
  | "opening_selfintro"
  | "brand_name_check"
  | "offering_assistance_opening"
  | "empathy_check"
  | "apology_check"
  | "acknowledgement_check"
  | "agentactive_listeining"
  | "jargons_avoidence"
  | "fumbling_stammering"
  | "energetic_sound"
  | "rushing_avoidence"
  | "fillerswords_avoid_agent"
  | "probing_check"
  | "hold_placing"
  | "hold_thanking"
  | "deadair_avoidence_check"
  | "survey_check"
  | "verification_check"
  | "further_assistance_check"
  | "rudeness_avoidence_check"
  | "singing_laughing_humming_avoidence_check"
  | "showing_interest_check"
  | "sarcasm_avoidence_check"
  | "greeting_closing"
  | "closing_branding"
  | "abusiveness_avoidence_check"
  | "thanking_closing"

  |"areas_of_improvement"
  |"language"
  |"training_needs_identification"
    ;
  label: string;
  minWidth?: number;
  align?: "right";
}
interface DataItem {
  [key: string]: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns: readonly Column[] = [

  { id: "audio_url", label: "Audio", minWidth: 150 },
  { id: "score", label: "Score without fatal", minWidth: 100 },
  { id: "score_fatal", label: "score with fatal", minWidth: 100 },
  { id: "call_date", label: "Call Date", minWidth: 100 },
  { id: "StartTime", label: "Start Time", minWidth: 100 },
  { id: "EndTime", label: "End Time", minWidth: 100 },
  { id: "call_type", label: "Call Type", minWidth: 120 },
  { id: "CallerID", label: "Caller ID", minWidth: 100 },
  { id: "call_time", label: "Call Time", minWidth: 100 },
  { id: "language", label: "Language", minWidth: 100 },
  { id: "agent", label: "Agent Name", minWidth: 150 },
  { id: "ucid", label: "UCID", minWidth: 100 },
  { id: "campaign", label: "Campaign", minWidth: 120 },
  { id: "disposition", label: "Disposition", minWidth: 120 },

  { id: "call_opening_thanking", label: "Call Opening Thanking", minWidth: 150 },
  { id: "opening_selfintro", label: "Opening Self Introduction", minWidth: 150 },
  { id: "brand_name_check", label: "Brand Name Mention", minWidth: 150 },
  { id: "offering_assistance_opening", label: "Offering Assistance (Opening)", minWidth: 180 },
  { id: "empathy_check", label: "Empathy Check", minWidth: 150 },
  { id: "apology_check", label: "Apology Check", minWidth: 150 },
  { id: "acknowledgement_check", label: "Acknowledgement Check", minWidth: 180 },
  { id: "agentactive_listeining", label: "Agent Active Listening", minWidth: 180 },
  { id: "jargons_avoidence", label: "Jargon Avoidance", minWidth: 160 },
  { id: "fumbling_stammering", label: "Fumbling / Stammering", minWidth: 180 },
  { id: "energetic_sound", label: "Energetic Tone", minWidth: 150 },
  { id: "rushing_avoidence", label: "Rushing Avoidance", minWidth: 160 },
  { id: "fillerswords_avoid_agent", label: "Avoiding Filler Words", minWidth: 180 },
  { id: "probing_check", label: "Probing Check", minWidth: 150 },
  { id: "hold_placing", label: "Hold Placement", minWidth: 150 },
  { id: "hold_thanking", label: "Hold Thanking", minWidth: 150 },
  { id: "deadair_avoidence_check", label: "Dead Air Avoidance", minWidth: 180 },
  { id: "survey_check", label: "Survey Check", minWidth: 150 },
  { id: "verification_check", label: "Verification Check", minWidth: 170 },
  { id: "further_assistance_check", label: "Further Assistance Offered", minWidth: 200 },
  { id: "rudeness_avoidence_check", label: "Rudeness Avoidance", minWidth: 180 },
  { id: "singing_laughing_humming_avoidence_check", label: "Singing/Laughing/Humming Avoidance", minWidth: 250 },
  { id: "showing_interest_check", label: "Showing Interest", minWidth: 160 },
  { id: "sarcasm_avoidence_check", label: "Sarcasm Avoidance", minWidth: 160 },
  { id: "greeting_closing", label: "Greeting (Closing)", minWidth: 160 },
  { id: "closing_branding", label: "Closing Branding", minWidth: 170 },
  { id: "abusiveness_avoidence_check", label: "Abusiveness Avoidance", minWidth: 200 },
  { id: "thanking_closing", label: "Thanking (Closing)", minWidth: 170 },

  { id: "summary", label: "Summary", minWidth: 250 },
  { id: "training_needs_identification", label: "Training needs Identification", minWidth: 250 },
  { id: "areas_of_improvement", label: "Areas of Improvement", minWidth: 250 },

];

let metaArr: any[] = [];

const getData = () => {
  metaArr = [];
  Object.values(HitsArr).forEach((data) => {
    const obj = JSON.parse(JSON.stringify(data));
    metaArr.push(obj);
  });
  return metaArr;
};


type Props = {
  fetchCalls: any;
};

let HitsArr = {};
let downloadArr: any[] = [];

axios.defaults.headers.common["user"] = localStorage.getItem("user");

export default function HDFCCredillaCallsTable({ fetchCalls }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>();
  const [downloadData, setDownloadData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [pageNav, setPageNav] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [agentCountFinal, setAgentCountFinal] = useState<any>([]);
  const [avgDurationFinal, setAvgDurationFinal] = useState<any>([]);                                                                                                                                                                                                                                                                                                                                                                 
  const [order, setOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
 
  const tableRef = useRef(null);


  const convertToTitleCase = (str: string) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  const convertKeysToTitleCase = (obj: DataItem) => {
    const newObj: DataItem = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[convertToTitleCase(key)] = obj[key];
      }
    }
    return newObj;
  };
  const exportToExcel = (data: any) => {
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty data array");
      return;
    }
    // Convert keys to camel case for each object in the array
    const camelCaseData = data.map((obj) => convertKeysToTitleCase(obj));
    // Check if camelCaseData is defined and is an array
    if (
      !camelCaseData ||
      !Array.isArray(camelCaseData) ||
      camelCaseData.length === 0
    ) {
      console.error("Invalid or empty camelCaseData array");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(camelCaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Save the workbook as an XLSX file
    XLSX.writeFile(
      workbook,
      String(localStorage.getItem("user")) + "_Calls.xlsx"
    );
  };
  interface LanguageMapping {
    [key: string]: string;
  }

  const getLanguageName = (languageCode: string): string => {
    const languageMapping: LanguageMapping = {
      "en-IN": "English",
      "ta-IN": "Tamil",
      "ml-IN": "Malayalam",
      "mr-IN": "Marathi",
      "hi-IN": "Hindi",
      "te-IN": "Telugu",
      "kn-IN": "Kannada",
      "gu-IN": "Gujarati",
      // Add more language codes and names as needed
    };
    return languageMapping[languageCode] || "Language not found";
  };

  const getLanguageCode = (languageName: string): string => {
    const languageMapping: LanguageMapping = {
      "English": "en-IN",
      "Tamil": "ta-IN",
      "Malayalam": "ml-IN",
      "Marathi": "mr-IN",
      "Hindi": "hi-IN",
      "Telugu": "te-IN",
      "Kannada": "kn-IN",
      "Gujarati": "gu-IN",
      // Add more language codes and names as needed
    };
    return languageMapping[languageName] || "0";
  };

  const downloadDataCall = async () => {
    try {
      setLoading(true);
      const response = await fetchCalls[2]();
      const data = response && response.data && response.data.Hits;
      downloadArr = [];
      Object.values(data).forEach((rowData: any) => {
        const orderedRow: any = {};
        // Iterate through columns to ensure the order
        columns.forEach((column) => {
          const { id } = column;
          orderedRow[id] = rowData.hasOwnProperty(id)
            ? JSON.parse(JSON.stringify(rowData[id]))
            : undefined;
          // Additional processing for specific columns if needed
          if (id === "language" && orderedRow[id] !== undefined) {
            orderedRow[id] = JSON.parse(
              JSON.stringify(getLanguageName(orderedRow[id]))
            );
          }
          // Add more conditions for other columns if needed
        });
        downloadArr.push(orderedRow);
      });
      console.log(downloadArr);
      setDownloadData(downloadArr);
      exportToExcel(downloadArr);
    } catch (error) {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
      console.log(error);
      window.alert("Error Calling Api to Download");
    } finally {
      downloadArr = [];
      setLoading(false); // Set isLoading to false when done or if an error occurs
    }
  };

  const handleDownload = (e: any) => { 
    downloadDataCall();
  };

  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const getPopoverContent = () => {
    if (
      !_.isEmpty(responseData) &&
      !_.isEmpty(agentCountFinal) &&
      !_.isEmpty(avgDurationFinal)
    ) {
      return (
        <AgentDataDetails
          getAgentData={[responseData, agentCountFinal, avgDurationFinal]}
        ></AgentDataDetails>
      );
    } else {
      return <AgentDataDetails></AgentDataDetails>;
    }
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setTableData([]);
    setPage(newPage);
    setPageNav(true);
    var from = newPage === 0 ? 0 : newPage * rowsPerPage;
    var size = rowsPerPage;
    fetchCalls[1](
      size,
      from
    );
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableData([]);
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  
    fetchCalls[1](
   parseInt(event.target.value, 10),
      "0"
    );
  };

  if (fetchCalls != undefined && fetchCalls.length != 0) {
    if (fetchCalls[0]["Hits"] != undefined) {
      HitsArr = fetchCalls[0]["Hits"];
    }
  }


  useEffect(() => {
    if(!pageNav){
      setPage(0);
    }
    setTableData(getData());
    setPageNav(false);
  }, [fetchCalls[0]]);


  return (
    <Card>
    {/* Box for RowsPerPage and Download button */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        ml: 100,
      }}
    >
      {/* RowsPerPage Options */}
      <TablePagination
    rowsPerPageOptions={[50, 100, 250]}
    component="div"
    count={
      fetchCalls[0]["dataCount"] > 0 ? fetchCalls[0]["dataCount"] : 0
    } // This is what your request should be returning in addition to the current page of rows.
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
      />
  
      {/* Download Button */}
      <Button variant="contained" color="primary" onClick={handleDownload}>
        Download
      </Button>
    </Box>
  
    <Paper>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: "yellow" }}>
            <TableRow>
              {localStorage.getItem("user") === "hdfc_credila" ? (
                <>
                  {columns.map((column, accessor) => {
                    return (
                      <StyledTableCell
                        key={accessor}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1976d2",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {column.label}
                        {order === "asc" ? (
                          <img
                            alt="upArrow"
                            style={{ height: "12px", paddingLeft: "5px" }}
                            src={images.sortAsc}
                          />
                        ) : order == "" ? null : (
                          <img
                            alt="downArrow"
                            style={{ height: "12px" }}
                            src={images.sortDsc}
                          />
                        )}
                      </StyledTableCell>
                    );
                  })}
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {localStorage.getItem("user") === "hdfc_credila" ? (
              <>
                {tableData &&
                  tableData.map((row: any, rowIdx: any) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.SNO}_${rowIdx}`}
                      >
                        {columns.map((column, keyId) => {
                          if (column.id === "audio_url") {
                            return (
                              <StyledTableCell
                                className="sub-color"
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {audioFile(row.audio_url)}
                              </StyledTableCell>
                            );
                          }
                          if (column.id === "language") {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {getLanguageName(row[column.id])}
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={column.id + rowIdx + keyId}
                                align={column.align}
                              >
                                {row[column.id]}
                              </StyledTableCell>
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  })}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  
    <Popover
      open={isPopoverOpen}
      onClose={handleOnPopoverClose}
      anchorReference="anchorPosition"
      anchorPosition={eleRefPoint}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <span
        className="cs-modal-close"
        onClick={(e) => {
          handleOnPopoverClose();
        }}
      >
        X
      </span>
      {getPopoverContent()}
    </Popover>
  </Card>
  
  );
}

function audioFile(url: string) {
  const aud = <audio controls controlsList="nodownload" src={url}></audio>;
  return aud;
}
