import styled from "styled-components";
const SpeechInsights = styled.div`
grid-column: 2 / 5;
font-family: Roboto;
font-size: 11px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #fff;
padding-left:5px;
`
export default SpeechInsights;