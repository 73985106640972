import "./../App_Speech.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  PieController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import "react-h5-audio-player/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "./Toolbar.css";
import "./loader.css";
import Axios from "axios";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VocSearchDataTable from "./VoiceBot/VocSearchDataTable";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import * as _ from "lodash";
import { Pie } from "react-chartjs-2";
import VocTranscriptTable from "./VocTranscriptTable";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  PieController
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let variants = [
  {
    id: 1,
    label: "Positive",
    value: "positive",
  },
  {
    id: 2,
    label: "Negative",
    value: "negative",
  },
  {
    id: 3,
    label: "Neutral",
    value: "neutral",
  },
];

const VOCSummaryFilterUrl =
  process.env.REACT_APP_BASE_URL + "/VOCSearchBoxData";
const VOCSummaryUrl = process.env.REACT_APP_BASE_URL + "/searchVocSummary";
const VOCTranscriptUrl =
  process.env.REACT_APP_BASE_URL + "/vocTranscriptReport";
const vocFilter = process.env.REACT_APP_BASE_URL + "/VOCFilter";
const vocDataURL = process.env.REACT_APP_BASE_URL + "/getVoiceBotVocData";
const vocWordCluster = process.env.REACT_APP_BASE_URL + "/api/wordCloud";
const vocTranscriptFilter =
  process.env.REACT_APP_BASE_URL + "/vocDashBoardReportFilters";
const axios = require("axios");

axios.defaults.headers.common["user"] = localStorage.getItem("user");
var vocSearchObj = {};
var vocSearchDescriptions = [];
var vocSearchField = [];

export function logoutUser(ele) {
  localStorage.clear();
  sessionStorage.clear();
  const temp = window;
  temp.location = "/#/login";
}

const SpeechDemoVOC = () => {
  // alert("check");
  const [vocBarGraphData, setvocBarGraphData] = useState([]);
  const [vocBarGraphData2, setvocBarGraphData2] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [wordClusterData, setWordClusterData] = useState(null);
  const [vocSearchFieldFilters, setVOCsearchFieldFilters] = useState([]);
  const [vocCampaignFieldFilters, setVOCCampaignFieldFilters] = useState([]);
  const [jsonVocDataTranscript, setJsonVocDataTranscript] = useState({});
  const [jsonVocSummaryData, setJsonVocSummaryData] = useState({});
  const [load, setIsload] = useState(true);
  const [value, setValue] = useState("1");
  const [text, setText] = useState([]);
  const [vocSearchInputVal, setVocSearchInputVal] = React.useState([]);
  const [vocMatchInputVal, setVocMatchInputVal] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [vocReasonFilter, setVocReasonFilter] = useState([]);
  const [vocCallTypeFilter, setVocCallTypeFilter] = useState([]);
  const [vocCampaignFilter, setVocCampaignFilter] = useState([]);
  const [selectedReasonFilter, setSelectedReasonFilter] = useState([]);
  const [selectedTrxReasonFilter, setSelectedTrxReasonFilter] = useState([]);
  const [selectedTrxSentimentFilter, setSelectedTrxSentimentFilter] = useState(
    []
  );
  const [selectedTrxCallTypeFilter, setSelectedTrxCallTypeFilter] = useState(
    []
  );
  const [selectedTrxCampaignFilter, setSelectedTrxCampaignFilter] = useState(
    []
  );
  const [selectedSentimentFilter, setSelectedSentimentFilter] = useState([]);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState([]);
  const [selectedSummaryCampaignFilter, setSelectedSummaryCampaignFilter] =
    useState([]);
  const [vocFromDate, setVocFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [vocToDate, setVocTODate] = useState(moment().format("YYYY-MM-DD"));

  const getSearchData = (intent) => {
    let searchArray = [];
    for (const element of intent) {
      if (element.trim() !== "") {
        searchArray.push({ label: element, value: element });
        // searchArray.push({ label: element.length > 40 ? element.substr(0, 40) + "...." : element, value: element.length > 40 ? element.substr(0, 40) + "...." : element });
      }
    }
    return searchArray;
  };

  const handleOpen = () => {
    if (text.length > 0) {
      setOpen(true);
    }
  };
  const handleChangeVocSearch = (event, value) => {
    setVocSearchInputVal(value.label);
    setVocMatchInputVal(value.label);
  };
  const handleChangeVocSearchInput = (e) => {
    let finalSearchArr = [];
    let filterdSearchArr = [];
    let selectedMatch;
    let input = e.target.value.toLowerCase();
    setText(input);
    vocSearchFieldFilters.map((m) => {
      finalSearchArr.push(m.label);
    });
    // console.log(" final search options are", finalSearchArr);
    finalSearchArr.filter((option) => {
      let m = option.toLowerCase();
      if (m.includes(input)) {
        filterdSearchArr.push(m);
      }
    });
    selectedMatch = filterdSearchArr.join(";");
    if (e.target.value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setVocMatchInputVal(e.target.value);
  };
  const handleVocSearchPaste = (event) => {
    setVocSearchInputVal(event.clipboardData.getData("text"));
  };
  const handleKey = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation(); // Prevent the default Enter key behavior
      return;
    }
  };
  if (window.localStorage.getItem("user") === "speech_demo_voc") {
    useEffect(() => {
      fetchVocSearchTextData();
    }, []);
  }
  const findVocSearch = (name) => {
    for (const element of vocSearchObj) {
      if (element.name === name) {
        return element;
      }
    }
  };
  const handleChange = (event, newValue) => {
    // console.log("Tabs value", newValue);
    setValue(newValue);
  };

  const handleVocDates = (e, name) => {
    if (name === "startDate") {
      setVocFromDate(String(moment(e).format("YYYY-MM-DD")));
    } else {
      setVocTODate(String(moment(e).format("YYYY-MM-DD")));
    }
  };

  const handleVOCfilter = (e, name) => {
    if (name === "reason") {
      setSelectedReasonFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedCampaignFilter(e.target.value);
    } else {
      setSelectedSentimentFilter(e.target.value);
    }
  };

  const handleVOCTranscriptFilter = (e, name) => {
    if (name === "reason") {
      setSelectedTrxReasonFilter(e.target.value);
    } else if (name === "sentiment") {
      setSelectedTrxSentimentFilter(e.target.value);
    } else if (name === "call-type") {
      setSelectedTrxCallTypeFilter(e.target.value);
    } else if (name === "campaign") {
      setSelectedTrxCampaignFilter(e.target.value);
    }
  };
  const handleVOCSummaryFilter = (e, name) => {
    if ((name = "campaign")) {
      setSelectedSummaryCampaignFilter(e.target.value);
    }
  };
  if (window.localStorage.getItem("user") === "speech_demo_voc") {
    useEffect(() => {
      VOCfilter();
    }, []);
  }
  if (window.localStorage.getItem("user") === "speech_demo_voc") {
    useEffect(() => {
      vocdownloadFilter();
    }, []);
  }
  const VOCfilter = async () => {
    try {
      const filterData = await axios.get(vocFilter);
      if (!_.isEmpty(filterData)) {
        let formatedData = convertToSelectBoxFormat(filterData.data);
        if (!_.isEmpty(formatedData)) {
          setVocReasonFilter(formatedData);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };
  const vocdownloadFilter = async () => {
    try {
      const filterResponse = await axios.get(vocTranscriptFilter);
      if (!_.isEmpty(filterResponse)) {
        let formatedReaonTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.reason_types
        );
        if (!_.isEmpty(formatedReaonTypes)) {
          setVocReasonFilter(formatedReaonTypes);
        }
        let formatedCallTypes = convertToSelectBoxFormat(
          filterResponse.data.aggregations.call_types
        );
        if (!_.isEmpty(formatedCallTypes)) {
          setVocCallTypeFilter(formatedCallTypes);
        }
        let formatedCampaigns = convertToSelectBoxFormat(
          filterResponse.data.aggregations.campaigns
        );
        if (!_.isEmpty(formatedCampaigns)) {
          setVocCampaignFilter(formatedCampaigns);
          setVOCCampaignFieldFilters(formatedCampaigns);
        }
      }
    } catch (err) {
      setIsload(false);
      window.alert("Error Calling Api");
    }
  };
  let stopWordsArr = [
    "customer",
    "order",
    "receive",
    "product",
    "item",
    "issue",
    "deliver",
    "delivery",
    "complaint",
    "complain",
    "bigbasket",
    "website",
    "face",
  ];
  const getWordCloudImg = async (sentences) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // console.log(sentences);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sentences,
        width: "1150",
        height: "525",
        background_color: "white",
        stopwords: stopWordsArr,
        // min_font_size: 20,
      }),
      redirect: "follow",
    };

    await fetch(vocWordCluster, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        console.log(result);
        setWordClusterData(URL.createObjectURL(result));
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
  };
  const handleVocData = async () => {
    setIsload(true);
    let params = {};
    if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        sentiment: String(selectedSentimentFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        campaign: String(selectedCampaignFilter),
      };
    } else if (
      (_.isEmpty(selectedReasonFilter) || _.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        sentiment: String(selectedSentimentFilter),
        campaign: String(selectedCampaignFilter),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (!_.isEmpty(selectedSentimentFilter) ||
        !_.isNull(selectedSentimentFilter)) &&
      (_.isEmpty(selectedCampaignFilter) || _.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        sentiment: String(selectedSentimentFilter),
      };
    } else if (
      (!_.isEmpty(selectedReasonFilter) || !_.isNull(selectedReasonFilter)) &&
      (_.isEmpty(selectedSentimentFilter) ||
        _.isNull(selectedSentimentFilter)) &&
      (!_.isEmpty(selectedCampaignFilter) || !_.isNull(selectedCampaignFilter))
    ) {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        campaign: String(selectedCampaignFilter),
      };
    } else {
      params = {
        startDate: String(vocFromDate),
        endDate: String(vocToDate),
        reasonType: String(selectedReasonFilter),
        sentiment: String(selectedSentimentFilter),
        campaign: String(selectedCampaignFilter),
      };
    }

    await axios
      .get(vocDataURL, { params })
      .then((response) => {
        if (!_.isEmpty(response)) {
          const bardata1 =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.languages_spoken.buckets;
          const bardata2 =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.reason_types.buckets;

          const vocPieChart =
            response &&
            response.data &&
            response.data.aggregations &&
            response.data.aggregations.languages_spoken &&
            response.data.aggregations.sentiment_dist.buckets;

          const wordCloudSentences =
            response &&
            response.data &&
            response.data.hits &&
            response.data.hits.hits &&
            response.data.hits.hits.map((item) =>
              item._source &&
              item._source.summary_report &&
              item._source.summary_report.summary_info &&
              item._source.summary_report.summary_info.reason_for_uninstall &&
              item._source.summary_report.summary_info.reason_for_uninstall
                .reason &&
              item._source.summary_report.summary_info.reason_for_uninstall
                .reason.description &&
              !_.isUndefined(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              ) &&
              !_.isNil(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              ) &&
              !_.isNull(
                item._source.summary_report.summary_info.reason_for_uninstall
                  .reason.description
              )
                ? item._source.summary_report.summary_info.reason_for_uninstall.reason.description.join(
                    ", "
                  )
                : " "
            );
          // const wordCloudSentence =

          // response &&
          // response.data &&
          // response.data.hits &&
          // response.data.hits.hits &&
          // response.data.hits.hits.map((item) =>
          //   item._source &&
          //   item._source.summary_report &&
          //   item._source.summary_report.summary_info &&
          //   !_.isUndefined(
          //     item._source.summary_report.summary_info.issues_details
          //   ) &&
          //   !_.isNil(
          //     item._source.summary_report.summary_info.issues_details
          //   ) &&
          //   !_.isNull(
          //     item._source.summary_report.summary_info.issues_details
          //   )&&
          //   _.isString(item._source.summary_report.summary_info.issues_details)
          //     ? item._source.summary_report.summary_info.issues_details
          //     : " "
          // );
          if (!_.isEmpty(bardata1)) {
            setvocBarGraphData(convertToBarGraphFormat(bardata1));
          } else {
            setvocBarGraphData([]);
          }
          if (!_.isEmpty(bardata2)) {
            setvocBarGraphData2(convertToBarGraphFormat(bardata2));
          } else {
            setvocBarGraphData2([]);
          }
          if (!_.isEmpty(vocPieChart)) {
            setPieChartData(vocPieChart);
          } else {
            setPieChartData([]);
          }
          if (!_.isEmpty(wordCloudSentences)) {
            getWordCloudImg(wordCloudSentences);
          }
        }
      })
      .catch((error) => {
        setIsload(false);
        window.alert("Error Calling Api");
        console.error("Error:", error);
      });
    setIsload(false);
  };
  const fetchVocSearchTextData = async () => {
    sessionStorage.removeItem("vocsearchData");
    const vocsearch = await Axios(VOCSummaryFilterUrl);
    sessionStorage.setItem(
      "vocsearchData",
      JSON.stringify(vocsearch.data.aggregation)
    );
    if (typeof sessionStorage.vocsearchData !== "undefined") {
      vocSearchObj = JSON.parse(sessionStorage.vocsearchData);
    }
    const searchDescriptions = findVocSearch(
      "sterms#reason_description",
      vocSearchObj
    );
    vocSearchDescriptions = JSON.stringify(searchDescriptions["labels"]);
    if (typeof vocSearchDescriptions !== "undefined") {
      vocSearchField = JSON.parse(vocSearchDescriptions);
    }
    const vocSearchArr = getSearchData(vocSearchField);
    setVOCsearchFieldFilters(vocSearchArr);
    return {
      vocSearchFieldFilters: vocSearchFieldFilters,
    };
  };
  const fetchVocSummariesData = async (
    vocFromDate,
    vocToDate,
    vocMatchInputVal,
    selectedSummaryCampaignFilter,    
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCSummaryUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            keyPhrase: String(vocMatchInputVal),
            campaign: String(selectedSummaryCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCSummaryUrl);
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };
  if (window.localStorage.getItem("user") === "bigbasket_voc") {
    useEffect(() => {}, [jsonVocSummaryData]);
  }
  const fetchVocSummariesDataCallBack = async (
    vocFromDate,
    vocToDate,
    vocMatchInputVal,
    selectedSummaryCampaignFilter,    
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCSummaryUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            keyPhrase: String(vocMatchInputVal),
            campaign: String(selectedSummaryCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCSummaryUrl);
        if (response) {
          setJsonVocSummaryData(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };
  if (window.localStorage.getItem("user") === "speech_demo_voc") {
    useEffect(() => {
      fetchVocTranscriptData(vocFromDate);
    }, []);
  }
  const fetchVocTranscriptData = async (
    vocFromDate,
    vocToDate,
    selectedTrxReasonFilter,
    selectedTrxSentimentFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            reasonType: String(selectedTrxReasonFilter),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            campaign: String(selectedTrxCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      setIsload(false);
      console.log(error);
      window.alert("Error Calling Api");
    }
  };
  if (window.localStorage.getItem("user") === "speech_demo_voc") {
    useEffect(() => {}, [jsonVocDataTranscript]);
  }
  const fetchVocTranscriptDataCallBack = async (
    vocFromDate,
    vocToDate,
    selectedTrxReasonFilter,
    selectedTrxSentimentFilter,
    selectedTrxCallTypeFilter,
    selectedTrxCampaignFilter,
    size,
    from
  ) => {
    try {
      setIsload(!load);
      if (vocFromDate) {
        const response = await axios.get(VOCTranscriptUrl, {
          params: {
            startDate: String(vocFromDate),
            endDate: String(vocToDate),
            reasonType: String(selectedTrxReasonFilter),
            sentiment: String(selectedTrxSentimentFilter),
            callType: String(selectedTrxCallTypeFilter),
            campaign: String(selectedTrxCampaignFilter),
            size: size ? size : 20,
            from: from ? from : 0,
          },
        });
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      } else {
        const response = await axios.get(VOCTranscriptUrl);
        if (response) {
          setJsonVocDataTranscript(response.data);
          setIsload(false);
        }
      }
    } catch (error) {
      window.alert("Error Calling Api");
      setIsload(false);
      console.log(error);
    }
  };

  const convertToSelectBoxFormat = (arrayElement) => {
    if (arrayElement) {
      arrayElement.map((ele) => {
        ele.label = ele.doc_count;
        ele.value = ele.key;
        delete ele.doc_count;
        delete ele.key;
      });
    }
    return arrayElement;
  };
  const convertToBarGraphFormat = (arrayElement) => {
    arrayElement.map((ele) => {
      ele.value = ele.doc_count;
      ele.text = ele.key;
      delete ele.doc_count;
      delete ele.key;
    });
    return arrayElement;
  };

  const PieChart = ({ data }) => {
    // Extracting labels and values from the data
    const labels = data.map((item) => item.key);
    const values = data.map((item) => item.doc_count);
    const filteredLabels = labels.filter((label) => label.trim() !== "");
    const filteredData = values.filter(
      (_, index) => labels[index].trim() !== ""
    );
    const filteredChartData = {
      labels: filteredLabels,
      datasets: [
        {
          data: filteredData,
          backgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the colors
          hoverBackgroundColor: ["#1876D1", "#ECC1C9", "#FFBF69"], // Customize the hover colors
        },
      ],
    };
    const pieOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          position: "top",
          align: true,
          text: "Sentiment Overview",
        },
        legend: {
          symbolWidth: "4px",
          symbolHeight: "5px",
          display: true,
          labels: {
            color: "rgb(255, 99, 132)",
          },
          position: "right",
          align: true,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const label = tooltipItem.label;
              const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
              return `${label} :: ${value}`;
            },
          },
        },
        datalabels: {
          display: true,
        },
      },
    };
    return (
      <Pie
        data={filteredChartData}
        options={pieOptions}
        plugins={[ChartDataLabels]}
      />
    );
  };

  const BarGraph = ({ data }) => {
    const chartData = {
      labels: data.map((item) => item.text),
      datasets: [
        {
          label: "Data",
          data: data.map((item) => item.value),
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      animation: false,
      scales: {
        y: {
          beginAtZero: true,
          max: Math.max(...data.map((item) => item.value)) + 10,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return <Bar data={chartData} options={chartOptions} />;
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div className="logo-container">
            <h1>Voice Of Customer</h1>
          </div>
        </Grid>
        <Grid item xs={2}>
          <span className="loggedInuser font-user">USER:</span>
          <span className="font-user">
            {" "}
            {window.localStorage.getItem("user")} |{" "}
          </span>
          <span className="logout-title font-user" onClick={logoutUser}>
            Logout
          </span>
        </Grid>

        <Box
          sx={{
            width: "100%",
            typography: "body1",
            pointerEvents: load ? "none" : "auto",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="VOC Analysis" value="1" />
                <Tab label="Summaries" value="2" />
                <Tab label="Transcripts" value="3" />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              style={{ pointerEvents: load ? "none" : "auto" }}
            >
              <div className="voc-filters-search-box-container">
                <Grid container item spacing={2}>
                  {/* <Grid item xs={1.5}> */}
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={vocFromDate}
                      onChange={(e) => {
                        handleVocDates(e, "startDate");
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="End Date"
                      value={vocToDate}
                      onChange={(e) => {
                        handleVocDates(e, "endDate");
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-checkbox">
                      Complaints
                    </InputLabel>
                    <Select
                      value={selectedReasonFilter}
                      onChange={(e) => {
                        handleVOCfilter(e, "reason");
                      }}
                      labelId="demo-multiple-checkbox"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Reason" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {vocReasonFilter.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={selectedReasonFilter.includes(
                              option.value
                            )}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-agent">Sentiment</InputLabel>
                    <Select
                      value={selectedSentimentFilter}
                      onChange={(e) => {
                        handleVOCfilter(e, "sentiment");
                      }}
                      labelId="demo-multiple-agent"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Agent" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {variants.map((value) => (
                        <MenuItem key={value.id} value={value.value}>
                          <Checkbox
                            checked={selectedSentimentFilter.includes(
                              value.value
                            )}
                          />
                          <ListItemText primary={value.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-checkbox">
                      Campaign
                    </InputLabel>
                    <Select
                      value={selectedCampaignFilter}
                      onChange={(e) => {
                        handleVOCfilter(e, "campaign");
                      }}
                      labelId="demo-multiple-checkbox"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Campaign" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {vocCampaignFilter.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={selectedCampaignFilter.includes(
                              option.value
                            )}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <Button
                    variant="contained"
                    style={{ padding: "12px 10px", width: "90px" }}
                    onClick={handleVocData}
                  >
                    Search
                  </Button>
                </Grid>
              </div>
              <br></br>
              <Grid item xs={1}></Grid>
              {!_.isEmpty(vocBarGraphData) ||
              !_.isEmpty(vocBarGraphData2) ||
              !_.isEmpty(pieChartData) ? (
                <Grid container spacing={3}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={3}>
                        <div className="voc-bar-graph">
                          <label className="voc-graphs-header-names">
                            Complaint Types Overview Distribution
                          </label>
                          {!_.isEmpty(vocBarGraphData2) && (
                            <BarGraph data={vocBarGraphData2} />
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      {!_.isEmpty(pieChartData) && (
                        <div className="voc-word-cloud-container">
                          <Paper>
                            <label className="voc-graphs-header-names">
                              Issue Cloud
                            </label>
                            <img
                              src={wordClusterData}
                              className="word-cloud-properties"
                              alt="logo"
                            />
                          </Paper>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={3} className="voc-pie-chart-box">
                        <div className="voc-pie-chart">
                          {pieChartData && <PieChart data={pieChartData} />}
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <div className="voc-no-data-found-container">
                  No Data found ...
                </div>
              )}
            </TabPanel>

            <TabPanel
              value="2"
              style={{ pointerEvents: load ? "none" : "auto" }}
            >
              <Grid item xs={4}>
                <Autocomplete
                  style={{ marginTop: "-33px" }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={vocSearchFieldFilters}
                  autoHighlight={true}
                  getOptionLabel={(option) => {
                    if (option["label"]) {
                      return option["label"];
                    }
                    if (typeof option === "string") {
                      return option;
                    }
                    return "";
                  }}
                  value={vocSearchInputVal}
                  onChange={handleChangeVocSearch}
                  // onKeyPress = {handleKey}
                  open={open}
                  filterSelectedOptions
                  includeInputInList
                  onOpen={handleOpen}
                  onClose={() => setOpen(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search input at VOC"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        onChange: handleChangeVocSearchInput,
                        onPaste: handleVocSearchPaste,
                        onKeyDown: handleKey,
                        // onKeyUp: handleKey,
                      }}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.value, inputValue, {
                      insideWords: true,
                    });
                    const parts = parse(option.value, matches);
                    if (
                      inputValue &&
                      !_.isEmpty(inputValue) &&
                      option === inputValue
                    ) {
                      // Render input value as the first option
                      return (
                        <div>
                          <li {...props}>
                            <div>
                              <span style={{ fontWeight: 700 }}>
                                {inputValue}
                              </span>
                            </div>
                          </li>
                        </div>
                      );
                    }
                    return (
                      <div>
                        {/* <div>hello</div> */}
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      </div>
                    );
                  }}
                />
              </Grid>
              <br></br> <br></br>
              <Grid container item spacing={2}>
                <div className="date-container">&nbsp;</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    disableFuture
                    // hideTabs
                    showTodayButton
                    maxDate={moment().toDate()}
                    todayText="now"
                    label="Start Date"
                    value={vocFromDate}
                    onChange={(e) => {
                      handleVocDates(e, "startDate");
                    }}
                  />
                </LocalizationProvider>
                <div className="date-container">&nbsp;</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "124px" }} />
                    )}
                    disableFuture
                    // hideTabs
                    showTodayButton
                    maxDate={moment().toDate()}
                    todayText="now"
                    label="End Date"
                    value={vocToDate}
                    onChange={(e) => {
                      handleVocDates(e, "endDate");
                    }}
                  />
                </LocalizationProvider>
                <div className="date-container">&nbsp;</div>
                <FormControl style={{ width: "125px" }}>
                  <InputLabel id="demo-multiple-checkbox">Campaign</InputLabel>
                  <Select
                    value={selectedSummaryCampaignFilter}
                    onChange={(e) => {
                      handleVOCSummaryFilter(e, "campaign");
                    }}
                    labelId="demo-multiple-checkbox"
                    id="demo-multiple-checkbox"
                    multiple
                    input={<OutlinedInput label="Campaign" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {vocCampaignFieldFilters.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        <Checkbox
                          checked={selectedSummaryCampaignFilter.includes(
                            option.value
                          )}
                        />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="date-container">&nbsp;</div>
                <FormControl>
                  <Button
                    variant="contained"
                    style={{ padding: "12px 14px" }}
                    onClick={() => {
                      fetchVocSummariesData(
                        vocFromDate,
                        vocToDate,
                        vocMatchInputVal,
                        selectedSummaryCampaignFilter
                      );
                    }}
                  >
                    Search
                  </Button>
                </FormControl>
                {load ? (
                  <div className="loader_digital" id="loader_digital"></div>
                ) : null}
                <Grid item xs={12}>
                  {!_.isEmpty(jsonVocSummaryData) && (
                    <VocSearchDataTable
                      fetchVocSummarydata={jsonVocSummaryData}
                      fetchVOCSummaryFunction={fetchVocSummariesDataCallBack}
                      fetchData={{
                        fetchVocSummariesDataCallBack,
                        startDate: vocFromDate,
                        endDate: vocToDate,
                        keyPhrase: String(vocMatchInputVal),
                        campaign: String(selectedTrxCampaignFilter),
                      }}
                    ></VocSearchDataTable>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value="3"
              style={{ pointerEvents: load ? "none" : "auto" }}
            >
              <div className="voc-filters-search-box-container">
                <Grid container item spacing={2}>
                  {/* <Grid item xs={1.5}> */}
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="Start Date"
                      value={vocFromDate}
                      onChange={(e) => {
                        handleVocDates(e, "startDate");
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField {...props} style={{ width: "124px" }} />
                      )}
                      disableFuture
                      showTodayButton
                      maxDate={moment().toDate()}
                      todayText="now"
                      label="Start Date Time"
                      value={vocToDate}
                      onChange={(e) => {
                        handleVocDates(e, "endDate");
                      }}
                    />
                  </LocalizationProvider>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-checkbox">
                      Complaints
                    </InputLabel>
                    <Select
                      value={selectedTrxReasonFilter}
                      onChange={(e) => {
                        handleVOCTranscriptFilter(e, "reason");
                      }}
                      labelId="demo-multiple-checkbox"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Reason" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {vocReasonFilter.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={selectedTrxReasonFilter.includes(
                              option.value
                            )}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-agent">Sentiment</InputLabel>
                    <Select
                      value={selectedTrxSentimentFilter}
                      onChange={(e) => {
                        handleVOCTranscriptFilter(e, "sentiment");
                      }}
                      labelId="demo-multiple-agent"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Agent" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {variants.map((value) => (
                        <MenuItem key={value.id} value={value.value}>
                          <Checkbox
                            checked={selectedTrxSentimentFilter.includes(
                              value.value
                            )}
                          />
                          <ListItemText primary={value.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-checkbox">
                      CallTypes
                    </InputLabel>
                    <Select
                      value={selectedTrxCallTypeFilter}
                      onChange={(e) => {
                        handleVOCTranscriptFilter(e, "call-type");
                      }}
                      labelId="demo-multiple-checkbox"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="CallType" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {vocCallTypeFilter.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={selectedTrxCallTypeFilter.includes(
                              option.value
                            )}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl style={{ width: "125px" }}>
                    <InputLabel id="demo-multiple-checkbox">
                      Campaign
                    </InputLabel>
                    <Select
                      value={selectedTrxCampaignFilter}
                      onChange={(e) => {
                        handleVOCTranscriptFilter(e, "campaign");
                      }}
                      labelId="demo-multiple-checkbox"
                      id="demo-multiple-checkbox"
                      multiple
                      input={<OutlinedInput label="Campaign" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {vocCampaignFilter.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={selectedTrxCampaignFilter.includes(
                              option.value
                            )}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="date-container">&nbsp;</div>
                  <FormControl>
                    <Button
                      variant="contained"
                      style={{ padding: "12px 10px", width: "90px" }}
                      onClick={() => {
                        fetchVocTranscriptData(
                          vocFromDate,
                          vocToDate,
                          selectedTrxReasonFilter,
                          selectedTrxSentimentFilter,
                          selectedTrxCallTypeFilter,
                          selectedTrxCampaignFilter
                        );
                      }}
                    >
                      Search
                    </Button>
                  </FormControl>
                  {load ? (
                    <div className="loader_digital" id="loader_digital"></div>
                  ) : null}
                  <Grid item xs={12}>
                    <VocTranscriptTable
                      fetchVocTranscriptData={jsonVocDataTranscript}
                      fetchVOCTranscriptFunction={
                        fetchVocTranscriptDataCallBack
                      }
                      fetchData={{
                        fetchVocTranscriptDataCallBack,
                        startDate: vocFromDate,
                        endDate: vocToDate,
                        reasonType: String(selectedTrxReasonFilter),
                        sentiment: String(selectedTrxSentimentFilter),
                        callType: String(selectedTrxCallTypeFilter),
                        campaign: String(selectedTrxCampaignFilter),
                      }}
                    ></VocTranscriptTable>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
      {load ? <div className="loader_digital" id="loader_digital"></div> : null}
    </Container>
  );
};
export default SpeechDemoVOC;
