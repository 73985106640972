import React from "react";
import "./Voicebot.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { ArcElement, Chart, Legend, PieController, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import * as _ from "lodash";
Chart.register(ArcElement, PieController, Legend, Tooltip);

function PieCharts(props) {
  const labels = props && props.data && props.data.labels;
  const docCounts = props && props.data && props.data.docCounts;
  const data = {
    labels: labels || [],
    datasets: [
      {
        label: "My First Dataset",
        data: docCounts || [],
        backgroundColor: [
          "#0263ff",
          "#4788ff",
          "#aed1fb",
          "#828282",
          "#e5e5e5",
          "#0263f0",
          "#478855",
          "#aed144",
          "#828284",
          "#e5e5e4",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        symbolWidth: "4px",
        symbolHeight: "5px",
        display: true,
        labels: {
          color: "rgb(255, 99, 132)",
        },
        position: "right",
        align: true,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label;
            const value = tooltipItem.dataset.data[tooltipItem.dataIndex];

            return `${label} > ${value}`;
          },
        },
      },
      datalabels: {
        display: true,
      },
    },
  };

  return (
    <div>
      <label className="pieChart-label">Utterances by Intents</label>
      <Pie data={data} options={options} />
    </div>
  );
}

export default function VoiceBot(props) {
  const voiceUtteranceData =
    props &&
    props.voiceUtteranceData &&
    props.voiceUtteranceData.data &&
    props.voiceUtteranceData.data.hits &&
    props.voiceUtteranceData.data.hits.hits.map((item) => item._source);

  const voiceUtterColumnDefs =
    voiceUtteranceData &&
    voiceUtteranceData.length > 0 &&
    Object.keys(voiceUtteranceData[0]).map((key) => {
      return key === "audio_url"
        ? {
          headerName: key,
          field: key,
          cellRenderer: "customCellRenderer",
          minWidth: 400,
        }
        : { headerName: key, field: key };
    });

  const voiceCallDetails =
    props &&
    props.voiceData &&
    props.voiceData.data &&
    props.voiceData.data.hits &&
    props.voiceData.data.hits.hits.map((item) => item._source);

  const voiceCallColumnDefs =
    voiceCallDetails &&
    voiceCallDetails.length > 0 &&
    Object.keys(voiceCallDetails[0]).map((key) => {
      return key === "audio_url"
        ? {
          headerName: key,
          field: key,
          cellRenderer: "customCellRenderer",
          minWidth: 400,
        }
        : { headerName: key, field: key };
    });

  const SummaryIntentColumnDefs = [
    { headerName: "Intent", field: "intent" },
    { headerName: "Transcript", field: "transcript" },
    { headerName: "Transaction Type", field: "transaction_type" },
    { headerName: "Context Name", field: "context_name" },
    { headerName: "Hung Up By", field: "hungup_by" },
  ];
  const tempColumnDefs = [
    { headerName: "Transaction_id", field: "transaction_id" },
    { headerName: "Call_time", field: "call_time" },
    { headerName: "Call_date", field: "call_date" },
    { headerName: "Cid", field: "cid" },
    { headerName: "Hung Up By", field: "hungup_by" },
  ];

  const buckets =
    props.voiceData &&
    props.voiceData.data &&
    props.voiceData.data.aggregations &&
    props.voiceData.data.aggregations.intent_count_dist &&
    props.voiceData.data.aggregations.intent_count_dist.buckets;

  const callCountBucket =
    props.voiceData &&
    props.voiceData.data &&
    props.voiceData.data.aggregations &&
    props.voiceData.data.aggregations.call_count &&
    props.voiceData.data.aggregations.call_count.buckets;

  let lengthOfCallCount = callCountBucket && _.size(callCountBucket);
  // console.log("length of call_count_bucket", callCountBucket.length());
  const callDoccount =
    callCountBucket && callCountBucket.map((item) => item.doc_count);
  const callCount =
    callDoccount &&
    callDoccount.reduce((accumulator, item) => {
      if (typeof item === "number" && !isNaN(item)) {
        return accumulator + item;
      }
      return accumulator;
    }, 0);

  const labels = buckets && buckets.map((item) => item.key);
  const docCounts = buckets && buckets.map((item) => item.doc_count);
  const untrances =
    docCounts &&
    docCounts.reduce((accumulator, item) => {
      if (typeof item === "number" && !isNaN(item)) {
        return accumulator + item;
      }
      return accumulator;
    }, 0);

  const intents =
    props.voiceData &&
    props.voiceData.data &&
    props.voiceData.data.aggregations &&
    props.voiceData.data.aggregations.total_intent_count &&
    props.voiceData.data.aggregations.total_intent_count.value;
  const intentOnly = intents && voiceUtteranceData &&
    intents - voiceUtteranceData.length;
  const intentRecognizationCount =
    intents &&
      intentOnly &&
      intents > intentOnly ? parseFloat(((intentOnly / intents) * 100).toFixed(2)) :
      parseFloat(((intents / intentOnly) * 100).toFixed(2));
  const CustomCellRenderer = ({ value }) => {
    return (
      <audio controls controlsList="nodownload">
        <source src={value} type="audio/mpeg" />
      </audio>
    );
  };

  const frameworkComponents = { customCellRenderer: CustomCellRenderer };
  return (
    <div className="voicebot-body-container">
      <div className="voicebot-totalcalls">
        <div className="voicebot-details-box">
          {lengthOfCallCount && (
            <label className="voicebot-card-label">{lengthOfCallCount}</label>
          )}
          <label className="voicebot-card-label-name">TotalCalls</label>
        </div>
        <div className="voicebot-details-box">
          {lengthOfCallCount && (
            <label className="voicebot-card-label">{lengthOfCallCount}</label>
          )}
          <label className="voicebot-card-label-name">Connected Calls</label>
        </div>
        <div className="voicebot-details-box">
          {untrances && (
            <label className="voicebot-card-label">{untrances}</label>
          )}
          <label className="voicebot-card-label-name">Utterances</label>
        </div>
        <div className="voicebot-details-box">
          {intents && <label className="voicebot-card-label">{intents}</label>}
          <label className="voicebot-card-label-name">Intents</label>
        </div>
        <div className="voicebot-details-box">
          {intentRecognizationCount && (
            <label className="voicebot-card-label">
              {intentRecognizationCount + "%"}
            </label>
          )}
          <label className="voicebot-card-label-name">Intent Recognition</label>
        </div>
      </div>
      <div className="voicebot-content">
        <div className="voicebot-content-box1">
          <div style={{ height: "350px", width: "360px", marginLeft: "100px" }}>
            <PieCharts data={{ labels: labels, docCounts: docCounts }} />
          </div>
        </div>

        <div className="voicebot-content-box2">
          <div className="ag-theme-alpine voice-bot-ag-grid voice-bot-ag-grid-2">
            <label className="table-label"> Summary of intents</label>
            <AgGridReact
              columnDefs={SummaryIntentColumnDefs}
              rowData={voiceCallDetails}
            />
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine voice-bot-ag-grid voice-bot-ag-grid-1">
        <label className="table-label"> Call detail View</label>

        <AgGridReact
          columnDefs={
            voiceCallColumnDefs ? voiceCallColumnDefs : tempColumnDefs
          }
          rowData={voiceCallDetails}
          frameworkComponents={frameworkComponents}
        />
      </div>
      <div className="ag-theme-alpine voice-bot-ag-grid voice-bot-ag-grid-3">
        <label className="table-label"> Unidentified Utterancess</label>
        <AgGridReact
          columnDefs={
            voiceUtterColumnDefs ? voiceUtterColumnDefs : tempColumnDefs
          }
          rowData={voiceUtteranceData}
          frameworkComponents={frameworkComponents}
        />
      </div>
    </div>
  );
}
