import axios from "axios";
const baseURL = "/endpoint";
const currentURL = "http://localhost:3000";

const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    },
});
axios.defaults.params = {}
export default instance;
