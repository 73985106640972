import React from "react";
import "../App.css";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

const header = ["Parameters", "Score"];
const columns = [
  { id: "score_count", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "hold_dead_air_probing",
    label: "Hold Dead Air Probing",
    minWidth: 150,
  },
  {
    id: "customer_profiling_effective_probing",
    label: "Customer Profiling Effective Probing",
    minWidth: 150,
  },
  { id: "discount_offered", label: "Discount Offered", minWidth: 150 },
  {
    id: "upselling_pitch_for_pack_upgrade",
    label: "Upselling Pitch for Pack Upgrade",
    minWidth: 150,
  },
  {
    id: "sales_closure_or_sales_rebuttals",
    label: "Sales Closure or Sales Rebuttals",
    minWidth: 150,
  },
  { id: "product_presentation", label: "Product Presentation" },
  { id: "empathy", label: "Empathy", minWidth: 100 },
  {
    id: "speech_rate_and_clarity",
    label: "Speech Rate and Clarity",
    minWidth: 120,
  },
  { id: "active_listening", label: "Active Listening", minWidth: 150 },
  {
    id: "courteous_and_professionality",
    label: "Courteous and Professionality",
    minWidth: 120,
  },
  {
    id: "conversational_ability",
    label: "Conversational Ability",
    minWidth: 120,
  },
  { id: "tone", label: "Tone", minWidth: 150 },
];
const columns_poonawalla = [
  { id: "energetic_opening", label: "Energetic Opening", minWidth: 300 },
  {
    id: "acknowledgement_comprehension",
    label: "Acknowledgement Comprehensive",
    minWidth: 300,
  },
  {
    id: "closure_script_further_assistance",
    label: "Closure Script Further Assistance",
    minWidth: 300,
  },
  { id: "hold_script", label: "Hold Script", minWidth: 150 },
];
const columns_shobha = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 150,
  },
  {
    id: "providing_project_information",
    label: "Providing Project Information",
    minWidth: 150,
  },
  { id: "site_visit_pitch", label: "Site Visit Pitch", minWidth: 150 },
  {
    id: "rate_of_speech",
    label: "Rate of Speech",
    minWidth: 150,
  },
  {
    id: "volume",
    label: "Volume",
    minWidth: 150,
  },
  { id: "noisy_calls", label: "Noisy Calls" },
  {
    id: "interruptions",
    label: "Interruptions",
    minWidth: 120,
  },
  {
    id: "participation_ratio",
    label: "Participation Ratio",
    minWidth: 120,
  },
  {
    id: "dead_air",
    label: "Dead Air",
    minWidth: 120,
  },
];
const columns_adret = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  { id: "product_upsell", label: "Product Upsell", minWidth: 150 },
  { id: "usage_and_dosage", label: "Usage And Dosage", minWidth: 150 },
  { id: "payment_link_sent", label: "Payment Link Sent", minWidth: 100 },
  { id: "benifits", label: "Benifits", minWidth: 120 },
  { id: "customer_interest", label: "Customer Interest", minWidth: 120 },
];
const columns_wellness = [
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "closing", label: "Call Closing", minWidth: 100 },
  { id: "upselling", label: "Upselling", minWidth: 120 },
  { id: "customer_value", label: "Customer Value", minWidth: 120 },
  { id: "hold_process", label: "Payment Link Sent", minWidth: 100 },
  { id: "assurance", label: "Assurance", minWidth: 120 },
  {
    id: "informing_correct_TAT_for_resolution",
    label: "Informing Correct TAT for Resolution",
    minWidth: 160,
  },
  {
    id: "active_listening_and_interruption",
    label: "Active Listening and Interruption",
    minWidth: 150,
  },
  { id: "empathy", label: "Empathy", minWidth: 100 },
  {
    id: "verifying_the_customer",
    label: "Verifying the Customer",
    minWidth: 150,
  },
  { id: "further_assistance", label: "Further Assistance", minWidth: 120 },
];
const columns_clearTax = [
  { id: "score", label: "Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "call_closing", label: "Call Closing", minWidth: 100 },
  {
    id: "rate_of_speech",
    label: "Rate of Speech",
    minWidth: 150,
  },
  {
    id: "volume",
    label: "Volume",
    minWidth: 150,
  },
  { id: "noisy_calls", label: "Noisy Calls" },
  {
    id: "interruptions",
    label: "Interruptions",
    minWidth: 120,
  },
  {
    id: "participation_ratio",
    label: "Participation Ratio",
    minWidth: 120,
  },
  {
    id: "dead_air",
    label: "Dead Air",
    minWidth: 120,
  },
];
const columns_matrimony = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "call_opening", label: "Call Opening", minWidth: 350 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 300 },
  { id: "interest_creation", label: "Interest Creation", minWidth: 300 },
  { id: "objection_handling", label: "Object Handling", minWidth: 300 },
  { id: "package_pitch", label: "Package Pitch", minWidth: 150 },
  {
    id: "convincing_and_need_creation",
    label: "Convincing and Need Creation",
    minWidth: 350,
  },
  { id: "product_usp", label: "Product USP", minWidth: 300 },
  {
    id: "price_pitch_and_discount",
    label: "Price Pitch and Discount",
    minWidth: 300,
  },
  {
    id: "utilize_opportunities",
    label: "Utilize Opportunities",
    minWidth: 300,
  },
  { id: "customer_engagement", label: "Customer Engagement", minWidth: 150 },
  { id: "active_listening", label: "Active Listening", minWidth: 300 },
  {
    id: "tone_and_voice_modulation",
    label: "Tone and Voice Modulation",
    minWidth: 300,
  },
  {
    id: "confidence_and_enthusiasm",
    label: "Confidence and Enthusiasm",
    minWidth: 150,
  },
  { id: "urgency_creation", label: "Urgency Creation", minWidth: 300 },
  {
    id: "summarization_and_call_closing",
    label: "Summarization and Call Closing",
    minWidth: 300,
  },
];

const columns_sunteck_realty = [
{ id: "agent_names", label: "Agent Name", minWidth: 200 },
{ id: "score", label: "Score", minWidth: 100 },
{ id: "score_fatal", label: "Fatal Score", minWidth: 100 },
{ id:"calls",label: "Calls", minWidth: 100 },
{ id: "callopening_5sec", label: "Call Opening (5 Sec)", minWidth: 100 },
{ id: "opening_greeting", label: "Opening Greeting", minWidth: 100 },
{ id: "opening_selfintro", label: "Self Introduction", minWidth: 100 },
{ id: "brand_name_check", label: "Brand Name Check", minWidth: 100 },
{ id: "paraphrasing", label: "Paraphrasing", minWidth: 100 },
{ id: "showing_intresting_thanking_outbound", label: "Showing Interest & Thanking (Outbound)", minWidth: 150 },
{ id: "requirements_understanding", label: "Understanding Requirements", minWidth: 150 },
{ id: "looking_for_underconstruction_or_readytomove", label: "Looking for Under Construction or Ready to Move", minWidth: 200 },
{ id: "offering_assistance_opening", label: "Offering Assistance (Opening)", minWidth: 150 },
{ id: "name_confirmation_outbound", label: "Name Confirmation (Outbound)", minWidth: 150 },
{ id: "continuing_previous_interaction", label: "Continuing Previous Interaction", minWidth: 180 },
{ id: "budget_check", label: "Budget Check", minWidth: 100 },
{ id: "configurations_carpetarea_check", label: "Configuration & Carpet Area Check", minWidth: 180 },
{ id: "customer_location_check", label: "Customer Location Check", minWidth: 150 },
{ id: "investment_self_check", label: "Investment Self Check", minWidth: 150 },
{ id: "objection_handling_check", label: "Objection Handling", minWidth: 150 },
{ id: "effective_timelyresponses_check", label: "Effective & Timely Responses", minWidth: 180 },
{ id: "urgencey_creation", label: "Urgency Creation", minWidth: 150 },
{ id: "correct_property_location_check", label: "Correct Property Location Check", minWidth: 200 },
{ id: "correct_usp_check", label: "Correct USP Check", minWidth: 150 },
{ id: "correct_configuration_check", label: "Correct Configuration Check", minWidth: 180 },
{ id: "correct_pricing_check", label: "Correct Pricing Check", minWidth: 150 },
{ id: "correct_amenities_check", label: "Correct Amenities Check", minWidth: 180 },
{ id: "campaign_scheme", label: "Campaign Scheme", minWidth: 150 },
{ id: "offer_payment_plan", label: "Offer Payment Plan", minWidth: 150 },
{ id: "correct_possession_timeline", label: "Correct Possession Timeline", minWidth: 200 },
{ id: "confidence_check", label: "Confidence Check", minWidth: 150 },
{ id: "active_listeining", label: "Active Listening", minWidth: 150 },
{ id: "empathy_assurance_check", label: "Empathy & Assurance", minWidth: 150 },
{ id: "interruption_avoidence", label: "Interruption Avoidance", minWidth: 180 },
{ id: "tone_modulation", label: "Tone Modulation", minWidth: 150 },
{ id: "energetic_sound", label: "Energetic Sound", minWidth: 150 },
{ id: "fillers", label: "Fillers", minWidth: 100 },
{ id: "rate_of_speech", label: "Rate of Speech", minWidth: 150 },
{ id: "rudeness_avoidence_check", label: "Rudeness Avoidance", minWidth: 150 },
{ id: "abusiveness_avoidence_check", label: "Abusiveness Avoidance", minWidth: 180 },
{ id: "hold_placing", label: "Hold Placing", minWidth: 150 },
{ id: "hold_duration", label: "Hold Duration", minWidth: 150 },
{ id: "language_switch", label: "Language Switch", minWidth: 150 },
{ id: "personalization", label: "Personalization", minWidth: 150 },
{ id: "jargons_avoidence", label: "Jargon Avoidance", minWidth: 150 },
{ id: "pick_and_drop_check", label: "Pick and Drop Check", minWidth: 180 },
{ id: "virtual_presentation_check", label: "Virtual Presentation Check", minWidth: 200 },
{ id: "followup_visit_date_time_check", label: "Follow-up Visit Date & Time Check", minWidth: 200 },
{ id: "summarization", label: "Summarization", minWidth: 150 },
{ id: "thanking_closing", label: "Thanking (Closing)", minWidth: 150 },
{ id: "greeting_closing", label: "Greeting (Closing)", minWidth: 150 },

];

const columns_hdfc_credila = [
  { id: "agent_names", label: "Agent Name", minWidth: 250 },
    { id: "score", label: "Score without fatal", minWidth: 100 },
    { id: "score_fatal", label: "score with fatal", minWidth: 100 },
    { id: "calls", label: "Calls", minWidth: 100 },

    { id: "call_opening_thanking", label: "Call Opening Thanking", minWidth: 150 },
    { id: "opening_selfintro", label: "Opening Self Introduction", minWidth: 150 },
    { id: "brand_name_check", label: "Brand Name Mention", minWidth: 150 },
    { id: "offering_assistance_opening", label: "Offering Assistance (Opening)", minWidth: 180 },
    { id: "empathy_check", label: "Empathy Check", minWidth: 150 },
    { id: "apology_check", label: "Apology Check", minWidth: 150 },
    { id: "acknowledgement_check", label: "Acknowledgement Check", minWidth: 180 },
    { id: "agentactive_listeining", label: "Agent Active Listening", minWidth: 180 },
    { id: "jargons_avoidence", label: "Jargon Avoidance", minWidth: 160 },
    { id: "fumbling_stammering", label: "Fumbling / Stammering", minWidth: 180 },
    { id: "energetic_sound", label: "Energetic Tone", minWidth: 150 },
    { id: "rushing_avoidence", label: "Rushing Avoidance", minWidth: 160 },
    { id: "fillerswords_avoid_agent", label: "Avoiding Filler Words", minWidth: 180 },
    { id: "probing_check", label: "Probing Check", minWidth: 150 },
    { id: "hold_placing", label: "Hold Placement", minWidth: 150 },
    { id: "hold_thanking", label: "Hold Thanking", minWidth: 150 },
    { id: "deadair_avoidence_check", label: "Dead Air Avoidance", minWidth: 180 },
    { id: "survey_check", label: "Survey Check", minWidth: 150 },
    { id: "verification_check", label: "Verification Check", minWidth: 170 },
    { id: "further_assistance_check", label: "Further Assistance Offered", minWidth: 200 },
    { id: "rudeness_avoidence_check", label: "Rudeness Avoidance", minWidth: 180 },
    { id: "singing_laughing_humming_avoidence_check", label: "Singing/Laughing/Humming Avoidance", minWidth: 250 },
    { id: "showing_interest_check", label: "Showing Interest", minWidth: 160 },
    { id: "sarcasm_avoidence_check", label: "Sarcasm Avoidance", minWidth: 160 },
    { id: "greeting_closing", label: "Greeting (Closing)", minWidth: 160 },
    { id: "closing_branding", label: "Closing Branding", minWidth: 170 },
    { id: "abusiveness_avoidence_check", label: "Abusiveness Avoidance", minWidth: 200 },
    { id: "thanking_closing", label: "Thanking (Closing)", minWidth: 170 }
  
  ];

const columns_bisleri = [
  { id: "agent_names", label: "Agent Name", minWidth: 200 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "agent_lethargy_check", label: "Lethargy Check", minWidth: 100 },
  { id: "agent_mentioned_brand_at_closing", label: "Brand Mention Closing", minWidth: 180 },
  { id: "agent_acknowledge", label: "Acknowledge", minWidth: 120 },
  { id: "agent_interruption_avoidance", label: "Interruption Avoidance", minWidth: 150 },
  { id: "opening_assist", label: "Opening Assist", minWidth: 120 },
  { id: "agent_hold_update_refresh", label: "Hold Update Refresh", minWidth: 150 },
  { id: "agent_energetic_check", label: "Energetic Check", minWidth: 100 },
  { id: "agent_dead_air_avoidance", label: "Dead Air Avoidance", minWidth: 150 },
  { id: "language_switch_permission", label: "Language Switch", minWidth: 150 },
  { id: "agent_respond_on_time", label: "Respond On Time", minWidth: 120 },
  { id: "agent_upsell", label: "Upsell", minWidth: 100 },
  { id: "agent_calmness_patience", label: "Calmness & Patience", minWidth: 150 },
  { id: "agent_further_assistance", label: "Further Assistance", minWidth: 150 },
  { id: "agent_filler_avoidance", label: "Filler Avoidance", minWidth: 150 },
  { id: "brand_name_check", label: "Brand Name Check", minWidth: 120 },
  { id: "agent_professional", label: "Professional", minWidth: 100 },
  { id: "agent_retention_effort", label: "Retention Effort", minWidth: 120 },
  { id: "agent_rude_avoidence", label: "Rude Avoidance", minWidth: 120 },
  { id: "agent_thanked_customer", label: "Thanked Customer", minWidth: 120 },
  { id: "agent_avoid_customer_repeat", label: "Avoid Customer Repeat", minWidth: 150 },
  { id: "agent_positive_language", label: "Positive Language", minWidth: 150 },
  { id: "agent_speech_clarity", label: "Speech Clarity", minWidth: 120 },
  { id: "agent_empathy_check", label: "Empathy Check", minWidth: 120 },
  { id: "call_opening_intro", label: "Opening Intro", minWidth: 120 },
  { id: "agent_clear_articulation", label: "Clear Articulation", minWidth: 150 },
  { id: "agent_jargon_avoidance", label: "Jargon Avoidance", minWidth: 150 },
  { id: "agent_probing_check", label: "Probing Check", minWidth: 120 },
  { id: "agent_hold_thanking", label: "Hold Thanking", minWidth: 120 },
  { id: "agent_rate_of_speech_check", label: "Rate of Speech", minWidth: 150 },
  { id: "agent_slang_avoidance", label: "Slang Avoidance", minWidth: 150 },
  { id: "issue_resolved", label: "Issue Resolved", minWidth: 120 },
  { id: "agent_hold_return_timing", label: "Hold Return Timing", minWidth: 150 },
  { id: "agent_abusive_avoidence", label: "Abusive Avoidance", minWidth: 150 },
  { id: "order_verification", label: "Order Verification", minWidth: 150 },
  { id: "customer_name_confirm", label: "Name Confirmation", minWidth: 150 },
  { id: "agent_proactive", label: "Proactive", minWidth: 100 },
  { id: "repeat_caller", label: "Repeat Caller", minWidth: 100 },
  { id: "agent_polite", label: "Polite", minWidth: 100 },
  { id: "agent_call_disconnect_avoidence", label: "Call Disconnect Avoidance", minWidth: 180 },
  { id: "agent_speaking_clearly", label: "Speaking Clearly", minWidth: 150 },
  
 ];
const columns_piramal = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "score_fatal", label: "Fatal Score", minWidth: 100 },
  { id: "sentiment", label: "Sentiment", minWidth: 100 },
  { id: "pace", label: "Pace", minWidth: 100 },
  { id: "pitch", label: "Pitch", minWidth: 120 },
  { id: "noise", label: "Noise", minWidth: 120 },
  { id: "active_listening", label: "Active Listening", minWidth: 100 },
  { id: "response", label: "Response", minWidth: 120 },
  { id: "etiquettes", label: "Etiquettes", minWidth: 120 },
  { id: "fluency", label: "Fluency", minWidth: 120 },
  { id: "clarity", label: "Clarity", minWidth: 120 },
  { id: "opening", label: "Opening", minWidth: 120 },
  {
    id: "closing",
    label: "Closing",
    minWidth: 120,
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 120,
  },
  { id: "satisfaction", label: "Satisfaction", minWidth: 100 },
  { id: "courtesy", label: "Courtesy", minWidth: 100 },
  {
    id: "information_gathering",
    label: "Information Gathering",
    minWidth: 150,
  },
  { id: "hold_time", label: "Hold Time", minWidth: 120 },
];
const columns_adret1 = [
  { id: "agent_names", label: "Agent Names", minWidth: 350 },
  { id: "score", label: "Score", minWidth: 100 },
  { id: "score_fatal", label: "Fatal Score", minWidth: 100 },
  { id: "call_opening", label: "Call Opening", minWidth: 100 },
  { id: "effective_probing", label: "Effective Probing", minWidth: 100 },
  { id: "product_benefit", label: "Product Benefit", minWidth: 120 },
  { id: "product_usage", label: "Product Usage", minWidth: 120 },

 
  {
    id: "pre_medical_history_check",
    label: "Pre Medical History Check",
    minWidth: 150,
  },
  {
    id: "order_confirmation_accuracy",
    label: "Order Confirmation Accuracy",
    minWidth: 120,
  },
  { id: "call_handling", label: "Call Handling", minWidth: 100 },
  {
    id: "prepaid_adherence",
    label: "Prepaid Adherence",
    minWidth: 120,
  },
    {
    id: "kapiva_app_pitching",
    label: "Kapiva App Pitching",
    minWidth: 100,
  },
  {
    id: "upselling",
    label: "Up Selling",
    minWidth: 100,
  },
  { id: "cross_selling", label: "Cross Selling", minWidth: 100 },
  { id: "ivr_transfer", label: "IVR Transfer", minWidth: 100 },
];
export const AgentDataDetails = (responseData) => {
  console.log("Get TAgent Data ", responseData);
  let bestParams = [];
  let needImprovement = [];
  let sortable = [];
 
  for (let arr in responseData.getAgentData[0]) {
    console.log("inside responseData", arr);
    sortable.push([arr, responseData.getAgentData[0][arr]]);
  }

  // Remove unwanted fields
  let filteredArr = sortable.filter(([key]) => 
    key !== "score_fatal" && key !== "score" && key !== "calls"
  );

  // Sort the filtered array
  const sortedArr = filteredArr.sort((a, b) => a[1] - b[1]);

  console.log("get list for need improvement :: ", sortedArr);

  // Get the lowest three scores (need improvement)
  for (let i = 0; i < 3 && i < sortedArr.length; i++) {
    needImprovement[i] = sortedArr[i];
  }

  // Get the highest three scores (best parameters)
  for (let i = sortedArr.length - 1, j = 0; j < 3 && i >= 0; i--) {
    if (sortedArr[i][0] !== "agent_names") {
      bestParams[j] = sortedArr[i];
      j++;
    }
  }

  console.log("Best Parameters:", bestParams);
  console.log("Need Improvement:", needImprovement);

  function toCamelCase(str) {
    // Split the string into words.
    const words = str.split(/\W+/);
    // Capitalize the first letter of each word except the first.
    const camelCaseWords = words.map((word, index) => {
      return word[0].toUpperCase() + word.slice(1);
    });
    // Join the words back together into a single string.
    return camelCaseWords.join(" ");
  }

  function transformArray(inputArray) {
    inputArray.forEach((array) => {
      array[0] = toCamelCase(array[0].replace(/_/g, " "));
    });
    return inputArray;
  }

  return (
    <>
      <div className="col-md-12 row">
        <div className="col-md-7">
          <div className="insight">
            {responseData.getAgentData[0].agent_names}
          </div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          >
            <Paper className="aupage">
              Calls
              <div className="dapage"> {responseData.getAgentData[1]}</div>
            </Paper>
            <Paper className="aupage">
              Score
              <div className="dapage">{responseData.getAgentData[0].score}</div>
            </Paper>
            <Paper className="aupage">
              Avg Call Duration<br></br>
              <div className="dapage"> {responseData.getAgentData[2]}</div>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 115,
                height: 60,
                marginLeft: 2,
              },
            }}
          ></Box>
          <div className="insight">Insights</div>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 245,
                height: 120,
                marginLeft: 2,
              },
            }}
          >
            <Paper className="aupage">
              <b>Best Parameters</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {bestParams &&
                  transformArray(bestParams).map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>
            {localStorage.getItem("user") === "sunteck_realty" ? (
            <Paper className="aupage">
              <b>Missed Opportunity Areas</b>
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
                {needImprovement &&
                  transformArray(needImprovement).map((el, i) => (
                    <div key={i}>{el[0].replace(/_/g, " ")}</div>
                  ))}
              </ul>
            </Paper>):(
            <Paper className="aupage">
            <b>Need Improvement</b>
            <br></br>
            <br></br>
            <ul style={{ paddingLeft: "10px", textAlign: "center" }}>
              {needImprovement &&
                transformArray(needImprovement).map((el, i) => (
                  <div key={i}>{el[0].replace(/_/g, " ")}</div>
                ))}
            </ul>
          </Paper>)}
          </Box>
        </div>
        {localStorage.getItem("user") === "redcliffe_lab" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "sobha_limited" ||
          localStorage.getItem("user") === "speech_demo_audit" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_shobha.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "bisleri" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_bisleri.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ): localStorage.getItem("user") === "sunteck_realty" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_sunteck_realty.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {
                            responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ): localStorage.getItem("user") === "hdfc_credila" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_hdfc_credila.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {
                            responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        )    
        : localStorage.getItem("user") === "poonawalla_fincorp" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_poonawalla.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "matrimony" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Score
                </th>
              </tr>
              {columns_matrimony.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "clearTax" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_clearTax.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "adret" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_adret.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "wellnessForever" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_wellness.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "piramal" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_piramal.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : localStorage.getItem("user") === "adret1" ? (
          <div className="col-md-5" style={{ paddingTop: "9px" }}>
            <table>
              <tr className="bord">
                <th
                  className="bord"
                  style={{ color: "#008DFF", backgroundColor: "#55493e36" }}
                >
                  Parameters
                </th>
                <th className="bord" style={{ backgroundColor: "#55493e36" }}>
                  Scores
                </th>
              </tr>
              {columns_adret1.map((column) => {
                return (
                  <tr className="bord">
                    {header.map((m) => {
                      if (m === "Parameters") {
                        return <td className="bord">{column.label}</td>;
                      }
                      if (m === "Score") {
                        return (
                          <td className="bord">
                            {responseData.getAgentData[0][column.id]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : null}
      </div>
    </>
  );
};
