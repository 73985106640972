import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, Popover, TablePagination, Button } from '@mui/material';
import { useState, useMemo,useRef } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { CallTimeLine } from './CallTimeLine';
import { useDownloadExcel } from 'react-export-table-to-excel';
import "../App.css";
interface Column {
  id: 'ucid' | 'call_type' | 'campaign' | 'agent' | 'disposition' | 'audio_url' | 'transcript_url';
  label: string;
  minWidth?: number;
  align?: 'right';
}
let arr: any[] = [];



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const columns: readonly Column[] = [
  { id: 'ucid', label: 'UCID', minWidth: 100 },
  { id: 'call_type', label: 'Call Type', minWidth: 80 },
  { id: 'campaign', label: 'Campaign', minWidth: 80 },
  { id: 'agent', label: 'Agent', minWidth: 80 },
  { id: 'disposition', label: 'Disposition', minWidth: 80 },
  { id: 'audio_url', label: 'Audio Transcript', minWidth: 150 },
  { id: 'transcript_url', label: 'View Transcript', minWidth: 150 }
];


let metaArr: any[] = [];
let ucidArr: any[] = [];
const getData = () => {
  metaArr=[];
  Object.values(HitsArr).forEach((data) => {
    const obj = JSON.parse(JSON.stringify(data));
    metaArr.push(obj.meta);
  })
  return metaArr;
}

type Props = {
  fetchTranscript: any;
};

let customerTranscript = {};
let PageSize = 10;
let HitsArr = {};

const chatTranscriptUrl = process.env.REACT_APP_BASE_URL + "/getChatTranscript";
const axios = require('axios');
axios.defaults.headers.common['user'] = localStorage.getItem('user');

export default function SampleTable({ fetchTranscript }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [responseData,setResponseData]=useState<any>();
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: String(localStorage.getItem('user'))+'_CDR',
    sheet: 'Users'
})
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];
    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
      );
    }
    
    setSelected(newSelected);
    };
    
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      console.log("page is rows ",page);
      var from = (newPage === 0 ? 0 : ((newPage*rowsPerPage)+1));
      var size = rowsPerPage;
      fetchTranscript[1](fetchTranscript[2].startDateTranscript,
        fetchTranscript[2].endDateTranscript,
        fetchTranscript[2].intentTranscriptVal,
        fetchTranscript[2].sentimentTranscriptVal,
        fetchTranscript[2].agentTranscriptionVal,
        fetchTranscript[2].campaignVal,
        fetchTranscript[2].genderVal,
        fetchTranscript[2].dispositionTranscriptVal,fetchTranscript[2].searchInputVal,fetchTranscript[2].matchInputVal,size, from);
      console.log("page is ",from);
    };
   
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    var size = rowsPerPage;
    fetchTranscript[1](fetchTranscript[2].startDateTranscript,
      fetchTranscript[2].endDateTranscript,
      fetchTranscript[2].intentTranscriptVal,
      fetchTranscript[2].sentimentTranscriptVal,
      fetchTranscript[2].agentTranscriptionVal,
      fetchTranscript[2].campaignVal,
      fetchTranscript[2].genderVal,
      fetchTranscript[2].dispositionTranscriptVal,
      fetchTranscript[2].searchInputVal,
      fetchTranscript[2].matchInputVal,
      parseInt(event.target.value, 10), 
      "0");
    };
    
    // console.log("No of pages",rowsPerPage);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    console.log("PAGE++++++", metaArr.slice(firstPageIndex, lastPageIndex))
    return metaArr.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);



  const transcriptDetails = (event: any) => {
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
    getPopoverContent();
  };

  const getTranscript = async (value: any, e: any) => {
    const res = await axios.get(chatTranscriptUrl, { params: { url: value.value } });
    setResponseData(res.data);
    transcriptDetails(e);
    return res.data;

  }
  // React.useEffect(() => {
  // 	getTranscript();
  // }, []);

  const getPopoverContent = () => {
    console.log("responseis ", responseData ? responseData.transcription[0] : [])
    return (
      <CallTimeLine getTranscript={responseData}></CallTimeLine>
    );
  };
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setIsPopoverOpen(false);
  };
  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  if (fetchTranscript != undefined && fetchTranscript.length != 0) {
    if (fetchTranscript[0]['Hits'] != undefined) {
      HitsArr = fetchTranscript[0]['Hits'];
      // Object.values(HitsArr).forEach((data: any)=>
      // {
      //   console.log("DATA INSIDE HITS IS",data);
      //   console.log("DATA INSIDE METAIS",data.meta);
      //   // const obj = JSON.parse(JSON.stringify(data));
      //         // console.log("obj333333333",obj.meta);
      //         metaArr.push();
      //         console.log("Meta array is---->",metaArr);
      // })
      // getData();
    }

  }
  

    metaArr.map((row, rowIdx) =>
    {
        ucidArr.push(row.ucid);
        })


  React.useEffect(() => {
    getData();
  }, [fetchTranscript]);
  
  return (
    <Card>
      <Paper>
        <TableContainer component={Paper} ref={tableRef}>
          <Table  aria-label="customized table">
            <TableHead sx={{backgroundColor: "yellow"}}>
              <TableRow >
                {columns.map((column, idx) => {
                  return (
                    <StyledTableCell key={column.id + idx} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#1976d2", fontWeight:"bold" }}>
                      {column.label}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {metaArr.map((row, rowIdx) => {
                console.log("row data is",row);
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      console.log("value is", value)
                      if (column.id === 'audio_url') {
                        return (
                          <StyledTableCell key={column.id + rowIdx} align={column.align}>
                            <audio controls controlsList="nodownload">
                              <source src={value} type="audio/mpeg" />
                            </audio>
                          </StyledTableCell>
                        );
                      }
                      else if (column.id === "transcript_url") {
                        return (
                          <StyledTableCell key={column.id + rowIdx} align={column.align}>
                            <Button variant="text" disabled={row['audio_url'] != '' ? false: true } onClick={(e) => getTranscript({value},e)}>
                              Click Here
                            </Button>
                          </StyledTableCell>
                        );
                      }
                      else {
                        return (
                          <StyledTableCell key={column.id + rowIdx} align={column.align}>
                            {value}
                          </StyledTableCell>
                        );

                      }
                    }
                    )}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count= {fetchTranscript[0]['dataCount']?Math.ceil(fetchTranscript[0]['dataCount']/rowsPerPage):0} // This is what your request should be returning in addition to the current page of rows.
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>



      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <span
          className="cs-modal-close"
          onClick={(e) => {
            handleOnPopoverClose();
          }}>
          X
        </span>
        {getPopoverContent()}
      </Popover>
      <Button  style={{"float":"right","marginTop": "-10px","marginRight": "22px"}} onClick={()=>onDownload()}>Download</Button>
    </Card>
  );
}




