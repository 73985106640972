import styled from "styled-components";

const IntentLabel = styled.div`
    overflow: hidden;
    color: #ff7f00;
    font-size: 11px;
    font-style: italic;
    word-wrap: break-word;
    border-radius: 7px;
    border: solid 1px rgba(255, 128, 0, 0.26);
    background-image: linear-gradient(to bottom, #fff, #fafafa 83%, #dfdfdf);
    grid-row: ${props => props.startr} / ${props => props.endr};
    grid-column: ${props => props.start} / ${props => props.end};
`;

export default IntentLabel;